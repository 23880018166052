import Notification from './Notification';

export enum NotificationTypes {
  NONE = 'none',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export const getNotification = (
  type: NotificationTypes,
  onClose: () => void,
  message = '',
  duration?: number | undefined
) => {
  return type === NotificationTypes.NONE ? null : (
    <Notification
      type={type}
      onClose={onClose}
      message={message}
      duration={duration}
    />
  );
};

import React from 'react';
import { styled } from '@mui/system';
import OptionItem from './OptionItem';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { Container, Drm, Format, PackagerType } from 'api/graphql/generated';
import { colors } from 'design';
import { useFormContext } from '../context/FormContext';

export type BasePackageTabProps = {
  className?: string;
};

type PackageTabProps = {
  className?: string;
};

const PackageTab = ({ className }: PackageTabProps) => {
  const {
    formats,
    containers,
    drms,
    segmentDurations,
    fragmentDurations,
    setFormats,
    setContainers,
    setDrms,
    setSegmentDurations,
    setFragmentDurations,
    setPackagerType,
    packagerType,
  } = useFormContext();

  return (
    <div className={className}>
      <OptionItem>
        <p>Packager</p>
        <ToggleButtonGroup
          value={packagerType}
          exclusive
          onChange={(e, val: PackagerType) => setPackagerType(val)}
          aria-label="packager-type"
          color="primary"
          size="small"
        >
          <ToggleButton value={PackagerType.Hybrik}>
            {PackagerType.Hybrik}
          </ToggleButton>
          <ToggleButton value={PackagerType.Mss}>
            {PackagerType.Mss}
          </ToggleButton>
        </ToggleButtonGroup>
      </OptionItem>
      <OptionItem>
        <p>Format</p>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={formats.has(Format.Hls)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.target.checked
                    ? formats.add(Format.Hls)
                    : formats.delete(Format.Hls);
                  setFormats(new Set(formats.values()));
                }}
              />
            }
            label="HLS"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={formats.has(Format.Dash)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.target.checked
                    ? formats.add(Format.Dash)
                    : formats.delete(Format.Dash);
                  setFormats(new Set(formats.values()));
                }}
              />
            }
            label="DASH"
            labelPlacement="start"
          />
        </FormGroup>
      </OptionItem>
      <OptionItem>
        <p>Container</p>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={containers.has(Container.Cmaf)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.target.checked
                    ? containers.add(Container.Cmaf)
                    : containers.delete(Container.Cmaf);
                  setContainers(new Set(containers.values()));
                }}
              />
            }
            label="CMAF"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={containers.has(Container.Ts)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.target.checked
                    ? containers.add(Container.Ts)
                    : containers.delete(Container.Ts);
                  setContainers(new Set(containers.values()));
                }}
              />
            }
            label="TS"
            labelPlacement="start"
          />
        </FormGroup>
      </OptionItem>
      <OptionItem>
        <p>DRM</p>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={drms.has(Drm.None)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.target.checked
                    ? drms.add(Drm.None)
                    : drms.delete(Drm.None);
                  setDrms(new Set(drms.values()));
                }}
              />
            }
            label="NONE"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={drms.has(Drm.Cbcs)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.target.checked
                    ? drms.add(Drm.Cbcs)
                    : drms.delete(Drm.Cbcs);
                  setDrms(new Set(drms.values()));
                }}
              />
            }
            label="CBCS"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={drms.has(Drm.Ctr)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.target.checked
                    ? drms.add(Drm.Ctr)
                    : drms.delete(Drm.Ctr);
                  setDrms(new Set(drms.values()));
                }}
              />
            }
            label="CTR"
            labelPlacement="start"
          />
        </FormGroup>
      </OptionItem>
      <OptionItem>
        <p>Segment Duration</p>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={segmentDurations.has(2)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.target.checked
                    ? segmentDurations.add(2)
                    : segmentDurations.delete(2);
                  setSegmentDurations(new Set(segmentDurations.values()));
                }}
              />
            }
            label="2"
            labelPlacement="start"
            disabled={Math.min(...fragmentDurations.values()) > 2}
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={segmentDurations.has(4)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.target.checked
                    ? segmentDurations.add(4)
                    : segmentDurations.delete(4);
                  setSegmentDurations(new Set(segmentDurations.values()));
                }}
              />
            }
            label="4"
            labelPlacement="start"
            disabled={Math.min(...fragmentDurations.values()) > 4}
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={segmentDurations.has(6)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.target.checked
                    ? segmentDurations.add(6)
                    : segmentDurations.delete(6);
                  setSegmentDurations(new Set(segmentDurations.values()));
                }}
              />
            }
            label="6"
            labelPlacement="start"
            disabled={Math.min(...fragmentDurations.values()) > 6}
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={segmentDurations.has(8)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.target.checked
                    ? segmentDurations.add(8)
                    : segmentDurations.delete(8);
                  setSegmentDurations(new Set(segmentDurations.values()));
                }}
              />
            }
            label="8"
            labelPlacement="start"
            disabled={Math.min(...fragmentDurations.values()) > 8}
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={segmentDurations.has(10)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.target.checked
                    ? segmentDurations.add(10)
                    : segmentDurations.delete(10);
                  setSegmentDurations(new Set(segmentDurations.values()));
                }}
              />
            }
            label="10"
            labelPlacement="start"
            disabled={Math.min(...fragmentDurations.values()) > 10}
          />
        </FormGroup>
      </OptionItem>
      <OptionItem>
        <p>Fragment Duration</p>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={fragmentDurations.has(2)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.target.checked
                    ? fragmentDurations.add(2)
                    : fragmentDurations.delete(2);
                  setFragmentDurations(new Set(fragmentDurations.values()));
                }}
              />
            }
            label="2"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={fragmentDurations.has(4)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.target.checked
                    ? fragmentDurations.add(4)
                    : fragmentDurations.delete(4);
                  setFragmentDurations(new Set(fragmentDurations.values()));
                }}
              />
            }
            label="4"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={fragmentDurations.has(6)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.target.checked
                    ? fragmentDurations.add(6)
                    : fragmentDurations.delete(6);
                  setFragmentDurations(new Set(fragmentDurations.values()));
                }}
              />
            }
            label="6"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={fragmentDurations.has(8)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.target.checked
                    ? fragmentDurations.add(8)
                    : fragmentDurations.delete(8);
                  setFragmentDurations(new Set(fragmentDurations.values()));
                }}
              />
            }
            label="8"
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={fragmentDurations.has(10)}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.target.checked
                    ? fragmentDurations.add(10)
                    : fragmentDurations.delete(10);
                  setFragmentDurations(new Set(fragmentDurations.values()));
                }}
              />
            }
            label="10"
            labelPlacement="start"
          />
        </FormGroup>
      </OptionItem>
    </div>
  );
};

const styledPackageTab = styled(PackageTab)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  columnGap: '10px',

  '.MuiFormGroup-root': {
    display: 'flex',
    flexDirection: 'row',

    '.Mui-disabled': {
      color: `${colors.darkWhite}`,

      '&.MuiCheckbox-root': {
        color: `${colors.darkWhite}`,
      },
    },
  },
});

export default styledPackageTab;

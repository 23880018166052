import React from 'react';
import { styled } from '@mui/system';

type OptionItemProps = {
  className?: string;
  children: React.ReactNode;
};

const OptionItem = ({ className, children }: OptionItemProps) => {
  return <div className={className}>{children}</div>;
};

const styledOptionItem = styled(OptionItem)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  columnGap: '10px',
});

export default styledOptionItem;

const vcmsAuthFetch = async <T>(
  url: RequestInfo,
  vcmsAccessToken: string,
  additionalOptions?: RequestInit | undefined
): Promise<T> => {
  try {
    const fetchOptions: RequestInit = {
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${vcmsAccessToken}`,
        'Content-Type': 'application/json',
      },
    };
    const enhancedInit = { ...additionalOptions, ...fetchOptions };
    const resp = await fetch(url, enhancedInit);

    if (resp.status >= 400) {
      const msg = await resp.text();
      return Promise.reject(new Error(msg));
    }
    return resp.json();
  } catch (error) {
    return Promise.reject(new Error('Failed to Fetch VCMS server'));
  }
};

export { vcmsAuthFetch };

import { useEffect, useState } from 'react';
import { styled } from '@mui/system';

import { Tabs } from '../types';
import { Button, Divider, Typography } from '@mui/material';
import { useFormContext } from '../context/FormContext';
import FileTab from './FileTab';
import { FileConsumer, FileProvider } from '../context/FileContext';
import AudioTab from './AudioTab';
import VideoTab from './VideoTab';
import OptionItem from './OptionItem';
import { FileType } from 'api/graphql/generated';
import { FeatureLogger } from 'common/logging';
import PackageTab from './PackageTab';
import { colors } from 'design';

type OptionsMenuProps = {
  className?: string;
};

const OptionsMenu = ({ className }: OptionsMenuProps) => {
  const { selectedFileId, uploadFiles } = useFormContext();
  const [activeTab, setActiveTab] = useState(Tabs.File);

  const selectedFile = uploadFiles.get(selectedFileId);

  const showTabs = [];
  if (selectedFile?.fileType === FileType.Video) {
    showTabs.push(Tabs.File, Tabs.Video, Tabs.Audio);
  } else if (selectedFile?.fileType === FileType.Audio) {
    showTabs.push(Tabs.File, Tabs.Audio);
  }

  const globalTabs = [Tabs.Package];

  useEffect(() => {
    FeatureLogger.SelfService(
      'Menu detected "selectedFileId" change: ',
      selectedFileId
    );
    setActiveTab(Tabs.File);
  }, [selectedFileId]);

  return (
    <div className={className}>
      {selectedFile === undefined && (
        <Typography color="primary" className="welcome">
          UPLOAD or SELECT a file to edit{' '}
        </Typography>
      )}
      {selectedFile !== undefined && (
        <>
          <div className="header">
            <h3>{selectedFile.fileName}</h3>
            <div className="tabs">
              {showTabs.map((tabType) => (
                <Button
                  key={tabType}
                  className={activeTab === tabType ? 'active' : ''}
                  onClick={() => setActiveTab(tabType)}
                >
                  {tabType}
                </Button>
              ))}
              <Divider orientation="vertical" />
              {globalTabs.map((tabType) => (
                <Button
                  key={tabType}
                  className={activeTab === tabType ? 'active' : ''}
                  onClick={() => setActiveTab(tabType)}
                >
                  {tabType}
                </Button>
              ))}
            </div>
          </div>
          <div className="options" key={selectedFileId}>
            <FileProvider>
              <>
                {activeTab === Tabs.File && <FileTab />}
                {activeTab === Tabs.Package && <PackageTab />}
                {activeTab === Tabs.Audio && (
                  <FileConsumer>
                    {(value) => (
                      <>
                        <OptionItem>
                          <div />
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={!value || value.audioConfigs.size >= 3}
                            onClick={() => value?.addConfig(FileType.Audio)}
                          >
                            Add Config
                          </Button>
                        </OptionItem>

                        {Array.from(value?.audioConfigs.keys() || []).map(
                          (configId) => (
                            <AudioTab
                              key={configId}
                              fileName={selectedFile.fileName}
                              configId={configId}
                            />
                          )
                        )}
                      </>
                    )}
                  </FileConsumer>
                )}
                {activeTab === Tabs.Video && (
                  <FileConsumer>
                    {(value) => (
                      <>
                        <OptionItem>
                          <div />
                          <Button
                            variant="contained"
                            color="secondary"
                            disabled={!value || value.videoConfigs.size >= 3}
                            onClick={() => value?.addConfig(FileType.Video)}
                          >
                            Add Config
                          </Button>
                        </OptionItem>

                        {Array.from(value?.videoConfigs.keys() || []).map(
                          (configId) => (
                            <VideoTab
                              key={configId}
                              fileName={selectedFile.fileName}
                              configId={configId}
                            />
                          )
                        )}
                      </>
                    )}
                  </FileConsumer>
                )}
              </>
            </FileProvider>
          </div>
        </>
      )}
    </div>
  );
};

const styledOptionsMenu = styled(OptionsMenu)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',

  '.welcome': {
    marginTop: '30px',
    alignSelf: 'center',
    position: 'relative',

    '&:before': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: '0',
      left: '0',
      borderRadius: '5px',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
      animation: 'pulse 4s infinite ease-in-out',

      '@keyframes pulse': {
        '0%': {
          boxShadow: `0 0 0 0 rgb(212, 15, 195, .5)`,
        },

        '70%': {
          transform: 'scaleY(1.7) scaleX(1.2)',
          boxShadow: `0 0 5px 10px rgb(212, 15, 195, 0)`,
        },

        '100%': {
          boxShadow: `0 0 0 0 rgb(212, 15, 195, 0)`,
        },
      },
    },
  },

  '.header': {
    display: 'flex',
    justifyContent: 'space-between',

    h3: {
      margin: '0 20px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '150px',
      flexGrow: '1',
    },
  },

  '.tabs': {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '30px',
    padding: '0 20px 0 10px',

    /* Since span overflow is freed for pseudo element. clip the tabs box */
    overflow: 'hidden',

    '.MuiDivider-root': {
      margin: '0 10px',
      height: '50%',
      position: 'relative',
      top: '25%',
      zIndex: '2',
      borderColor: `${colors.primaryPink}`,
    },

    '.MuiButton-root': {
      borderRadius: '5px 5px 0px 0px',
      /* prevent delay from messing with rounded bottom corners */
      transition: 'none',

      '&.active': {
        background: 'black',
        color: 'white',

        /* Squares */
        '&:before, &:after': {
          content: '""',
          position: 'absolute',
          background: 'black',
          width: '10px',
          height: '10px',
          bottom: '0px',
        },
        '&:before': {
          left: '-10px',
        },
        '&:after': {
          right: '-10px',
        },

        /* Circles */
        span: {
          overflow: 'unset',
          /* Circles over squares */
          zIndex: '1',

          '&:after, &:before': {
            content: '""',
            position: 'absolute',
            width: '20px',
            height: '20px',
            borderRadius: '5px',
            background: 'white',
            bottom: '0px',
          },
          '&:before': {
            left: '-20px',
          },
          '&:after': {
            right: '-20px',
          },
        },
      },

      '&:not(.active)': {
        color: 'black',

        '&': {
          /* Button over Circles */
          zIndex: '2',
        },
      },
    },
  },

  '.options': {
    flexGrow: '1',
    background:
      'linear-gradient(118.9deg, black 35.94%, rgba(0, 0, 0, 0.74) 100%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    color: 'white',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '5px',
    rowGap: '5px',

    '.MuiTypography-root': {
      fontSize: '14px',
    },

    '.MuiButtonGroup-root': {
      '.MuiButton-root': {
        borderWidth: '2px',
        borderRightColor: 'unset',

        '&.Mui-disabled': {
          border: '1px solid lightgray',
          backgroundColor: 'rgba(200,200,200,0.25)',
        },
      },
    },

    '.MuiOutlinedInput-root, .MuiToggleButtonGroup-root': {
      backgroundColor: 'white',

      input: {
        padding: '0 10px',
      },
    },

    '.MuiCheckbox-root:not(.Mui-checked)': {
      color: 'white',
    },
  },
});

export default styledOptionsMenu;

import { ApolloError } from '@apollo/client';
import {
  useDeleteStreamInfoMutation,
  GetAssetStreamsDocument,
} from 'api/graphql/generated';
import { initApolloClient } from 'api/BaseService';
import { useNotificationContext } from 'common/utils/managers/notifications/NotificationContext';
import { NotificationTypes } from 'common/utils/managers/notifications/NotificationManager';
import React, { useState } from 'react';
import { ConfirmDialog } from '../confirm-dialog/ConfirmDialog';
import StreamInfoModal, { StreamInfoModalModel } from './StreamInfoModal';
import { useAuthContext } from 'common/contexts/AuthContext';

export interface StreamInfoModalControllerProps {
  data: StreamInfoModalModel;
  onClose: () => void;
}

export const StreamInfoModalController: React.FC<StreamInfoModalControllerProps> =
  ({ data, onClose }) => {
    // ===== Dependency Injection =====
    const { groupId, vcmsAccessToken } = useAuthContext();
    const notifications = useNotificationContext();

    // ===== State =====
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    // ===== Data Services =====
    const [deleteStream] = useDeleteStreamInfoMutation({
      onCompleted: onDeleteSuccess,
      onError: onDeleteError,
    });

    // It's necessary to have this in a separate function rather than in the mutation options
    // so we can make the refetch conditional. Otherwise we get a big warning/error in testing
    function refreshStreams(): void {
      const apolloClient = initApolloClient(vcmsAccessToken);
      if (apolloClient.cache.readQuery({ query: GetAssetStreamsDocument })) {
        apolloClient.refetchQueries({
          include: [GetAssetStreamsDocument],
          updateCache(cache) {
            cache.evict({ fieldName: 'assets' });
          },
        });
      }
    }

    // ===== Event Handlers =====
    function onDeleteClick(): void {
      setShowConfirmDialog(true);
    }

    function onDeleteSuccess(): void {
      notifications.showNotification(
        NotificationTypes.SUCCESS,
        'Stream successfully deleted.'
      );
      refreshStreams();
      onClose();
    }

    function onDeleteError(e: ApolloError): void {
      notifications.showNotification(
        NotificationTypes.ERROR,
        'Error Deleting: ' + e.message
      );
    }

    function onCancel(): void {
      setShowConfirmDialog(false);
    }

    function onConfirm(): void {
      const input = {
        groupId: groupId,
        id: data.assetId,
      };
      deleteStream({
        variables: { deleteStreamInfoInput: input },
      });
    }

    return (
      <>
        <StreamInfoModal
          data={data}
          onCloseClick={onClose}
          onDeleteClick={onDeleteClick}
        />
        <ConfirmDialog
          title="Delete This Stream?"
          open={showConfirmDialog}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      </>
    );
  };

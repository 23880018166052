/* eslint-disable react/jsx-props-no-spreading */
import {
  Container,
  Drm,
  FileType,
  Format,
  PackagePayload,
  PackagerType,
  UploadFile,
} from 'api/graphql/generated';
import { FeatureLogger } from 'common/logging';
import {
  ComponentType,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  ATMOS_AUDIO_CONFIG,
  DEFAULT_AUDIO_CONFIG,
  DEFAULT_VIDEO_CONFIG,
} from '../constants/defaults';
import { CartesianPackageConfigs } from '../helpers';
import {
  AtmosAudioFileValidator,
  AtmosMetadataFileValidator,
  AtmosSourceFileValidator,
} from '../validators/IsAtmosFile';
import {
  NotificationTypes,
  useNotificationContext,
} from 'common/utils/managers/notifications';

export interface FormContextValue {
  audioFiles: Map<string, File>;
  videoFiles: Map<string, File>;
  metaFiles: Map<string, File>;
  uploadFiles: Map<string, UploadFile>;
  selectedFileId: string;
  packagePayload: PackagePayload;

  packagerType: PackagerType;
  formats: Set<Format>;
  containers: Set<Container>;
  drms: Set<Drm>;
  segmentDurations: Set<number>;
  fragmentDurations: Set<number>;

  validSelections: Error | undefined;

  setAudioFiles: Dispatch<SetStateAction<Map<string, File>>>;
  setVideoFiles: Dispatch<SetStateAction<Map<string, File>>>;
  setMetaFiles: Dispatch<SetStateAction<Map<string, File>>>;

  setPackagerType: React.Dispatch<React.SetStateAction<PackagerType>>;
  setFormats: React.Dispatch<React.SetStateAction<Set<Format>>>;
  setContainers: React.Dispatch<React.SetStateAction<Set<Container>>>;
  setDrms: React.Dispatch<React.SetStateAction<Set<Drm>>>;
  setSegmentDurations: React.Dispatch<React.SetStateAction<Set<number>>>;
  setFragmentDurations: React.Dispatch<React.SetStateAction<Set<number>>>;

  addFile: (file: File, fileType: FileType, linkToFileName?: string) => void;
  removeFile: (fileId: string, fileType: FileType) => void;

  setSelectedFileId: Dispatch<SetStateAction<string>>;
  setUploadFiles: Dispatch<SetStateAction<Map<string, UploadFile>>>;
}

const FormContext = createContext<FormContextValue | null>(null);

type FormProviderProps = {
  children: JSX.Element;
};
const FormProvider = ({ children }: FormProviderProps) => {
  const notifications = useNotificationContext();

  const [videoFiles, setVideoFiles] = useState<Map<string, File>>(new Map());
  const [audioFiles, setAudioFiles] = useState<Map<string, File>>(new Map());
  const [metaFiles, setMetaFiles] = useState<Map<string, File>>(new Map());
  const [uploadFiles, setUploadFiles] = useState<Map<string, UploadFile>>(
    new Map()
  );
  const [selectedFileId, setSelectedFileId] = useState('');
  // Packager Options
  // NOTE: invalid option combos will be dropped server side. To make the UX friendly we will auto generate packager configs from all multi-choice packager selections.
  const [packagerType, setPackagerType] = useState(PackagerType.Hybrik);
  const [formats, setFormats] = useState(new Set([Format.Hls, Format.Dash]));
  const [containers, setContainers] = useState(
    new Set([Container.Cmaf, Container.Ts])
  );
  const [drms, setDrms] = useState(new Set([Drm.None]));
  const [segmentDurations, setSegmentDurations] = useState(new Set([6]));
  const [fragmentDurations, setFragmentDurations] = useState(new Set([6]));

  const packagePayload: PackagePayload = useMemo(
    () => ({
      packagerType,
      packageConfigs: CartesianPackageConfigs(
        [...formats.values()],
        [...containers.values()],
        [...drms.values()],
        [...segmentDurations.values()],
        [...fragmentDurations.values()]
      ),
    }),
    [
      packagerType,
      formats,
      containers,
      drms,
      segmentDurations,
      fragmentDurations,
    ]
  );

  useEffect(() => {
    FeatureLogger.SelfService('FormProvider updated File Map: ', uploadFiles);
  }, [uploadFiles]);

  const addFile = (file: File, fileType: FileType, linkToFileName?: string) => {
    const uuid = `${file.name}_${uuidv4()}`;

    const duplicateFileName = [...uploadFiles.values()].find(
      (currentFile) => currentFile.fileName === file.name
    );
    if (duplicateFileName) {
      FeatureLogger.SelfService(
        'Tried to add a File that contains a duplicate filename: ',
        file.name
      );
      notifications.showNotification(
        NotificationTypes.ERROR,
        `Error adding file: '${file.name}' has an invalid (duplicate) filename`
      );
      return;
    }

    if (fileType === FileType.Video) {
      setVideoFiles((prev) => new Map([...prev.entries(), [uuid, file]]));
      const newVideoUploadFile: UploadFile = {
        encodeConfig: {
          video: [{ ...DEFAULT_VIDEO_CONFIG }],
          audio: [{ ...DEFAULT_AUDIO_CONFIG }],
        },
        fileType: FileType.Video,
        contentType: file.type,
        fileName: file.name,
      };
      setUploadFiles(
        (prev) => new Map([...prev.entries(), [uuid, newVideoUploadFile]])
      );
      setSelectedFileId(uuid);
      return;
    }
    if (fileType === FileType.Audio) {
      setAudioFiles((prev) => new Map([...prev.entries(), [uuid, file]]));
      const audioConfig = AtmosAudioFileValidator(file.name).valid
        ? ATMOS_AUDIO_CONFIG
        : DEFAULT_AUDIO_CONFIG;
      const newAudioUploadFile: UploadFile = {
        encodeConfig: {
          audio: [{ ...audioConfig }],
        },
        fileType: FileType.Audio,
        contentType: file.type,
        fileName: file.name,
      };
      setUploadFiles(
        (prev) => new Map([...prev.entries(), [uuid, newAudioUploadFile]])
      );
      setSelectedFileId(uuid);
      return;
    }
    if (fileType === FileType.Meta) {
      setMetaFiles((prev) => new Map([...prev.entries(), [uuid, file]]));
      const newMetaUploadFile: UploadFile = {
        fileType: FileType.Meta,
        contentType: file.type,
        fileName: file.name,
      };
      setUploadFiles((prev) => {
        const newUploadFiles = new Map(
          [...prev.entries()].map(([uuid, uploadFile]) => {
            if (uploadFile.fileName === linkToFileName) {
              const currentLinks = uploadFile.supportMetaFileNames;
              uploadFile.supportMetaFileNames = [
                ...(currentLinks || []),
                file.name,
              ];
            }
            return [uuid, uploadFile];
          })
        );
        newUploadFiles.set(uuid, newMetaUploadFile);
        return newUploadFiles;
      });
      return;
    }
  };

  const removeFile = (fileId: string, fileType: FileType) => {
    let deletedFile;
    if (fileType === FileType.Meta) {
      deletedFile = metaFiles.get(fileId);

      metaFiles.delete(fileId);
      setMetaFiles(new Map([...metaFiles]));
    }
    if (fileType === FileType.Video) {
      deletedFile = videoFiles.get(fileId);
      videoFiles.delete(fileId);
      setVideoFiles(new Map([...videoFiles]));
    }
    if (fileType === FileType.Audio) {
      deletedFile = audioFiles.get(fileId);
      audioFiles.delete(fileId);
      setAudioFiles(new Map([...audioFiles]));
    }

    if (!deletedFile) {
      FeatureLogger.SelfService(
        'Tried to remove non-existent File Id: ',
        fileId
      );
      return;
    }
    const removedFileName = deletedFile.name;
    const metaFilesToRemove =
      uploadFiles.get(fileId)?.supportMetaFileNames || [];
    // remove file being deleted
    uploadFiles.delete(fileId);
    const newUploadedFiles = new Map(
      [...uploadFiles.entries()]
        .filter(
          // remove linked files(trees) when node file is deleted
          ([uuid, uploadFile]) => {
            const keepFile = !metaFilesToRemove.find(
              (metaFiles) => metaFiles === uploadFile.fileName
            );
            if (!keepFile) {
              metaFiles.delete(uuid);
              setMetaFiles(new Map([...metaFiles]));
            }

            return keepFile;
          }
        )
        // remove support file links(trees) from node file when support file is deleted
        .map(([uuid, uploadFile]) => {
          if (!uploadFile.supportMetaFileNames) {
            return [uuid, uploadFile];
          }
          uploadFile.supportMetaFileNames =
            uploadFile.supportMetaFileNames.filter(
              (file) => file !== removedFileName
            );
          return [uuid, uploadFile];
        })
    );
    setUploadFiles(newUploadedFiles);

    if (selectedFileId === fileId) {
      FeatureLogger.SelfService(
        'Removed file that was being edited, resetting optionsMenu',
        fileId
      );
      setSelectedFileId([...uploadFiles][0][0] || '');
    }
  };

  const validSelections = () => {
    const hasNoVideoOrAudioFile =
      audioFiles.size === 0 && videoFiles.size === 0;
    if (hasNoVideoOrAudioFile) {
      return new Error('Upload must contain an audio or video file');
    }

    const atmosAudioErrors = [...uploadFiles.values()].reduce<Error[]>(
      (accum, uploadFile) => {
        const { fileName, supportMetaFileNames } = uploadFile;
        if (!AtmosAudioFileValidator(fileName).valid) {
          return accum;
        }
        const linkedFiles = supportMetaFileNames;
        if (!linkedFiles) {
          accum.push(
            new Error(
              `Atmos audio (${fileName}): requires metadata and source atmos files`
            )
          );
          return accum;
        }
        const hasAtmosSource = linkedFiles.find(
          (linkedFileName) =>
            AtmosSourceFileValidator(linkedFileName || '').valid
        );
        if (!hasAtmosSource) {
          accum.push(
            new Error(`Atmos audio (${fileName}): requires a atmos source file`)
          );
          return accum;
        }

        const hasAtmosMetadata = linkedFiles.find(
          (linkedFileName) =>
            AtmosMetadataFileValidator(linkedFileName || '').valid
        );
        if (!hasAtmosMetadata) {
          accum.push(
            new Error(
              `Atmos audio (${fileName}): requires a atmos metadata file`
            )
          );
          return accum;
        }
        return accum;
      },
      []
    );

    if (atmosAudioErrors.length !== 0) {
      // display first error, no need to concat errors for now
      return atmosAudioErrors[0];
    }

    // --- Future Job Requirements go here ---

    return;
  };

  return (
    <FormContext.Provider
      value={{
        audioFiles,
        videoFiles,
        metaFiles,
        uploadFiles,
        selectedFileId,
        packagePayload,

        packagerType,
        formats,
        containers,
        drms,
        segmentDurations,
        fragmentDurations,

        validSelections: validSelections(),

        setAudioFiles,
        setVideoFiles,
        setMetaFiles,
        setUploadFiles,
        setSelectedFileId,

        setPackagerType,
        setFormats,
        setContainers,
        setDrms,
        setSegmentDurations,
        setFragmentDurations,

        removeFile,
        addFile,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

const FormConsumer = FormContext.Consumer;

const useFormContext = (): FormContextValue => {
  const context = useContext(FormContext);
  if (context === null) {
    throw new Error('useFormContext must be used with a FormProvider');
  }
  return context;
};

const withFormContext =
  <P extends object>(Component: ComponentType<P>) =>
  (props: P) =>
    (
      <FormProvider>
        <Component {...props} />
      </FormProvider>
    );

export {
  FormProvider,
  withFormContext,
  useFormContext,
  FormContext,
  FormConsumer,
};

import { formatNumberWithCommas } from './resolution.util';

export enum FileMagnitudes {
  TB = 'TB',
  GB = 'GB',
  MB = 'MB',
  KB = 'KB',
  B = ' Bytes',
}
export function bytesToFormattedString(
  bytes: number,
  magnitude?: FileMagnitudes
): string {
  let fileMagnitude = magnitude;
  if (!magnitude) {
    fileMagnitude =
      [
        FileMagnitudes.TB,
        FileMagnitudes.GB,
        FileMagnitudes.MB,
        FileMagnitudes.KB,
        FileMagnitudes.B,
      ].find((mag) => bytesToBiggerUnit(bytes, mag) >= 1) || FileMagnitudes.B;
  }
  return (
    formatNumberWithCommas(
      parseFloat(
        trimTrailingZeros(
          bytesToBiggerUnit(bytes, fileMagnitude || FileMagnitudes.B).toFixed(2)
        )
      )
    ) + fileMagnitude
  );
}
export function bytesToBiggerUnit(
  bytes: number,
  magnitude: FileMagnitudes
): number {
  let outputUnits = bytes;
  switch (magnitude) {
    case FileMagnitudes.TB: {
      outputUnits = outputUnits / 1024 / 1024 / 1024 / 1024;
      break;
    }
    case FileMagnitudes.GB: {
      outputUnits = outputUnits / 1024 / 1024 / 1024;
      break;
    }
    case FileMagnitudes.MB: {
      outputUnits = outputUnits / 1024 / 1024;
      break;
    }
    case FileMagnitudes.KB: {
      outputUnits = outputUnits / 1024;
    }
  }
  return outputUnits;
}

export function trimTrailingZeros(numericString: string): string {
  return String(parseFloat(numericString));
}

export function makeSafeFileName(name: string): string {
  const safeName = name.replace(/\s/g, '_');
  return safeName.replace(/[^a-z0-9_]/gi, '');
}

import React, { FunctionComponent } from 'react';
import ModalTitle from './ModalTitle';
import { Dialog, DialogContent } from '@mui/material';

export enum ModalSizes {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

export interface ModalProps {
  title: string;
  isOpen: boolean;
  onClose(): void;
  size?: ModalSizes;
  children?: React.ReactNode;
}

export const ModalDialog: FunctionComponent<ModalProps> = ({
  title,
  isOpen,
  onClose,
  size,
  children,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth={size}>
      <ModalTitle title={title} onClose={onClose} />
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

import { Typography } from '@mui/material';
import { styled } from '@mui/system';

interface LoadingDisplayProps {
  className?: string;
}

const LoadingDisplay = ({ className }: LoadingDisplayProps) => {
  return (
    <>
      <Typography component="h3" className={className} variant="white">
        Loading...
      </Typography>
    </>
  );
};

const styledLoadingDisplay = styled(LoadingDisplay)({
  textAlign: 'center',
  marginTop: '300px',
});

export default styledLoadingDisplay;

import { FormControl, MenuItem, styled, Typography } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  GetGroupsQueryVariables,
  useGetGroupsQuery,
} from 'api/graphql/generated';
import ErroredDisplay from 'common/components/status-displays/ErroredDisplay';
import { useAuthContext } from 'common/contexts/AuthContext';

interface GroupIdSelectorProps {
  className?: string;
}

const GroupIdSelector = ({ className }: GroupIdSelectorProps) => {
  const { groupId, changeGroupId, vcmsAccessClaims } = useAuthContext();

  const handleGroupInputChange = (event: SelectChangeEvent<number>) => {
    changeGroupId(event.target.value as number);
  };

  const accessLevel = Number(vcmsAccessClaims?.gid) || 0;

  const groupsQueryVariables: GetGroupsQueryVariables = {
    groupsInput: { id: groupId },
  };
  const { loading, error, data } = useGetGroupsQuery({
    variables: groupsQueryVariables,
    skip: accessLevel !== 1 || groupId === 0,
  });
  if (error) return <ErroredDisplay error={error} />;

  if (loading) return <></>;

  if (accessLevel !== 1 || groupId === 0) {
    return <></>;
  }
  return (
    <div className={className}>
      <Typography
        variant="white"
        component="span"
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        Group:{' '}
      </Typography>
      <FormControl variant="standard">
        <Select
          value={groupId}
          label="GroupId"
          disableUnderline
          sx={(theme) => ({
            color: theme.palette.white.main,
            '.MuiSelect-select': {
              padding: '1px 0 0 0',
            },
            '.MuiSvgIcon-root': {
              color: theme.palette.white.main,
            },
          })}
          onChange={handleGroupInputChange}
        >
          {data?.groups.map((group) => {
            const id = group.id;
            return (
              <MenuItem key={id} value={id}>
                {group.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

const styledGroupIdSelector = styled(GroupIdSelector)(() => ({
  display: 'flex',
  gap: '6px',
  paddingRight: '30px',
}));

export default styledGroupIdSelector;

import { Typography, LinearProgress } from '@mui/material';
import { trimTrailingZeros } from 'common/utils/file.util';
import { styled } from '@mui/system';

interface UploadingDisplayProps {
  value: number;
  className?: string;
}

const UploadingDisplay = ({ value, className }: UploadingDisplayProps) => {
  return (
    <div className={className}>
      <Typography
        component="h2"
        className="uploadingDisplay"
      >{`${trimTrailingZeros(value.toFixed(2))}%`}</Typography>
      <LinearProgress value={value} variant="determinate" />
      <Typography component="h3" className="uploadingDisplay">
        Uploading: Please DO NOT navigate away!
      </Typography>
    </div>
  );
};

const styledUploadingDisplay = styled(UploadingDisplay)({
  padding: '50px 15px',
  flexDirection: 'column',
  display: 'flex',
  alignContent: 'center',
  border: '1px solid',
  borderRadius: '0.25em',
  '.uploadingDisplay': {
    marginTop: '50px',
    fontweight: 'bold',
    textAlign: 'center',
  },
});

export default styledUploadingDisplay;

import { FeatureLogger } from 'common/logging/FeatureLogger';
import {
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useEffect,
} from 'react';

interface KeySyncEvent {
  data: {
    action: string;
    value: string;
  };
}

// Function Overloading Signatures
function useLocalStorage<S>(
  key: string,
  defaultValue: S | (() => S)
): [S, Dispatch<SetStateAction<S>>];

function useLocalStorage<S = undefined>(
  key: string
): [S | undefined, Dispatch<SetStateAction<S | undefined>>];

// Function Implementation Signature
function useLocalStorage(key: string, defaultValue?: unknown): unknown {
  const bc = useMemo(() => new BroadcastChannel(`${key}_Sync_Channel`), [key]);

  const localValue = useCallback((): unknown => {
    if (typeof window === 'undefined') return defaultValue;

    try {
      const item = window.localStorage.getItem(key);
      const res: unknown = item ? JSON.parse(item) : defaultValue;
      return res;
    } catch (e) {
      console.warn(`Parsing key: ${key} from localStorage`);
      return defaultValue;
    }
  }, [defaultValue, key]);

  const [stateValue, setStateValue] = useState(localValue);

  const setLocalValue = useCallback(
    (newValue: unknown) => {
      if (typeof window === 'undefined') {
        return;
      }
      try {
        const clearLocalStorage =
          typeof newValue === 'undefined' || newValue === '';
        if (clearLocalStorage) {
          window.localStorage.removeItem(key);
        } else {
          const jsonNewValue = JSON.stringify(newValue);
          bc.postMessage({ action: 'sync', value: jsonNewValue });
          window.localStorage.setItem(key, jsonNewValue);
        }
      } catch {
        console.warn(`Adding key: ${key} to localStorage`);
      }
      setStateValue(newValue);
    },
    [key, bc]
  );

  useEffect(() => {
    const keySync = (event: KeySyncEvent) => {
      const { action, value } = event.data;
      if (action === 'sync') {
        FeatureLogger.Auth(
          'local storage hook received instructions to sync key/value: ',
          key,
          ' / ',
          value
        );
        window.localStorage.setItem(key, value);
        setStateValue(value);
      }
    };

    bc.addEventListener('message', keySync);
    return () => {
      bc.removeEventListener('message', keySync);
    };
  }, [bc, key]);

  return [stateValue, setLocalValue];
}

export { useLocalStorage };

import { ApolloError } from '@apollo/client';
import {
  useDeleteStreamInfoMutation,
  GetAssetStreamsDocument,
  useGetAssetStreamsQuery,
  StreamInfoFieldsFragment,
} from 'api/graphql/generated';
import ErroredDisplay from 'common/components/status-displays/ErroredDisplay';
import LoadingDisplay from 'common/components/status-displays/LoadingDisplay';
import { createDescriptionText } from 'common/utils/createDescriptionText.util';
import { useNotificationContext } from 'common/utils/managers/notifications/NotificationContext';
import { NotificationTypes } from 'common/utils/managers/notifications/NotificationManager';
import React, { useState } from 'react';
import { ConfirmDialog } from '../confirm-dialog/ConfirmDialog';
import ErrorModal, {
  ErrorModalDataModel,
  ErrorModalModel,
  ErrorModalStreamDataModel,
} from './ErrorModal';
import { useAuthContext } from 'common/contexts/AuthContext';

export interface ErrorModalControllerProps {
  data: ErrorModalModel;
  onClose: () => void;
}

export const ErrorModalController = ({
  data,
  onClose,
}: ErrorModalControllerProps) => {
  // ===== Dependency Injection =====
  const notifications = useNotificationContext();
  const { groupId } = useAuthContext();

  // ===== State =====
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  // ===== Data Services =====
  const [deleteStream] = useDeleteStreamInfoMutation({
    refetchQueries: [GetAssetStreamsDocument],
    onCompleted: onDeleteSuccess,
    onError: onDeleteError,
  });

  const {
    loading,
    error,
    data: streamStatusData,
  } = useGetAssetStreamsQuery({
    variables: {
      assetInput: {
        id: data.assetId,
        groupId,
      },
    },
  });
  if (loading) return <LoadingDisplay />;
  if (error) return <ErroredDisplay error={error} />;

  const errorModalArray: ErrorModalStreamDataModel[] = [];
  if (!data.streamId && streamStatusData) {
    const { streamInfos } = streamStatusData.asset;
    streamInfos.forEach((streamData: StreamInfoFieldsFragment) => {
      if (streamData.error) {
        // get details
        const descriptionText = createDescriptionText(streamData);
        const modalModel: ErrorModalStreamDataModel = {
          description: descriptionText || '',
          taskId: streamData.taskId,
          error: streamData.error,
        };
        errorModalArray.push(modalModel);
      }
    });
  } else if (streamStatusData) {
    // if modal source is the AssetList
    // find all the errored streams for this asset
    const streamData = streamStatusData.asset.streamInfos.find((streamInfo) => {
      return streamInfo.taskId === Number(data.streamId);
    });
    if (streamData) {
      // get details
      const descriptionText = createDescriptionText(streamData);
      errorModalArray.push({
        description: descriptionText || '',
        taskId: streamData.taskId,
        error: streamData.error ?? null,
      });
    }
  }

  const errorModalData: ErrorModalDataModel = {
    assetTitle: streamStatusData?.asset?.title ?? '',
    assetDescription: streamStatusData?.asset?.description ?? '',
    streamData: errorModalArray,
  };

  // ===== Event Handlers =====
  function onCancelClick(item: ErrorModalStreamDataModel): void {
    setShowConfirmDialog(true);
  }

  function onRetryClick(): void {
    console.log('RETRY');
  }

  function onDeleteSuccess(): void {
    notifications.showNotification(
      NotificationTypes.SUCCESS,
      'Stream successfully deleted.'
    );
    onClose();
  }

  function onDeleteError(e: ApolloError): void {
    notifications.showNotification(
      NotificationTypes.ERROR,
      'Error Deleting: ' + e.message
    );
  }

  function onCancel(): void {
    setShowConfirmDialog(false);
  }

  function onConfirm(): void {
    const input = {
      groupId,
      id: data.assetId,
    };
    deleteStream({
      variables: { deleteStreamInfoInput: input },
    });
  }

  return (
    <>
      <ErrorModal
        data={errorModalData}
        onCloseClick={onClose}
        onRetryClick={onRetryClick}
        onCancelClick={onCancelClick}
      />
      <ConfirmDialog
        title="Cancel processing this stream?"
        open={showConfirmDialog}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </>
  );
};

/* eslint-disable react-hooks/rules-of-hooks */
import {
  AudioConfig,
  FileType,
  UploadFile,
  VideoConfig,
} from 'api/graphql/generated';
import {
  ComponentType,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useFormContext } from './FormContext';
import { v4 as uuidv4 } from 'uuid';
import {
  DEFAULT_AUDIO_CONFIG,
  DEFAULT_VIDEO_CONFIG,
} from '../constants/defaults';
import { FeatureLogger } from 'common/logging';

export type FileContextValue = {
  fileType: FileType;
  contentType: string;
  fileName: string;
  preuploaded: boolean;
  fileUrl: string;

  videoConfigs: Map<string, VideoConfig>;
  audioConfigs: Map<string, AudioConfig>;

  setVideoConfigs: React.Dispatch<
    React.SetStateAction<Map<string, VideoConfig>>
  >;
  setAudioConfigs: React.Dispatch<
    React.SetStateAction<Map<string, AudioConfig>>
  >;
  setPreuploaded: React.Dispatch<React.SetStateAction<boolean>>;
  setFileUrl: React.Dispatch<React.SetStateAction<string>>;
  addConfig: (configType: FileType) => void;
  removeConfig: (configId: string, configType: FileType) => void;
};

const FileContext = createContext<FileContextValue | null>(null);

type FileProviderProps = {
  children: JSX.Element;
};

const FileProvider = ({ children }: FileProviderProps) => {
  const { setUploadFiles, uploadFiles, selectedFileId } = useFormContext();
  const file = uploadFiles.get(selectedFileId);
  if (!file) return <></>;
  const { fileType, contentType, fileName } = file;

  const vcm = file?.encodeConfig?.video;
  const initialVideoConfigs = new Map<string, VideoConfig>();
  if (vcm) {
    vcm.forEach((videoConfig) => {
      if (!videoConfig) return;
      initialVideoConfigs.set(uuidv4(), videoConfig);
    });
  }

  const acm = file?.encodeConfig?.audio;
  const initialAudioConfigs = new Map<string, AudioConfig>();
  if (acm) {
    acm.forEach((audioConfig) => {
      if (!audioConfig) return;
      initialAudioConfigs.set(uuidv4(), audioConfig);
    });
  }

  const [videoConfigs, setVideoConfigs] =
    useState<Map<string, VideoConfig>>(initialVideoConfigs);
  const [audioConfigs, setAudioConfigs] =
    useState<Map<string, AudioConfig>>(initialAudioConfigs);

  // File Options
  const [preuploaded, setPreuploaded] = useState(file?.fileUrl ? true : false);
  const [fileUrl, setFileUrl] = useState(file?.fileUrl || '');

  useEffect(() => {
    FeatureLogger.SelfService(
      'File context, file url change:',
      fileUrl,
      preuploaded
    );
    if (!file) return;
    const fileBuilder: UploadFile = {
      ...file,
    };
    preuploaded
      ? (fileBuilder.fileUrl = fileUrl)
      : (fileBuilder.fileUrl = undefined);
    FeatureLogger.SelfService('updating file:', file, fileBuilder);
    uploadFiles.set(selectedFileId, fileBuilder);
    setUploadFiles(new Map([...uploadFiles]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preuploaded, fileUrl]);

  useEffect(() => {
    FeatureLogger.SelfService(
      'File context, video config change:',
      videoConfigs
    );
    if (!file) return;
    const fileBuilder: UploadFile = {
      ...file,
    };
    fileBuilder.encodeConfig = {
      video: [...videoConfigs.values()],
      audio: file.encodeConfig?.audio,
    };
    FeatureLogger.SelfService('updating file:', file, fileBuilder);
    uploadFiles.set(selectedFileId, fileBuilder);
    setUploadFiles(new Map([...uploadFiles]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoConfigs]);

  useEffect(() => {
    FeatureLogger.SelfService(
      'File context, audio config change:',
      audioConfigs
    );
    if (!file) return;
    const fileBuilder: UploadFile = {
      ...file,
    };
    fileBuilder.encodeConfig = {
      video: file.encodeConfig?.video,
      audio: [...audioConfigs.values()],
    };
    FeatureLogger.SelfService('updating file:', file, fileBuilder);
    uploadFiles.set(selectedFileId, fileBuilder);
    setUploadFiles(new Map([...uploadFiles]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioConfigs]);

  const addConfig = (configType: FileType) => {
    const uuid = uuidv4();

    if (configType === FileType.Video) {
      const vcs = videoConfigs?.entries() ?? [];
      setVideoConfigs(new Map([...vcs, [uuid, DEFAULT_VIDEO_CONFIG]]));
      return;
    }
    if (configType === FileType.Audio) {
      const acs = audioConfigs?.entries() ?? [];
      setAudioConfigs(new Map([...acs, [uuid, DEFAULT_AUDIO_CONFIG]]));
      return;
    }
    if (fileType === FileType.Meta) {
      return;
    }
  };

  const removeConfig = (configId: string, configType: FileType) => {
    if (configType === FileType.Meta) {
      return;
    }
    if (configType === FileType.Video) {
      videoConfigs.delete(configId);
      setVideoConfigs(new Map([...videoConfigs]));
      return;
    }
    if (configType === FileType.Audio) {
      audioConfigs.delete(configId);
      setAudioConfigs(new Map([...audioConfigs]));
      return;
    }
  };

  return (
    <FileContext.Provider
      value={{
        fileType,
        contentType,
        fileName,
        preuploaded,
        fileUrl,

        videoConfigs,
        audioConfigs,

        setVideoConfigs,
        setAudioConfigs,

        setPreuploaded,
        setFileUrl,

        addConfig,
        removeConfig,
      }}
    >
      {children}
    </FileContext.Provider>
  );
};

const FileConsumer = FileContext.Consumer;

const useFileContext = (): FileContextValue => {
  const context = useContext(FileContext);
  if (context === null) {
    throw new Error('useFileContext must be used with a FileProvider');
  }
  return context;
};

const withFileContext =
  <P extends object>(
    Component: ComponentType<P>,
    contextProps: FileProviderProps
  ) =>
  (componentProps: P) =>
    (
      <FileProvider {...contextProps}>
        <Component {...componentProps} />
      </FileProvider>
    );

export {
  FileProvider,
  withFileContext,
  useFileContext,
  FileContext,
  FileConsumer,
};

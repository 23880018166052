import { ThemeProvider, createTheme } from '@mui/material/styles';
import { colors } from 'design';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            'sans-serif',
          ].join(','),
          lineHeight: 'normal',
          letterSpacing: 'normal',
        },
      },
    },
  },
  palette: {
    primary: {
      light: colors.primaryPink,
      main: colors.primaryPink,
      dark: colors.primaryPink,
    },
    secondary: {
      light: colors.primaryBlue,
      main: colors.primaryBlue,
      dark: colors.primaryBlue,
    },
    white: {
      light: colors.white,
      main: colors.white,
      dark: colors.darkWhite,
    },
    black: {
      light: colors.black,
      main: colors.black,
      dark: colors.black,
    },
    error: {
      main: colors.error,
    },
  },
  typography: {
    white: {
      color: colors.white,
    },
    darkWhite: {
      color: colors.darkWhite,
    },
    black: {
      color: colors.black,
    },
  },
});

export const withMuiTheme =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) =>
    (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </ThemeProvider>
    );

type JSXComponentProps = {
  children: JSX.Element;
};
export const MuiThemeProvider = ({ children }: JSXComponentProps) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

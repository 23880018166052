import { Link } from 'react-router-dom';
import Routes, { RoutePaths } from 'routes/PageRoutes';
import { styled } from '@mui/system';
import GroupIdSelector from './GroupIdSelector';
import AccountSelector from './AccountSelector';
import { useAuthContext } from 'common/contexts/AuthContext';

type NavigationBarProps = {
  className?: string;
};

const NavigationBar = ({ className }: NavigationBarProps): JSX.Element => {
  const { vcmsAccessToken, vcmsAccessClaims } = useAuthContext();
  const accessLevel = Number(vcmsAccessClaims?.gid) || 0;
  return (
    <>
      <nav className={className}>
        <div className="header">
          <Link to={`${RoutePaths.ASSET_LIST}`}>MSS+</Link>
        </div>
        <GroupIdSelector />
        {vcmsAccessToken && (
          <>
            <section className="page-links">
              {accessLevel === 1 && (
                <Link to={`${RoutePaths.ADMIN_PAGE}`}>Admin</Link>
              )}
              <Link to={`${RoutePaths.ASSET_LIST}`}>Assets</Link>
              <Link to={`${RoutePaths.TAG_PAGE}`}>Tags</Link>
            </section>
            <AccountSelector />
          </>
        )}
      </nav>
      <Routes />
    </>
  );
};

export default styled(NavigationBar)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  boxSizing: 'border-box',
  alignItems: 'center',
  width: '100%',
  height: '65px',
  display: 'flex',
  justifyContent: 'space-around',
  padding: '10px 5px',
  borderBottom: `3px solid ${theme.palette.white.light}`,
  '.header': {
    fontSize: '20px',
    fontWeight: 'bold',
    textDecoration: 'none',
    flexGrow: 1,
  },
  a: {
    color: theme.palette.white.light,
    textDecoration: 'underline 0.15em transparent',
    transition: 'text-decoration-color 300ms',

    '&:hover': {
      textDecorationColor: theme.palette.primary.light,
    },
  },
  '.page-links': {
    display: 'flex',
    gap: '30px',
  },
}));

enum Size {
  Small = 'SMALL',
  Medium = 'MEDIUM',
  Large = 'LARGE',

  Normal = 'NORMAL',
}

type SizeOptions = typeof Size;

export { Size };
export type { SizeOptions };

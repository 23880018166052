import { ApolloError } from '@apollo/client';
import { Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';

interface ErroredDisplayProps {
  error: ApolloError;
  className?: string;
}

const ErroredDisplay: React.FC<ErroredDisplayProps> = ({
  error,
  className,
}: ErroredDisplayProps) => {
  return (
    <>
      <Typography variant="white" component="h3" className={className}>
        Errored... {error.message}
      </Typography>
    </>
  );
};

const styledErroredDisplay = styled(ErroredDisplay)({
  textAlign: 'center',
  marginTop: '300px',
});

export default styledErroredDisplay;

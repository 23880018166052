import React, { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/system';
import { useFileContext } from '../context/FileContext';
import OptionItem from './OptionItem';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  AudioCodec,
  AudioConfig,
  AudioType,
  ChannelType,
  FileType,
} from 'api/graphql/generated';
import { DEFAULT_AUDIO_CONFIG } from '../constants/defaults';
import { Delete, ExpandMore } from '@mui/icons-material';
import { AtmosAudioFileValidator } from '../validators/IsAtmosFile';

type AudioTabProps = {
  className?: string;
  configId: string;
  fileName: string;
};

const AudioTab = ({ className, fileName, configId }: AudioTabProps) => {
  const { audioConfigs, setAudioConfigs, removeConfig } = useFileContext();
  let ac = audioConfigs.get(configId);
  if (!ac) {
    ac = DEFAULT_AUDIO_CONFIG;
  }

  const [channelType, setChannelType] = useState(ac.channelType);
  const [type, setType] = useState(ac.type);
  const [language, setLanguage] = useState(ac.language || undefined);
  const [label, setLabel] = useState(ac.label || undefined);
  const [codec, setCodec] = useState(ac.codec);
  const [bitrateKbs, setBitrateKbs] = useState(ac.bitrateKbs);

  const atmosFile = useMemo(() => {
    return AtmosAudioFileValidator(fileName).valid;
  }, [fileName]);

  useEffect(() => {
    const newAudioConfig: AudioConfig = {
      channelType,
      type,
      language,
      label,
      codec,
      bitrateKbs,
    };

    audioConfigs.set(configId, newAudioConfig);
    setAudioConfigs(new Map([...audioConfigs]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bitrateKbs, channelType, codec, configId, label, language, type]);

  return (
    <Accordion className={className}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
      >
        <Typography>
          Audio Config: <span className="boldText">{configId}</span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {atmosFile && (
          <p>
            <b>Atmos File has been detected. Some selections will be limited</b>
          </p>
        )}
        <OptionItem>
          <p>Channel</p>
          <ToggleButtonGroup
            value={channelType}
            exclusive
            onChange={(e, val: ChannelType) => setChannelType(val)}
            aria-label="audio-channel"
            color="primary"
            size="small"
          >
            <ToggleButton value={ChannelType.Mono} disabled={atmosFile}>
              {ChannelType.Mono}
            </ToggleButton>
            <ToggleButton value={ChannelType.Stereo}>
              {ChannelType.Stereo}
            </ToggleButton>
            <ToggleButton value={ChannelType.FiveOne}>5.1</ToggleButton>
          </ToggleButtonGroup>
        </OptionItem>
        <OptionItem>
          <p>Label</p>
          <TextField
            variant="outlined"
            size="small"
            value={label}
            disabled={atmosFile}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setLabel(event.target.value);
            }}
          />
        </OptionItem>
        <OptionItem>
          <p>Language</p>
          <TextField
            defaultValue="EN"
            variant="outlined"
            size="small"
            value={language}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setLanguage(event.target.value);
            }}
          />
        </OptionItem>
        <OptionItem>
          <p>Dialog Enhancement</p>
          <ToggleButtonGroup
            value={type}
            exclusive
            onChange={(e, val: AudioType) => setType(val)}
            aria-label="audio-type"
            color="primary"
            disabled={atmosFile}
            size="small"
          >
            <ToggleButton value={AudioType.DeNone}>
              {AudioType.DeNone}
            </ToggleButton>
            <ToggleButton value={AudioType.Enhanced}>
              {AudioType.Enhanced}
            </ToggleButton>
            <ToggleButton value={AudioType.Advanced}>
              {AudioType.Advanced}
            </ToggleButton>
            <ToggleButton value={AudioType.Max}>{AudioType.Max}</ToggleButton>
          </ToggleButtonGroup>
        </OptionItem>
        <OptionItem>
          <p>Codec</p>
          <ToggleButtonGroup
            value={codec}
            exclusive
            onChange={(e, val) => setCodec(val)}
            aria-label="audio-codec"
            color="primary"
            size="small"
            disabled={atmosFile}
          >
            <ToggleButton value={AudioCodec.AacLc}>
              {AudioCodec.AacLc}
            </ToggleButton>
            <ToggleButton value={AudioCodec.AacHe}>
              {AudioCodec.AacHe}
            </ToggleButton>
            <ToggleButton value={AudioCodec.Ac_3}>
              {AudioCodec.Ac_3}
            </ToggleButton>
            <ToggleButton value={AudioCodec.EAc_3}>
              {AudioCodec.EAc_3}
            </ToggleButton>
          </ToggleButtonGroup>
        </OptionItem>
        <OptionItem>
          <p>Bitrate (kbs)</p>
          <TextField
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            variant="outlined"
            value={bitrateKbs}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setBitrateKbs([Number(event.target.value)]);
            }}
          />
        </OptionItem>
        <OptionItem>
          <Button
            style={{ width: '100%' }}
            variant="outlined"
            startIcon={<Delete />}
            onClick={() => removeConfig(configId, FileType.Audio)}
          >
            Delete
          </Button>
        </OptionItem>
      </AccordionDetails>
    </Accordion>
  );
};

const styledAudioTab = styled(AudioTab)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  columnGap: '10px',

  '&.MuiPaper-root': {
    borderRadius: '5px',
  },

  '.boldText': {
    fontWeight: 'bold',
  },
});

export default styledAudioTab;

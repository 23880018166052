import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSessionStorage } from 'common/hooks/useSessionStorage';
import { getVcmsHostname } from 'common/models/environment';
import jwt_decode from 'jwt-decode';
import { useHistory, useLocation } from 'react-router-dom';
import { RoutePaths } from 'routes/PageRoutes';
import {
  withTabExpiredDialog,
  WithTabExpiredDialogProps,
} from 'common/hocs/TabExpiredDialog';
import { FeatureLogger } from 'common/logging';
import { useLocalStorage } from 'common/hooks/useLocalStorage';

export interface AuthContextValue {
  vcmsAccessToken: string;
  vcmsRefreshToken: string;
  vcmsAccessClaims: AccessTokenClaims | undefined;
  vcmsRefreshClaims: RefreshTokenClaims | undefined;
  groupId: number;
  login: (userLoginRequest: UserLoginRequest) => Promise<void>;
  logout: () => void;
  changeGroupId: (newGroupId: number) => boolean;
}

interface AuthProviderProps extends WithTabExpiredDialogProps {
  children?: React.ReactNode;
}
interface UserLoginRequest {
  username: string;
  password: string;
}
interface RefreshTokenRequest {
  fingerprint: string;
}
interface AuthResponseBody {
  message: string;
  jwt: string;
  refreshToken: string;
}
interface AccessTokenClaims {
  name: {
    firstname: string;
    lastname: string;
  };
  email: string;
  gid: string;
  gname: string;
  fingerprint: string;
  iss: string;
  sub: string;
  aud: string[];
  exp: number;
  iat: number;
}
interface RefreshTokenClaims {
  sub: string;
  exp: number;
}
interface GetSessionDataEvent {
  data: {
    action: string;
  };
}
interface ReceiveSessionDataEvent {
  data: {
    action: string;
    value: {
      vcms_access_token: string;
      vcms_access_claims: string;
      vcms_refresh_token: string;
      vcms_refresh_claims: string;
      vcms_group_id: string;
    };
  };
}

const AuthContext = createContext<AuthContextValue | null>(null);

const VCMS_ACCESS_TOKEN_SESSION_KEY = 'vcms_access_token';
const VCMS_ACCESS_CLAIMS_SESSION_KEY = 'vcms_access_claims';
const VCMS_REFRESH_TOKEN_SESSION_KEY = 'vcms_refresh_token';
const VCMS_REFRESH_CLAIMS_SESSION_KEY = 'vcms_refresh_claims';
const VCMS_GROUP_ID_SESSION_KEY = 'vcms_group_id';
const VCMS_SELECTION_GROUP_ID_KEY = 'vcms_selected_group_id';

const EMPTY = '';

const AuthProvider = ({
  children,
  openTabExpiredDialog,
  closeTabExpiredDialog,
}: AuthProviderProps) => {
  const bc = useMemo(() => new BroadcastChannel(`SessionStorage_Channel`), []);
  const controllerRef = useRef(new AbortController());

  const [vcmsAccessToken, setVcmsAccessToken] = useSessionStorage(
    VCMS_ACCESS_TOKEN_SESSION_KEY,
    EMPTY
  );
  const [vcmsRefreshToken, setVcmsRefreshToken] = useSessionStorage(
    VCMS_REFRESH_TOKEN_SESSION_KEY,
    EMPTY
  );
  const [vcmsAccessClaims, setVcmsAccessClaims] =
    useSessionStorage<AccessTokenClaims>(VCMS_ACCESS_CLAIMS_SESSION_KEY);
  const [vcmsRefreshClaims, setVcmsRefreshClaims] =
    useSessionStorage<RefreshTokenClaims>(VCMS_REFRESH_CLAIMS_SESSION_KEY);
  const [sessionGroupId, setSessionGroupId] = useSessionStorage(
    VCMS_GROUP_ID_SESSION_KEY,
    0
  );

  // This is the selected groupId, only able to be changed if the user belongs to group 1
  const [groupId, setGroupId] = useLocalStorage(
    VCMS_SELECTION_GROUP_ID_KEY,
    sessionGroupId
  );
  const [sessionSyncComplete, setSessionSyncComplete] = useState(false);
  const [tabExpired, setTabExpired] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const history = useHistory();
  const location = useLocation();

  // ---- Callback Functions ---- (used because of the setTimeout)
  const refreshToken = useCallback(async (): Promise<void> => {
    setRefreshing(true);
    FeatureLogger.Auth('refreshing with data:', {
      vcmsAccessClaims,
      vcmsRefreshToken,
    });
    const refreshTokenUrl = `${getVcmsHostname()}/refresh-token`;
    try {
      const refreshTokenRequest: RefreshTokenRequest = {
        fingerprint: vcmsAccessClaims?.fingerprint ?? '',
      };
      const fetchOptions: RequestInit = {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${vcmsRefreshToken}`,
        },
        body: JSON.stringify(refreshTokenRequest),
        signal: controllerRef.current.signal,
      };
      const body = await handleAuthRequest(refreshTokenUrl, fetchOptions);
      const accessTokenClaims = jwt_decode<AccessTokenClaims>(body.jwt);
      const refreshTokenClaims = jwt_decode<RefreshTokenClaims>(
        body.refreshToken
      );
      setVcmsAccessToken(body.jwt);
      setVcmsRefreshToken(body.refreshToken);
      setVcmsAccessClaims(accessTokenClaims);
      setVcmsRefreshClaims(refreshTokenClaims);
      setSessionGroupId(Number(accessTokenClaims.gid));

      // Send refresh session data to any other tabs
      const refreshedSessionData = {
        action: 'refresh_sync',
        value: { ...sessionStorage },
      };
      FeatureLogger.Auth(
        'sending refreshed session data',
        refreshedSessionData
      );
      bc.postMessage(refreshedSessionData);
    } catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        FeatureLogger.Auth('request aborted... continue');
        return;
      }
      logout();
      console.error('Refresh Access Token', error);
    } finally {
      FeatureLogger.Auth('refresh flow finished');
      setRefreshing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bc, vcmsAccessClaims, vcmsRefreshToken]);

  // --- Effects ----

  // Initial load effect -- handles syncing session storage & auth FF
  useEffect(() => {
    // Ask other tabs for session storage -- redirects to login if no tabs are logged in
    FeatureLogger.Auth('new session started');
    if (!vcmsAccessToken) {
      FeatureLogger.Auth('tab asking for session data');
      bc.postMessage({ action: 'get_session_data' });
      return;
    }

    // App loaded with an access token - proceed to post sync steps (page refresh)
    setSessionSyncComplete(true);

    return () => {
      FeatureLogger.Auth('session ending');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // after a browser tab sync completes, redirect to the original target route
  useEffect(() => {
    if (!sessionSyncComplete) {
      return;
    }
    if (location.pathname === RoutePaths.LOGIN_PAGE) {
      // Don't allow users to stay on login page if logged in
      location.pathname = RoutePaths.ASSET_LIST;
    }
    FeatureLogger.Auth(
      'reload or new login complete redirecting to: ',
      location
    );
    history.replace(location);
    setSessionSyncComplete(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionSyncComplete, history, location]);

  // sets automatic refresh if any VCMS tabs are still in use, otherwise expires session
  useEffect(() => {
    // if a refresh is taking place, user is not logged in, tab is expired, or auth FF disabled -- skip
    if (!vcmsAccessClaims || refreshing || !vcmsRefreshClaims || tabExpired) {
      return;
    }
    FeatureLogger.Auth('new refresh timer creating...');
    const timeToExpire = calculateTimeToExpireInMS(vcmsAccessClaims.exp ?? 0);
    const accessTokenIsExpired = timeToExpire <= 0;
    if (accessTokenIsExpired) {
      FeatureLogger.Auth(
        'received expired access token, can not set refresh flow -- trying to refresh'
      );
      const timeToExpire = calculateTimeToExpireInMS(
        vcmsRefreshClaims.exp ?? 0
      );
      const refreshTokenIsExpired = timeToExpire <= 0;
      if (refreshTokenIsExpired) {
        FeatureLogger.Auth(
          'refresh token and access token expired, can not set refresh flow -- logging out'
        );
        logout();
        return;
      }
      refreshToken();
      return;
    }

    const timeoutId = setTimeout(() => {
      FeatureLogger.Auth('attempting to refresh tab');
      /* Author: Eli-Kramer, NOTE:
       * I believe that `hasFocus` will only allow 1 tab to refresh automatically at a time.
       * If two tabs are manually refreshed near simultaneously two cases can occur:
       *  1) Tab one finishes refreshing as Tab two is processing the refresh request call.
       *     Tab one broadcasts 'refresh_sync' and tab two's request is cancelled.
       *     The 'sync' data is set and both tabs proceed as normal.
       *  2) Tab one call's refreshToken() first with valid creds, tab two quickly calls the same endpoint and returns prior to tab one's broadcast.
       *     Tab two will fail (forbidden -- invalid creds) and then receive tab one's broadcast that will restore "normal" operations.
       *     Restoration should occur quickly (within ms) as the networking call is complete but the broadcast is pending for case two.
       */
      if (document.hasFocus()) {
        FeatureLogger.Auth('tab has focus -- refreshing');
        refreshToken();
      } else {
        FeatureLogger.Auth(
          'no focus, tab expiring -- if any tab refreshes its access token or logs-in this tab will re-validate'
        );
        setTabExpired(true);
      }
    }, timeToExpire);

    return () => {
      FeatureLogger.Auth('clearing timer ....');
      clearInterval(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    refreshing,
    vcmsAccessClaims,
    refreshToken,
    vcmsRefreshClaims,
    tabExpired,
  ]);

  useEffect(() => {
    if (tabExpired) {
      FeatureLogger.Auth('tab is now expired');
      openTabExpiredDialog?.();
      return;
    }
    FeatureLogger.Auth('tab valid');
    closeTabExpiredDialog();
  }, [openTabExpiredDialog, tabExpired, closeTabExpiredDialog]);

  // ------ LISTENERS ------
  // sending session data listener
  useEffect(() => {
    const getSessionData = (event: GetSessionDataEvent) => {
      const { action } = event.data;
      if (action === 'get_session_data' && vcmsAccessToken) {
        const sessionData = {
          action: 'session_data',
          value: { ...sessionStorage },
        };
        FeatureLogger.Auth('sending session data', sessionData);
        bc.postMessage(sessionData);
      }
    };
    bc.addEventListener('message', getSessionData);
    return () => {
      bc.removeEventListener('message', getSessionData);
    };
  }, [bc, vcmsAccessToken]);

  // listener for receiving session data
  useEffect(() => {
    const receiveSessionData = (event: ReceiveSessionDataEvent) => {
      const { action, value } = event.data;
      if (
        (action === 'session_data' && !vcmsAccessToken) ||
        (action === 'new_login' && !vcmsAccessToken) ||
        (action === 'new_login' && tabExpired) ||
        (action === 'refresh_sync' && vcmsAccessToken)
      ) {
        // Cancel any outgoing login/refresh requests as they will have outdated session data
        controllerRef.current.abort();
        // Create new abort controller so new requests can proceed
        // NOTE: refs don't trigger a rerender on change so we can't rely on a component re-render to reset
        controllerRef.current = new AbortController();

        if (action === 'refresh_sync') {
          FeatureLogger.Auth('received instructions to refresh from other tab');
        }
        FeatureLogger.Auth('received session data', value);
        setVcmsAccessToken(JSON.parse(value[VCMS_ACCESS_TOKEN_SESSION_KEY]));
        setVcmsAccessClaims(JSON.parse(value[VCMS_ACCESS_CLAIMS_SESSION_KEY]));
        setVcmsRefreshToken(JSON.parse(value[VCMS_REFRESH_TOKEN_SESSION_KEY]));
        setVcmsRefreshClaims(
          JSON.parse(value[VCMS_REFRESH_CLAIMS_SESSION_KEY])
        );
        setSessionGroupId(JSON.parse(value[VCMS_GROUP_ID_SESSION_KEY]));
        setTabExpired(false);

        if (action !== 'refresh_sync') {
          setSessionSyncComplete(true);
        }
      }
    };
    bc.addEventListener('message', receiveSessionData);
    return () => {
      bc.removeEventListener('message', receiveSessionData);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bc, vcmsAccessToken, tabExpired]);

  // logout sync listener
  useEffect(() => {
    const logoutSync = (event: GetSessionDataEvent) => {
      const { action } = event.data;
      // if logged in proceed
      if (action === 'logout_sync' && vcmsAccessToken) {
        FeatureLogger.Auth('received instructions to logout');
        logout();
      }
    };
    bc.addEventListener('message', logoutSync);
    return () => {
      bc.removeEventListener('message', logoutSync);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bc, vcmsAccessToken]);

  // ------ END LISTENERS ------

  const login = async (userLoginRequest: UserLoginRequest): Promise<void> => {
    const loginUrl = `${getVcmsHostname()}/login`;
    try {
      const fetchOptions: RequestInit = {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(userLoginRequest),
        signal: controllerRef.current.signal,
      };

      const body = await handleAuthRequest(loginUrl, fetchOptions);

      const accessTokenClaims = jwt_decode<AccessTokenClaims>(body.jwt);
      const refreshTokenClaims = jwt_decode<RefreshTokenClaims>(
        body.refreshToken
      );
      setVcmsAccessToken(body.jwt);
      setVcmsRefreshToken(body.refreshToken);
      setVcmsAccessClaims(accessTokenClaims);
      setVcmsRefreshClaims(refreshTokenClaims);
      setSessionGroupId(Number(accessTokenClaims.gid));
      setTabExpired(false);
      setGroupId(Number(accessTokenClaims.gid));

      // Send login session data to any logged out tabs
      const newLoginData = {
        action: 'new_login',
        value: { ...sessionStorage },
      };
      FeatureLogger.Auth('sending new login data', newLoginData);
      bc.postMessage(newLoginData);
    } catch (error) {
      logout();
      console.error('Log In', error);
      throw error;
    }
  };

  const logout = (): void => {
    FeatureLogger.Auth('logging out');
    setVcmsAccessToken(EMPTY);
    setVcmsRefreshToken(EMPTY);
    setVcmsAccessClaims(undefined);
    setVcmsRefreshClaims(undefined);
    setSessionGroupId(0);
    setGroupId(0);
    setTabExpired(false);

    // log out other clients
    bc.postMessage({ action: 'logout_sync' });
    FeatureLogger.Auth('logging out other clients');
  };

  const handleAuthRequest = async (url: string, fetchOptions: RequestInit) => {
    const resp = await fetch(url, fetchOptions);
    if (resp.status !== 200) {
      throw new Error(await resp.text());
    }
    const body = (await resp.json()) as AuthResponseBody;
    return body;
  };

  const changeGroupId = (newGroupId: number): boolean => {
    const privileges = vcmsAccessClaims?.gid;
    FeatureLogger.Auth(
      'request to update selected group, privileges: ',
      privileges
    );
    if (privileges === undefined || privileges !== '1') {
      return false;
    }
    FeatureLogger.Auth('selected group updating to ... ', newGroupId);
    setGroupId(newGroupId);
    return true;
  };

  const contextInitValue: AuthContextValue = {
    vcmsAccessToken,
    vcmsRefreshToken,
    vcmsAccessClaims,
    vcmsRefreshClaims,
    groupId,
    login,
    logout,
    changeGroupId,
  };

  return (
    <AuthContext.Provider value={contextInitValue}>
      {children}
    </AuthContext.Provider>
  );
};

const AuthConsumer = AuthContext.Consumer;

const useAuthContext = (): AuthContextValue => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error('useAuthContext must be used with a AuthProvider');
  }
  return context;
};

const calculateTimeToExpireInMS = (expires: number) => {
  const MINUTE_IN_MS = 1000 * 60;
  const now = new Date().getTime();
  const future = new Date(expires * 1000).getTime();

  // Refresh 1 minute before token expires
  return future - now - MINUTE_IN_MS;
};

const AuthProviderWithDialog = withTabExpiredDialog(AuthProvider);

export {
  AuthProviderWithDialog as AuthProvider,
  useAuthContext,
  AuthContext,
  AuthConsumer,
};

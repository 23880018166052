import { ValidationResponse } from '../types';

const DolbyVisionFileValidator = (fileName: string): ValidationResponse => {
  const mxfVisionExtensionRegExp = new RegExp(/\.mxf$/);

  if (!mxfVisionExtensionRegExp.test(fileName)) {
    return {
      valid: false,
      error: new Error(
        'filename does not have an compatible dolby vision (mxf) file extension'
      ),
    };
  }
  return {
    valid: true,
    error: null,
  };
};

export { DolbyVisionFileValidator };

import { Route, Redirect } from 'react-router-dom';
import { useAuthContext } from 'common/contexts/AuthContext';

export interface PrivateRouteProps {
  path: string;
  exact?: boolean;
  children?: React.ReactNode;
}

export interface RedirectLocationStateType {
  from: { pathname: string };
}

function PrivateRoute({ children, path, exact }: PrivateRouteProps) {
  const { vcmsAccessToken, groupId } = useAuthContext();

  return (
    <Route
      path={path}
      exact={exact}
      render={({ location }) =>
        vcmsAccessToken && groupId ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location } as RedirectLocationStateType,
            }}
          />
        )
      }
    />
  );
}
export default PrivateRoute;

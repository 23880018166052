/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/system';
import { useFileContext } from '../context/FileContext';
import OptionItem from './OptionItem';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import {
  BitrateMode,
  ChromaFormat,
  ColorDepth,
  ColorSpace,
  FileType,
  Preset,
  VideoCodec,
  VideoConfig,
} from 'api/graphql/generated';
import { DEFAULT_VIDEO_CONFIG } from '../constants/defaults';
import { ExpandMore, Delete } from '@mui/icons-material';
import { DolbyVisionFileValidator } from '../validators/isDolbyVisionFile';

type VideoTabProps = {
  className?: string;
  fileName: string;
  configId: string;
};

const VideoTab = ({ className, fileName, configId }: VideoTabProps) => {
  const { videoConfigs, setVideoConfigs, removeConfig } = useFileContext();
  let vc = videoConfigs.get(configId);
  if (!vc) {
    vc = DEFAULT_VIDEO_CONFIG;
  }

  const [codec, setCodec] = useState(vc.codec);
  const [bitrateMode, setBitrateMode] = useState(vc.bitrateMode!);
  const [chromaFormat, setChromaFormat] = useState(vc.chromaFormat!);
  const [colorSpace, setColorSpace] = useState(vc.colorSpace!);
  const [preset, setPreset] = useState(vc.preset!);
  const [colorDepth, setColorDepth] = useState(vc.colorDepth!);
  const [bitrateLadder] = useState(vc.bitrateLadder || undefined);
  const [iFrameInterval, setIFrameInterval] = useState(vc.iFrameInterval!);
  const [idrInterval, setIdrInterval] = useState(vc.idrInterval!);

  const visionCompatibleFile = useMemo(
    () => DolbyVisionFileValidator(fileName).valid,
    [fileName]
  );

  useEffect(() => {
    if (colorDepth !== ColorDepth.DolbyVision) {
      return;
    }
    setChromaFormat(ChromaFormat.Yuv_420);
    setCodec(VideoCodec.H_265);
  }, [colorDepth]);

  useEffect(() => {
    const newVideoConfig: VideoConfig = {
      codec,
      bitrateMode,
      chromaFormat,
      colorSpace,
      preset,
      colorDepth,
      bitrateLadder,
      iFrameInterval,
      idrInterval,
    };

    videoConfigs.set(configId, newVideoConfig);
    setVideoConfigs(new Map([...videoConfigs]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bitrateLadder,
    bitrateMode,
    chromaFormat,
    codec,
    colorDepth,
    colorSpace,
    configId,
    iFrameInterval,
    idrInterval,
    preset,
  ]);

  return (
    <Accordion className={className}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
      >
        <Typography>
          Video Config:<span className="boldText">{configId}</span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {colorDepth === ColorDepth.DolbyVision && (
          <p>
            <b>Dolby Vision enabled. Some selections will be limited</b>
          </p>
        )}
        <OptionItem>
          <p>Codec</p>
          <ToggleButtonGroup
            value={codec}
            exclusive
            aria-label="video-codec"
            color="primary"
            size="small"
            disabled={colorDepth === ColorDepth.DolbyVision}
            onChange={(e, val: VideoCodec) => setCodec(val)}
          >
            <ToggleButton value={VideoCodec.H_264}>
              {VideoCodec.H_264}
            </ToggleButton>
            <ToggleButton value={VideoCodec.H_265}>
              {VideoCodec.H_265}
            </ToggleButton>
          </ToggleButtonGroup>
        </OptionItem>
        <OptionItem>
          <p>Bitrate Mode</p>
          <ToggleButtonGroup
            value={bitrateMode}
            exclusive
            aria-label="video-bitrate-mode"
            color="primary"
            size="small"
            onChange={(e, val: BitrateMode) => setBitrateMode(val)}
          >
            <ToggleButton value={BitrateMode.Cbr}>
              {BitrateMode.Cbr}
            </ToggleButton>
            <ToggleButton value={BitrateMode.Vbr}>
              {BitrateMode.Vbr}
            </ToggleButton>
          </ToggleButtonGroup>
        </OptionItem>
        <OptionItem>
          <p>Chroma Format</p>
          <ToggleButtonGroup
            value={chromaFormat}
            exclusive
            aria-label="video-chroma-format"
            color="primary"
            size="small"
            disabled={colorDepth === ColorDepth.DolbyVision}
            onChange={(e, val: ChromaFormat) => setChromaFormat(val)}
          >
            <ToggleButton value={ChromaFormat.Yuv_411}>
              {ChromaFormat.Yuv_411}
            </ToggleButton>
            <ToggleButton value={ChromaFormat.Yuv_420}>
              {ChromaFormat.Yuv_420}
            </ToggleButton>
            <ToggleButton value={ChromaFormat.Yuv_422}>
              {ChromaFormat.Yuv_422}
            </ToggleButton>
            <ToggleButton value={ChromaFormat.Yuv_444}>
              {ChromaFormat.Yuv_444}
            </ToggleButton>
          </ToggleButtonGroup>
        </OptionItem>
        <OptionItem>
          <p>Color Space</p>
          <ToggleButtonGroup
            value={colorSpace}
            exclusive
            aria-label="video-color-space"
            color="primary"
            size="small"
            onChange={(e, val: ColorSpace) => setColorSpace(val)}
          >
            <ToggleButton value={ColorSpace.Bt_2020}>
              {ColorSpace.Bt_2020}
            </ToggleButton>
            <ToggleButton value={ColorSpace.Bt_709}>
              {ColorSpace.Bt_709}
            </ToggleButton>
          </ToggleButtonGroup>
        </OptionItem>
        <OptionItem>
          <p>Color Depth</p>
          <ToggleButtonGroup
            value={colorDepth}
            exclusive
            aria-label="video-color-depth"
            color="primary"
            size="small"
            onChange={(e, val: ColorDepth) => setColorDepth(val)}
          >
            <ToggleButton value={ColorDepth.Sdr}>{ColorDepth.Sdr}</ToggleButton>
            <ToggleButton value={ColorDepth.Hdr}>{ColorDepth.Hdr}</ToggleButton>
            <ToggleButton
              disabled={!visionCompatibleFile}
              value={ColorDepth.DolbyVision}
            >
              {ColorDepth.DolbyVision}
            </ToggleButton>
          </ToggleButtonGroup>
        </OptionItem>
        <OptionItem>
          <p>Transcoding Preset</p>
          <ToggleButtonGroup
            value={preset}
            exclusive
            aria-label="video-preset"
            color="primary"
            size="small"
            onChange={(e, val: Preset) => setPreset(val)}
          >
            <ToggleButton value={Preset.Slow}>{Preset.Slow}</ToggleButton>
            <ToggleButton value={Preset.Medium}>{Preset.Medium}</ToggleButton>
            <ToggleButton value={Preset.Fast}>{Preset.Fast}</ToggleButton>
          </ToggleButtonGroup>
        </OptionItem>
        <OptionItem>
          <p>iFrame Interval (seconds)</p>
          <ButtonGroup size="small" aria-label="iframe-interval-button-group">
            <Button
              onClick={() => setIFrameInterval(iFrameInterval - 1)}
              disabled={iFrameInterval === 1}
            >
              -
            </Button>
            <Button style={{ background: 'white' }} disabled>
              {iFrameInterval}
            </Button>
            <Button
              onClick={() => setIFrameInterval(iFrameInterval + 1)}
              disabled={iFrameInterval + 1 === idrInterval}
            >
              +
            </Button>
          </ButtonGroup>
        </OptionItem>
        <OptionItem>
          <p>IDR Interval (seconds)</p>
          <ButtonGroup size="small" aria-label="idr-interval-button-group">
            <Button
              onClick={() => setIdrInterval(idrInterval - 1)}
              disabled={idrInterval - 1 === iFrameInterval}
            >
              -
            </Button>
            <Button color="primary" style={{ background: 'white' }} disabled>
              {idrInterval}
            </Button>
            <Button onClick={() => setIdrInterval(idrInterval + 1)}>+</Button>
          </ButtonGroup>
        </OptionItem>
        <OptionItem>
          <Button
            style={{ width: '100%' }}
            variant="outlined"
            startIcon={<Delete />}
            onClick={() => removeConfig(configId, FileType.Video)}
          >
            Delete
          </Button>
        </OptionItem>
      </AccordionDetails>
    </Accordion>
  );
};

const styledVideoTab = styled(VideoTab)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  columnGap: '10px',

  '&.MuiPaper-root': {
    borderRadius: '5px',
  },

  '.boldText': {
    fontWeight: 'bold',
  },
});

export default styledVideoTab;

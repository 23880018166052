const colors = {
  primaryMain: '#303240',
  primaryDark: '#13141C',
  secondaryMain: '#F44856',
  secondaryLight: '#F66B46',
  secondaryDark: '#F01E69',
  primaryBlue: '#0005FE',
  primaryPink: '#d40fc3',
  white: '#FFFFFF',
  darkWhite: '#535768',
  black: '#000000',
  error: '#FF0000',
  warning: '#535768',
  success: '#00D315',
  tertiary: '#830FD4',
};

export default colors;

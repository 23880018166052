import React, { useState } from 'react';
import { styled } from '@mui/system';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Input,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import {
  Format,
  Container,
  Drm,
  VideoCodec,
  AudioCodec,
  ColorDepth,
  ImageType,
  TagsQueryVariables,
  useTagsQuery,
  CreateAssetMutationVariables,
  useCreateAssetMutation,
  CreateAssetMutation,
} from 'api/graphql/generated';
import {
  DEFAULT_AUDIO_INFO,
  DEFAULT_STREAM_INFO,
  DEFAULT_TEXT_INFO,
  DEFAULT_VIDEO_INFO,
  DEFAULT_SOURCE_INFO,
  DEFAULT_IMAGE_INFO,
} from '../constants/defaults';
import OptionItem from './OptionItem';
import { useAuthContext } from 'common/contexts/AuthContext';
import { useNotificationContext } from 'common/utils/managers/notifications/NotificationContext';
import { NotificationTypes } from 'common/utils/managers/notifications';
import LoadingDisplay from 'common/components/status-displays/LoadingDisplay';
import ErroredDisplay from 'common/components/status-displays/ErroredDisplay';
import { ApolloError } from '@apollo/client';
import {
  withImportFormContext,
  useImportFormDispatchContext,
  useImportFormContext,
} from '../context/ImportFormContext';
import {
  ActionType,
  UpdateAssetIFormAction,
  AddStreamIFormAction,
  UpdateStreamIFormAction,
  AddVideoIFormAction,
  UpdateVideoIFormAction,
  AddAudioIFormAction,
  UpdateAudioIFormAction,
  AddTextIFormAction,
  UpdateTextIFormAction,
  AddSourceIFormAction,
  UpdateSourceIFormAction,
  AddImageIFormAction,
  UpdateImageIFormAction,
  AddDeleteTagIFormAction,
  DeleteVideoIFromAction,
  DeleteAudioIFromAction,
  DeleteTextIFromAction,
  DeleteSourceIFromAction,
  DeleteImageIFromAction,
  DeleteStreamIFromAction,
} from '../types';
import { LoadingButton } from '@mui/lab';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from 'routes/PageRoutes';

type ImportAssetFormProps = {
  className?: string;
  /* onClose: closes modal, via ModalContext -> ModalManager -> UploadModal */
  onClose: () => void;
};

const ImportAssetForm = ({ className, onClose }: ImportAssetFormProps) => {
  const { groupId } = useAuthContext();
  const notifications = useNotificationContext();
  const importFormDispatch = useImportFormDispatchContext();
  const history = useHistory();
  const {
    title,
    description,
    duration,
    streamInfos,
    imageInfos,
    tagIds,
    status,
    live,
  } = useImportFormContext();
  const [submitting, setSubmitting] = useState(false);

  const tagsQueryVariables: TagsQueryVariables = {
    tagsInput: {
      groupId,
    },
  };

  const createAssetError = (error: ApolloError) => {
    setSubmitting(false);
    notifications.showNotification(
      NotificationTypes.ERROR,
      `Create Asset Mutation failed: ${error}`,
      3000
    );
  };
  const createAssetComplete = (data: CreateAssetMutation) => {
    setSubmitting(false);
    notifications.showNotification(
      NotificationTypes.SUCCESS,
      'Asset successfully imported.',
      3000
    );
    onClose();
    const path = `${RoutePaths.ASSET_DETAILS}/${data.createAsset}`;
    history.push(path);
  };
  const queryError = (error: ApolloError) => {
    notifications.showNotification(
      NotificationTypes.ERROR,
      `Failed to load tags: ${error}`,
      3000
    );
  };
  const {
    loading: tagsQueryLoading,
    error: tagsQueryError,
    data: tagsData,
  } = useTagsQuery({
    variables: tagsQueryVariables,
    onError: queryError,
  });

  const [createAsset] = useCreateAssetMutation({
    onError: createAssetError,
    onCompleted: createAssetComplete,
  });

  const handleSubmit = () => {
    setSubmitting(true);

    const createAssetVariables: CreateAssetMutationVariables = {
      createAssetInput: {
        title,
        description,
        duration,
        streamInfos,
        imageInfos,
        tagIds,
        status,
        live,
        groupId,
        progress: 0,
      },
    };
    createAsset({
      variables: createAssetVariables,
    });
  };

  if (tagsQueryError !== undefined)
    return <ErroredDisplay error={tagsQueryError} />;
  if (tagsQueryLoading || tagsData === undefined) return <LoadingDisplay />;
  const { tags } = tagsData;

  return (
    <form className={className}>
      <FormControl variant="standard">
        <InputLabel htmlFor="asset-title">Title</InputLabel>
        <Input
          id="asset-title"
          value={title}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            importFormDispatch({
              type: ActionType.UpdateAsset,
              payload: {
                field: 'title',
                value: event.target.value,
              },
            } as UpdateAssetIFormAction)
          }
        />
      </FormControl>
      <FormControl variant="standard">
        <InputLabel htmlFor="asset-description">Description</InputLabel>
        <Input
          id="asset-description"
          value={description}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            importFormDispatch({
              type: ActionType.UpdateAsset,
              payload: {
                field: 'description',
                value: event.target.value,
              },
            } as UpdateAssetIFormAction)
          }
        />
      </FormControl>
      <OptionItem>
        <p>Asset Duration (number of seconds)</p>
        <Input
          value={duration}
          id="asset-duration"
          type="number"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            importFormDispatch({
              type: ActionType.UpdateAsset,
              payload: {
                field: 'duration',
                value: Number(event.target.value),
              },
            } as UpdateAssetIFormAction)
          }
        />
      </OptionItem>
      <OptionItem>
        <p>Live</p>
        <Checkbox
          size="small"
          checked={live ?? false}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            importFormDispatch({
              type: ActionType.UpdateAsset,
              payload: {
                field: 'live',
                value: event.target.checked,
              },
            } as UpdateAssetIFormAction)
          }
        />
      </OptionItem>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          importFormDispatch({
            type: ActionType.AddStream,
            payload: {
              value: DEFAULT_STREAM_INFO,
            },
          } as AddStreamIFormAction);
        }}
      >
        Add Stream Info
      </Button>
      {streamInfos.map(
        (
          {
            segmentDuration,
            format,
            container,
            drm,
            videoInfos,
            audioInfos,
            textInfos,
            sourceInfos,
          },
          streamIdx
        ) => (
          <Accordion key={streamIdx}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
            >
              <Typography>
                Stream Info: <span className="boldText">{streamIdx}</span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <OptionItem>
                <p>Segment Duration</p>
                <Input
                  value={segmentDuration}
                  type="number"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    importFormDispatch({
                      type: ActionType.UpdateStream,
                      payload: {
                        field: 'segmentDuration',
                        value: Number(event.target.value),
                        streamInfoIndex: streamIdx,
                      },
                    } as UpdateStreamIFormAction);
                  }}
                />
              </OptionItem>
              <OptionItem>
                <p>Format</p>
                <ToggleButtonGroup
                  value={format}
                  exclusive
                  aria-label="stream-info-format"
                  color="primary"
                  size="small"
                  onChange={(e, val: Format) => {
                    importFormDispatch({
                      type: ActionType.UpdateStream,
                      payload: {
                        field: 'format',
                        value: val,
                        streamInfoIndex: streamIdx,
                      },
                    } as UpdateStreamIFormAction);
                  }}
                >
                  <ToggleButton value={Format.Hls}>{Format.Hls}</ToggleButton>
                  <ToggleButton value={Format.Dash}>{Format.Dash}</ToggleButton>
                </ToggleButtonGroup>
              </OptionItem>
              <OptionItem>
                <p>Container</p>
                <ToggleButtonGroup
                  value={container}
                  exclusive
                  onChange={(e, val: Format) => {
                    importFormDispatch({
                      type: ActionType.UpdateStream,
                      payload: {
                        field: 'container',
                        value: val,
                        streamInfoIndex: streamIdx,
                      },
                    } as UpdateStreamIFormAction);
                  }}
                  aria-label="stream-info-container"
                  color="primary"
                  size="small"
                >
                  <ToggleButton value={Container.Cmaf}>
                    {Container.Cmaf}
                  </ToggleButton>
                  <ToggleButton value={Container.Ts}>
                    {Container.Ts}
                  </ToggleButton>
                </ToggleButtonGroup>
              </OptionItem>
              <OptionItem>
                <p>Drm</p>
                <ToggleButtonGroup
                  value={drm}
                  exclusive
                  onChange={(e, val: Format) => {
                    importFormDispatch({
                      type: ActionType.UpdateStream,
                      payload: {
                        field: 'drm',
                        value: val,
                        streamInfoIndex: streamIdx,
                      },
                    } as UpdateStreamIFormAction);
                  }}
                  aria-label="stream-info-drm"
                  color="primary"
                  size="small"
                >
                  <ToggleButton value={Drm.Cbcs}>{Drm.Cbcs}</ToggleButton>
                  <ToggleButton value={Drm.Ctr}>{Drm.Ctr}</ToggleButton>
                  <ToggleButton value={Drm.None}>{Drm.None}</ToggleButton>
                </ToggleButtonGroup>
              </OptionItem>
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  importFormDispatch({
                    type: ActionType.AddVideo,
                    payload: {
                      value: DEFAULT_VIDEO_INFO,
                      streamInfoIndex: streamIdx,
                    },
                  } as AddVideoIFormAction);
                }}
              >
                Add Video Info
              </Button>
              {videoInfos.map(
                (
                  { codec, bitdepth, width, height, bitrate, fps },
                  videoIdx
                ) => (
                  <Accordion key={streamIdx + '_' + videoIdx}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                    >
                      <Typography>
                        Video Info:{' '}
                        <span className="boldText">
                          {streamIdx + '_' + videoIdx}
                        </span>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <OptionItem>
                        <p>Video Codec</p>
                        <ToggleButtonGroup
                          value={codec}
                          exclusive
                          aria-label="video-codec"
                          color="primary"
                          size="small"
                          onChange={(e, val: Format) => {
                            importFormDispatch({
                              type: ActionType.UpdateVideo,
                              payload: {
                                field: 'codec',
                                value: val,
                                streamInfoIndex: streamIdx,
                                videoInfoIndex: videoIdx,
                              },
                            } as UpdateVideoIFormAction);
                          }}
                        >
                          <ToggleButton value={VideoCodec.H_264}>
                            {VideoCodec.H_264}
                          </ToggleButton>
                          <ToggleButton value={VideoCodec.H_265}>
                            {VideoCodec.H_265}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </OptionItem>
                      <OptionItem>
                        <p>Video Bitrate</p>
                        <Input
                          value={bitrate}
                          type="number"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            importFormDispatch({
                              type: ActionType.UpdateVideo,
                              payload: {
                                field: 'bitrate',
                                value: Number(event.target.value),
                                streamInfoIndex: streamIdx,
                                videoInfoIndex: videoIdx,
                              },
                            } as UpdateVideoIFormAction);
                          }}
                        />
                      </OptionItem>
                      <OptionItem>
                        <p>Video Width</p>
                        <Input
                          type="number"
                          value={width}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            importFormDispatch({
                              type: ActionType.UpdateVideo,
                              payload: {
                                field: 'width',
                                value: Number(event.target.value),
                                streamInfoIndex: streamIdx,
                                videoInfoIndex: videoIdx,
                              },
                            } as UpdateVideoIFormAction);
                          }}
                        />
                      </OptionItem>
                      <OptionItem>
                        <p>Video Height</p>
                        <Input
                          value={height}
                          type="number"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            importFormDispatch({
                              type: ActionType.UpdateVideo,
                              payload: {
                                field: 'height',
                                value: Number(event.target.value),
                                streamInfoIndex: streamIdx,
                                videoInfoIndex: videoIdx,
                              },
                            } as UpdateVideoIFormAction);
                          }}
                        />
                      </OptionItem>
                      <OptionItem>
                        <p>Color Depth</p>
                        <ToggleButtonGroup
                          value={bitdepth}
                          exclusive
                          aria-label="video-color-depth"
                          color="primary"
                          size="small"
                          onChange={(e, val: number) => {
                            importFormDispatch({
                              type: ActionType.UpdateVideo,
                              payload: {
                                field: 'bitdepth',
                                value: val,
                                streamInfoIndex: streamIdx,
                                videoInfoIndex: videoIdx,
                              },
                            } as UpdateVideoIFormAction);
                          }}
                        >
                          <ToggleButton value={ColorDepth.Sdr}>
                            {ColorDepth.Sdr}
                          </ToggleButton>
                          <ToggleButton value={ColorDepth.Hdr}>
                            {ColorDepth.Hdr}
                          </ToggleButton>
                          <ToggleButton value={ColorDepth.DolbyVision}>
                            {ColorDepth.DolbyVision}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </OptionItem>
                      <OptionItem>
                        <p>Frames per Second</p>
                        <Input
                          value={fps}
                          type="number"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            importFormDispatch({
                              type: ActionType.UpdateVideo,
                              payload: {
                                field: 'fps',
                                value: Number(event.target.value),
                                streamInfoIndex: streamIdx,
                                videoInfoIndex: videoIdx,
                              },
                            } as UpdateVideoIFormAction);
                          }}
                        />
                      </OptionItem>
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          importFormDispatch({
                            type: ActionType.DeleteVideo,
                            payload: {
                              streamInfoIndex: streamIdx,
                              videoInfoIndex: videoIdx,
                            },
                          } as DeleteVideoIFromAction);
                        }}
                      >
                        Delete Video Info
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                )
              )}
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  importFormDispatch({
                    type: ActionType.AddAudio,
                    payload: {
                      value: DEFAULT_AUDIO_INFO,
                      streamInfoIndex: streamIdx,
                    },
                  } as AddAudioIFormAction);
                }}
              >
                Add Audio Info
              </Button>
              {audioInfos.map(
                ({ codec, bitrate, channels, language }, audioIdx) => (
                  <Accordion key={streamIdx + '_' + audioIdx}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1a-content"
                    >
                      <Typography>
                        Audio Info:
                        <span className="boldText">
                          {streamIdx + '_' + audioIdx}
                        </span>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <OptionItem>
                        <p>Audio Codec</p>
                        <ToggleButtonGroup
                          value={codec}
                          exclusive
                          aria-label="audio-codec"
                          color="primary"
                          size="small"
                          onChange={(e, val: AudioCodec) => {
                            importFormDispatch({
                              type: ActionType.UpdateAudio,
                              payload: {
                                field: 'codec',
                                value: val,
                                streamInfoIndex: streamIdx,
                                audioInfoIndex: audioIdx,
                              },
                            } as UpdateAudioIFormAction);
                          }}
                        >
                          <ToggleButton value={AudioCodec.Ac_3}>
                            {AudioCodec.Ac_3}
                          </ToggleButton>
                          <ToggleButton value={AudioCodec.EAc_3}>
                            {AudioCodec.EAc_3}
                          </ToggleButton>
                          <ToggleButton value={AudioCodec.AacHe}>
                            {AudioCodec.AacHe}
                          </ToggleButton>
                          <ToggleButton value={AudioCodec.AacLc}>
                            {AudioCodec.AacLc}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </OptionItem>
                      <OptionItem>
                        <p>Audio Language</p>
                        <Input
                          value={language}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            importFormDispatch({
                              type: ActionType.UpdateAudio,
                              payload: {
                                field: 'language',
                                value: event.target.value,
                                streamInfoIndex: streamIdx,
                                audioInfoIndex: audioIdx,
                              },
                            } as UpdateAudioIFormAction);
                          }}
                        />
                      </OptionItem>
                      <OptionItem>
                        <p>Audio Bitrate</p>
                        <Input
                          type="number"
                          value={bitrate}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            importFormDispatch({
                              type: ActionType.UpdateAudio,
                              payload: {
                                field: 'bitrate',
                                value: Number(event.target.value),
                                streamInfoIndex: streamIdx,
                                audioInfoIndex: audioIdx,
                              },
                            } as UpdateAudioIFormAction);
                          }}
                        />
                      </OptionItem>
                      <OptionItem>
                        <p>Audio Channels</p>
                        <Input
                          type="number"
                          value={channels}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            importFormDispatch({
                              type: ActionType.UpdateAudio,
                              payload: {
                                field: 'channels',
                                value: Number(event.target.value),
                                streamInfoIndex: streamIdx,
                                audioInfoIndex: audioIdx,
                              },
                            } as UpdateAudioIFormAction);
                          }}
                        />
                      </OptionItem>
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          importFormDispatch({
                            type: ActionType.DeleteAudio,
                            payload: {
                              streamInfoIndex: streamIdx,
                              audioInfoIndex: audioIdx,
                            },
                          } as DeleteAudioIFromAction);
                        }}
                      >
                        Delete Audio Info
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                )
              )}
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  importFormDispatch({
                    type: ActionType.AddText,
                    payload: {
                      value: DEFAULT_TEXT_INFO,
                      streamInfoIndex: streamIdx,
                    },
                  } as AddTextIFormAction);
                }}
              >
                Add Text Info
              </Button>
              {textInfos.map(({ language, textType }, textIdx) => (
                <Accordion key={streamIdx + '_' + textIdx}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                  >
                    <Typography>
                      Text Info:
                      <span className="boldText">
                        {streamIdx + '_' + textIdx}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <OptionItem>
                      <p>Text Track Language</p>
                      <Input
                        value={language}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          importFormDispatch({
                            type: ActionType.UpdateText,
                            payload: {
                              field: 'language',
                              value: event.target.value,
                              streamInfoIndex: streamIdx,
                              textInfoIndex: textIdx,
                            },
                          } as UpdateTextIFormAction);
                        }}
                      />
                    </OptionItem>
                    <OptionItem>
                      <p>Text Track Type</p>
                      <Input
                        value={textType}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          importFormDispatch({
                            type: ActionType.UpdateText,
                            payload: {
                              field: 'textType',
                              value: event.target.value,
                              streamInfoIndex: streamIdx,
                              textInfoIndex: textIdx,
                            },
                          } as UpdateTextIFormAction);
                        }}
                      />
                    </OptionItem>
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={() => {
                        importFormDispatch({
                          type: ActionType.DeleteText,
                          payload: {
                            streamInfoIndex: streamIdx,
                            textInfoIndex: textIdx,
                          },
                        } as DeleteTextIFromAction);
                      }}
                    >
                      Delete Text Info
                    </Button>
                  </AccordionDetails>
                </Accordion>
              ))}
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  importFormDispatch({
                    type: ActionType.AddSource,
                    payload: {
                      value: DEFAULT_SOURCE_INFO,
                      streamInfoIndex: streamIdx,
                    },
                  } as AddSourceIFormAction);
                }}
              >
                Add Source Info
              </Button>
              {sourceInfos.map(({ cdn, url }, sourceIdx) => (
                <Accordion key={streamIdx + '_' + sourceIdx}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                  >
                    <Typography>
                      Source Info:
                      <span className="boldText">
                        {streamIdx + '_' + sourceIdx}
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <OptionItem>
                      <p>CDN Vendor Name</p>
                      <Input
                        value={cdn}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          importFormDispatch({
                            type: ActionType.UpdateSource,
                            payload: {
                              field: 'cdn',
                              value: event.target.value,
                              streamInfoIndex: streamIdx,
                              sourceInfoIndex: sourceIdx,
                            },
                          } as UpdateSourceIFormAction);
                        }}
                      />
                    </OptionItem>
                    <FormControl variant="standard">
                      <InputLabel
                        htmlFor={`source-url-${streamIdx}_${sourceIdx}`}
                      >
                        Stream Url
                      </InputLabel>
                      <Input
                        id={`source-url-${streamIdx}_${sourceIdx}`}
                        value={url}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          importFormDispatch({
                            type: ActionType.UpdateSource,
                            payload: {
                              field: 'url',
                              value: event.target.value,
                              streamInfoIndex: streamIdx,
                              sourceInfoIndex: sourceIdx,
                            },
                          } as UpdateSourceIFormAction);
                        }}
                      />
                    </FormControl>
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={() => {
                        importFormDispatch({
                          type: ActionType.DeleteSource,
                          payload: {
                            streamInfoIndex: streamIdx,
                            sourceInfoIndex: sourceIdx,
                          },
                        } as DeleteSourceIFromAction);
                      }}
                    >
                      Delete Source Info
                    </Button>
                  </AccordionDetails>
                </Accordion>
              ))}
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  importFormDispatch({
                    type: ActionType.DeleteStream,
                    payload: {
                      streamInfoIndex: streamIdx,
                    },
                  } as DeleteStreamIFromAction);
                }}
              >
                Delete Stream Info
              </Button>
            </AccordionDetails>
          </Accordion>
        )
      )}
      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          importFormDispatch({
            type: ActionType.AddImage,
            payload: {
              value: DEFAULT_IMAGE_INFO,
            },
          } as AddImageIFormAction);
        }}
      >
        Add Image Info
      </Button>
      {imageInfos.map(({ height, imageType, url, width }, imageIdx) => (
        <Accordion key={imageIdx}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
          >
            <Typography>
              Image Info: <span className="boldText">{imageIdx}</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl variant="standard">
              <InputLabel htmlFor={`image-url-${imageIdx}}`}>
                Image Url
              </InputLabel>
              <Input
                id={`image-url-${imageIdx}`}
                value={url}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  importFormDispatch({
                    type: ActionType.UpdateImage,
                    payload: {
                      field: 'url',
                      value: event.target.value,
                      imageInfoIndex: imageIdx,
                    },
                  } as UpdateImageIFormAction);
                }}
              />
            </FormControl>
            <OptionItem>
              <p>Image Type</p>
              <ToggleButtonGroup
                value={imageType}
                exclusive
                aria-label={`image-type-${imageIdx}`}
                color="primary"
                size="small"
                onChange={(e, val: ImageType) => {
                  importFormDispatch({
                    type: ActionType.UpdateImage,
                    payload: {
                      field: 'imageType',
                      value: val,
                      imageInfoIndex: imageIdx,
                    },
                  } as UpdateImageIFormAction);
                }}
              >
                <ToggleButton value={ImageType.Thumbnail_144}>
                  {ImageType.Thumbnail_144}
                </ToggleButton>
                <ToggleButton value={ImageType.Thumbnail_540}>
                  {ImageType.Thumbnail_540}
                </ToggleButton>
                <ToggleButton value={ImageType.Poster_720}>
                  {ImageType.Poster_720}
                </ToggleButton>
                <ToggleButton value={ImageType.Poster_1080}>
                  {ImageType.Poster_1080}
                </ToggleButton>
                <ToggleButton value={ImageType.Other}>
                  {ImageType.Other}
                </ToggleButton>
              </ToggleButtonGroup>
            </OptionItem>
            <OptionItem>
              <p>Image Width</p>
              <Input
                type="number"
                value={width}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  importFormDispatch({
                    type: ActionType.UpdateImage,
                    payload: {
                      field: 'width',
                      value: Number(event.target.value),
                      imageInfoIndex: imageIdx,
                    },
                  } as UpdateImageIFormAction);
                }}
              />
            </OptionItem>
            <OptionItem>
              <p>Image Height</p>
              <Input
                type="number"
                value={height}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  importFormDispatch({
                    type: ActionType.UpdateImage,
                    payload: {
                      field: 'height',
                      value: Number(event.target.value),
                      imageInfoIndex: imageIdx,
                    },
                  } as UpdateImageIFormAction);
                }}
              />
            </OptionItem>
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                importFormDispatch({
                  type: ActionType.DeleteImage,
                  payload: {
                    imageInfoIndex: imageIdx,
                  },
                } as DeleteImageIFromAction);
              }}
            >
              Delete Image Info
            </Button>
          </AccordionDetails>
        </Accordion>
      ))}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          style={{
            borderRadius: '4px',
            fontSize: '14px',
            textTransform: 'uppercase',
            fontWeight: '500',
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
          }}
        >
          Add Tags
        </AccordionSummary>
        <AccordionDetails
          style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '5px' }}
        >
          {tags.map(({ name, id }) => (
            <Chip
              key={id}
              label={name}
              color="secondary"
              variant={tagIds?.includes(id) ? 'filled' : 'outlined'}
              onClick={() => {
                importFormDispatch({
                  type: ActionType.AddDeleteTag,
                  payload: {
                    tagId: id,
                  },
                } as AddDeleteTagIFormAction);
              }}
            />
          ))}
        </AccordionDetails>
      </Accordion>
      <LoadingButton
        onClick={handleSubmit}
        loading={submitting}
        loadingIndicator="Importing asset..."
        variant="contained"
        color="primary"
      >
        Submit Job
      </LoadingButton>
    </form>
  );
};

const styledImportAssetForm = styled(ImportAssetForm)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  columnGap: '10px',

  '.MuiAccordionDetails-root': {
    display: 'flex',
    flexDirection: 'column',
  },

  '.MuiButton-root': {
    marginTop: '10px',
  },
});

export default withImportFormContext(styledImportAssetForm);

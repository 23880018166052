import { AsyncProviderConfig } from 'launchdarkly-react-client-sdk';

export function getApiUri(): string {
  return process.env.REACT_APP_API_BASE_URI + '/query' || '';
}

export function getVcmsHostname(): string {
  return process.env.REACT_APP_API_BASE_URI || '';
}

export function getLaunchDarklyProviderOptions(): AsyncProviderConfig {
  return {
    clientSideID: process.env.REACT_APP_LD_CLIENT_ID || '',
    user: {
      key: process.env.REACT_APP_LD_KEY || '',
      name: process.env.REACT_APP_LD_NAME || '',
    },
  };
}

export function getUploadConfig() {
  return {
    destinationBucketName: process.env.REACT_APP_DESTINATION_BUCKET || '',
    sourceBucketName: process.env.REACT_APP_SOURCE_BUCKET || '',
    destinationPathPrefix: process.env.REACT_APP_UPLOAD_ASSET_PATH || '',
    sourcePathPrefix: process.env.REACT_APP_UPLOAD_ASSET_PATH || '',
  };
}

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from 'routes/PageRoutes';

type TabExpiredDialogProps = {
  open: boolean;
  closeTabExpiredDialog: () => void;
  reload: () => void;
};

const TabExpiredDialog = ({
  open,
  closeTabExpiredDialog,
  reload,
}: TabExpiredDialogProps) => {
  const history = useHistory();

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleLoginRedirect = () => {
    const bc = new BroadcastChannel('SessionStorage_Channel');
    bc.postMessage({ action: 'logout_sync' });

    history.push(RoutePaths.LOGIN_PAGE);
    closeTabExpiredDialog();
    reload();
  };
  return (
    <Dialog
      open={open}
      onClose={handleRefresh}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Tab Expired'}</DialogTitle>
      <DialogActions>
        <Button onClick={handleLoginRedirect}>Login</Button>
        <Button onClick={handleRefresh} autoFocus>
          Refresh
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export type WithTabExpiredDialogProps = {
  openTabExpiredDialog: () => void;
  closeTabExpiredDialog: () => void;
};

type S = object;
type T = S & WithTabExpiredDialogProps;
export const withTabExpiredDialog = (Component: React.ComponentType<T>) => {
  return (props: S) => {
    const [open, setOpen] = useState(false);
    const [, reloadHoc] = useState<Record<string, never>>();

    const openTabExpiredDialog = useCallback(() => {
      setOpen(true);
    }, []);

    const closeTabExpiredDialog = useCallback(() => {
      setOpen(false);
    }, []);

    const reload = useCallback(() => {
      reloadHoc({});
    }, []);

    return (
      <>
        <Component
          {...props}
          openTabExpiredDialog={openTabExpiredDialog}
          closeTabExpiredDialog={closeTabExpiredDialog}
        />
        <TabExpiredDialog
          open={open}
          closeTabExpiredDialog={closeTabExpiredDialog}
          reload={reload}
        />
      </>
    );
  };
};

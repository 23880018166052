import {
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  styled,
  Tooltip,
} from '@mui/material';
import { useAuthContext } from 'common/contexts/AuthContext';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useState } from 'react';
import { Logout } from '@mui/icons-material';
import { ModalTypes } from 'common/utils/managers/modals/ModalManager';
import { useModalContext } from 'common/utils/managers/modals/ModalContext';

interface AccountSelectorProps {
  className?: string;
}

const AccountSelector = ({ className }: AccountSelectorProps) => {
  const { logout } = useAuthContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const modalContext = useModalContext();

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openNewAssetModal = () => {
    modalContext.showModal(ModalTypes.CREATE_ASSET);
  };

  const open = Boolean(anchorEl);

  return (
    <Box className={className}>
      <Tooltip title="Open settings" placement="bottom-start">
        <IconButton
          onClick={handleOpen}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <AccountCircleIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          key={'create-asset'}
          onClick={() => {
            handleClose();
            openNewAssetModal();
          }}
        >
          Create asset
        </MenuItem>
        <MenuItem
          key={'logout'}
          onClick={() => {
            handleClose();
            logout();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default styled(AccountSelector)(({ theme }) => ({
  paddingLeft: '30px',
  marginRight: '10px',

  '[aria-expanded="true"]': {
    svg: {
      color: theme.palette.primary.main,
    },
  },

  svg: {
    color: theme.palette.white.main,

    ':hover': {
      color: theme.palette.primary.main,
    },
  },
}));

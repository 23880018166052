import {
  AudioCodec,
  AudioConfig,
  AudioInfoInput,
  AudioType,
  BitrateMode,
  ChannelType,
  ChromaFormat,
  ColorDepth,
  ColorSpace,
  Container,
  CreateAssetInput,
  Drm,
  Format,
  ImageInfoInput,
  ImageType,
  Preset,
  SourceInfoInput,
  Status,
  StreamInfoInput,
  TextInfoInput,
  VideoCodec,
  VideoConfig,
  VideoInfoInput,
} from 'api/graphql/generated';

const DEFAULT_VIDEO_CONFIG: VideoConfig = {
  bitrateMode: BitrateMode.Cbr,
  chromaFormat: ChromaFormat.Yuv_420,
  codec: VideoCodec.H_264,
  colorDepth: ColorDepth.Sdr,
  colorSpace: ColorSpace.Bt_709,
  iFrameInterval: 1,
  idrInterval: 2,
  preset: Preset.Medium,
  bitrateLadder: [],
};

const DEFAULT_AUDIO_CONFIG: AudioConfig = {
  bitrateKbs: [],
  channelType: ChannelType.Mono,
  codec: AudioCodec.Ac_3,
  label: undefined,
  language: undefined,
  type: AudioType.DeNone,
};

const ATMOS_AUDIO_CONFIG: AudioConfig = {
  bitrateKbs: [488],
  channelType: ChannelType.FiveOne,
  codec: AudioCodec.EAc_3,
  label: undefined,
  language: undefined,
  type: AudioType.DeNone,
};

const DEFAULT_STREAM_INFO: StreamInfoInput = {
  taskId: 0,
  segmentDuration: 6,
  format: Format.Hls,
  container: Container.Cmaf,
  drm: Drm.None,
  videoInfos: [],
  audioInfos: [],
  textInfos: [],
  sourceInfos: [],
  status: Status.Undefined,
};

const DEFAULT_VIDEO_INFO: VideoInfoInput = {
  trackId: 0,
  codec: VideoCodec.H_264,
  bitrate: 0,
  width: 0,
  height: 0,
  bitdepth: 8,
  fps: 0,
};

const DEFAULT_AUDIO_INFO: AudioInfoInput = {
  trackId: 0,
  codec: AudioCodec.Ac_3,
  language: 'en',
  bitrate: 0,
  channels: 0,
};

const DEFAULT_TEXT_INFO: TextInfoInput = {
  trackId: '0',
  language: 'en',
  textType: '',
};

const DEFAULT_SOURCE_INFO: SourceInfoInput = {
  cdn: '',
  url: '',
};

const DEFAULT_IMAGE_INFO: ImageInfoInput = {
  url: '',
  imageType: ImageType.Other,
  width: 0,
  height: 0,
};

const DEFAULT_IMPORT_ASSET_FORM: CreateAssetInput = {
  title: '',
  description: '',
  duration: 0,
  streamInfos: [],
  imageInfos: [],
  tagIds: [],
  status: Status.Completed,
  progress: 0,
  live: false,
};

export {
  DEFAULT_VIDEO_CONFIG,
  DEFAULT_AUDIO_CONFIG,
  ATMOS_AUDIO_CONFIG,
  DEFAULT_STREAM_INFO,
  DEFAULT_VIDEO_INFO,
  DEFAULT_AUDIO_INFO,
  DEFAULT_TEXT_INFO,
  DEFAULT_SOURCE_INFO,
  DEFAULT_IMAGE_INFO,
  DEFAULT_IMPORT_ASSET_FORM,
};

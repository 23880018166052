import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { getApiUri } from 'common/models/environment';
import { customFetch } from './utils/customFetchUtil';
import { setContext } from '@apollo/client/link/context';

const uploadHttpLink = createUploadLink({
  uri: getApiUri(),
  fetch: customFetch,
});

const initApolloClient = (
  vcmsAccessToken: string | undefined
): ApolloClient<NormalizedCacheObject> => {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: vcmsAccessToken ? `Bearer ${vcmsAccessToken}` : '',
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(uploadHttpLink),
    cache: new InMemoryCache(),
  });
};

export { initApolloClient };

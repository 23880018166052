import { ApolloProvider } from '@apollo/client';
import {
  NotificationProvider,
  NotificationRoot,
} from 'common/utils/managers/notifications/NotificationContext';
import { initApolloClient } from './api/BaseService';
import {
  ModalProvider,
  ModalRoot,
} from './common/utils/managers/modals/ModalContext';
import NavigationBar from './common/components/navbar/NavigationBar';
import { withMuiTheme } from './common/hocs/MuiTheme';
import { styled } from '@mui/system';
import { AuthProvider, AuthConsumer } from 'common/contexts/AuthContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { memo } from 'react';

type AppProps = {
  className?: string;
};

type ApolloProviderProps = {
  vcmsAccessToken: string | undefined;
  children: JSX.Element;
};

const App = ({ className }: AppProps) => {
  // Memoizing the Apollo Provider allows us to prevent unnecessarily re-creating the apollo client.
  // This implementation allows us to ignore re-renders that would be caused by ANY Auth context value updates.
  // Instead we only re-render when the vcmsAccessToken changes.
  const MemoApolloProvider = memo(
    ({ vcmsAccessToken, children }: ApolloProviderProps) => {
      return (
        <ApolloProvider client={initApolloClient(vcmsAccessToken)}>
          {children}
        </ApolloProvider>
      );
    },
    (prev, next) => prev.vcmsAccessToken === next.vcmsAccessToken
  );

  return (
    <div className={className}>
      <Router>
        <AuthProvider>
          <AuthConsumer>
            {(authContext) => (
              <MemoApolloProvider
                vcmsAccessToken={authContext?.vcmsAccessToken}
              >
                <NotificationProvider>
                  <ModalProvider>
                    <NavigationBar />
                    <ModalRoot />
                    <NotificationRoot />
                  </ModalProvider>
                </NotificationProvider>
              </MemoApolloProvider>
            )}
          </AuthConsumer>
        </AuthProvider>
      </Router>
    </div>
  );
};

const styledApp = styled(App)({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'hidden',
  background: `linear-gradient(106.98deg, #C53DFB 0.81%, #0005FE 100%)`,
});

export default withMuiTheme(styledApp);

import { Container, Drm, Format, PackageConfig } from 'api/graphql/generated';

export const TitleCase = (string: string): string => {
  const lcString = string.toLowerCase();
  return lcString.charAt(0).toUpperCase() + lcString.slice(1);
};

/** This algorithm creates a 'n' dimensional cartesian product.
 *    'n' = each option set that is exposed for packaging configurations.
 *  This algorithm works by creating a perfectly balanced tree.
 *   each path from root to leaf will produce a cross product (that when appended to an array) will return a unique set of options for a packager config.
 *   mapping and then flattening the results keeps the accumulated value in the form '[[subset], [subset2]...]'.
 */
type OptionSet = Format[] | Container[] | Drm[] | number[];
type OptionSelection = Format | Container | Drm | number;
export const CartesianPackageConfigs = (
  formats: Format[],
  containers: Container[],
  drms: Drm[],
  segmentDurations: number[],
  fragmentDurations: number[]
): PackageConfig[] => {
  const reduce = (
    accumulator: OptionSelection[],
    optionSet: OptionSet | undefined,
    ...remainingSets: OptionSet[] | undefined[]
  ): OptionSelection[][] =>
    optionSet === undefined
      ? [accumulator]
      : optionSet.flatMap((optionSelection: OptionSelection) =>
          reduce(
            [...accumulator, optionSelection],
            remainingSets.slice(0, 1)[0],
            ...remainingSets.slice(1)
          )
        );

  // If any selection is missing return an empty array -- this should trigger server defaults
  if (
    formats.length === 0 ||
    containers.length === 0 ||
    drms.length === 0 ||
    segmentDurations.length === 0 ||
    fragmentDurations.length === 0
  ) {
    return [] as PackageConfig[];
  }

  const configsArrays = reduce(
    [],
    formats,
    containers,
    drms,
    segmentDurations,
    fragmentDurations
  );

  return configsArrays
    .map(
      (configArr: OptionSelection[]) =>
        ({
          format: configArr[0],
          container: configArr[1],
          drm: configArr[2],
          segmentDuration: configArr[3],
          fragmentDuration: configArr[4],
        } as PackageConfig)
    )
    .filter((packageConfig: PackageConfig) => {
      /* REMOVES incompatible package config option pairs */

      // Dash content can not stored in TS containers
      if (
        packageConfig.format === Format.Dash &&
        packageConfig.container === Container.Ts
      )
        return false;
      // segments can not be larger than fragment size
      if (
        packageConfig.fragmentDuration &&
        packageConfig.segmentDuration &&
        packageConfig.fragmentDuration > packageConfig.segmentDuration
      )
        return false;

      // all other config combinations are valid
      return true;
    });
};

import { Format, Drm, StreamInfoFieldsFragment } from 'api/graphql/generated';
import {
  getResolutionName,
  getTopResolution,
  isHDR,
} from 'common/utils/resolution.util';

export function createDescriptionText(
  stream: StreamInfoFieldsFragment
): string {
  const containerFormat =
    stream.format === Format.Hls
      ? `${stream.format}-${stream.container}`
      : stream.format;
  const topResolution = getTopResolution(stream.videoInfos);
  const resolutionName = getResolutionName(topResolution);
  const drmString =
    stream.drm === Drm.None || stream.drm === Drm.Undefined
      ? 'No DRM'
      : stream.drm;
  const hdrString = isHDR(topResolution || { bitdepth: 0 }) ? ', HDR' : '';
  return `${containerFormat}, ${resolutionName}, ${stream.segmentDuration}s, ${drmString}${hdrString}`;
}

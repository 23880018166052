import { IconButton, Snackbar } from '@mui/material';
import {
  Close,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import { styled } from '@mui/system';
import { NotificationTypes } from './NotificationManager';

type NotificationProps = {
  type: NotificationTypes;
  onClose: () => void;
  message: string;
  duration?: number | undefined;
  className?: string;
};

const notification = ({
  type,
  onClose,
  message,
  duration,
  className,
}: NotificationProps) => {
  let icon = <CheckCircleIcon className="icon" />;
  let defaultDuration = 3000;
  switch (type) {
    case NotificationTypes.INFO: {
      icon = <InfoIcon className="icon" />;
      break;
    }
    case NotificationTypes.ERROR: {
      icon = <ErrorIcon className="icon" />;
      defaultDuration = 15000;
      break;
    }
    case NotificationTypes.WARNING: {
      icon = <WarningIcon className="icon" />;
      defaultDuration = 10000;
      break;
    }
  }
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={true}
      autoHideDuration={duration || defaultDuration}
      onClose={onClose}
      className={className}
    >
      <div className={`notification ${type}`}>
        {icon}
        <p>{message}</p>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <Close fontSize="small" />
        </IconButton>
      </div>
    </Snackbar>
  );
};
const styledNotification = styled(notification)(({ theme }) => ({
  backgroundColor: 'white',

  '.notification': {
    display: 'flex',
    alignItems: 'start',
    padding: '10px 5px',
    gap: '10px',
    minWidth: '200px',

    p: {
      flex: 1,
      alignSelf: 'center',
      margin: 0,
    },
  },

  '.error': {
    backgroundColor: theme.palette.error.main,
    border: '1px solid red',
    '.icon': {
      color: 'black',
    },
  },

  '.info': {
    backgroundColor: 'powderblue',
    border: '1px solid royalblue',
    '.icon': {
      color: 'royalblue',
    },
  },

  '.success': {
    backgroundColor: 'lightgreen',
    border: '1px solid green',
    '.icon': {
      color: 'green',
    },
  },

  '.warning': {
    backgroundColor: 'lightgoldenrodyellow',
    border: '1px solid orange',
    '.icon': {
      color: 'orange',
    },
  },
}));

export default styledNotification;

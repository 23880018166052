import {
  Status,
  Drm,
  Format,
  GetAssetStreamStatusesFieldsFragment,
  GetAllAssetFieldsFragment,
  StreamInfoFieldsFragment,
  ImageType,
} from 'api/graphql/generated';
import { ReactElement } from 'react';
import { getTopResolution, getResolutionName, isHDR } from './resolution.util';

export const IMAGE_URL_PLACEHOLDER =
  'https://dott-origin-vod.s3.us-west-2.amazonaws.com/placeholder-images/poster.png';

export interface StatusAsset {
  id: number;
  status: Status;
  error?: string | null;
  progress: number;
}

export interface StatusStream {
  taskId: number;
  status: Status;
  error?: string | null;
}

export interface StatusStreamAssetData {
  streamInfos: StatusStream[];
}

export interface StatusData {
  asset: StatusStreamAssetData;
}

export function getImageUrl(
  asset: GetAssetStreamStatusesFieldsFragment | GetAllAssetFieldsFragment,
  imageType: ImageType
): string {
  let imageUrl = IMAGE_URL_PLACEHOLDER;
  if ('imageInfos' in asset && asset.imageInfos.length) {
    const img = asset.imageInfos.find(
      (image: { imageType: string; url: string }) =>
        image.imageType === imageType
    );
    if (img) {
      imageUrl = img.url;
    }
  }
  return imageUrl;
}

export function getSingleAssetStatusDisplay(asset: StatusAsset): ReactElement {
  if (asset && asset.status && asset.progress) {
    return (
      <>
        {asset.status} {asset.progress}%
      </>
    );
  } else if (asset && asset.status) {
    return <>{asset.status}</>;
  } else {
    return <>No Status Available</>;
  }
}

export function getStreamStatusDisplay(stream: StatusStream): ReactElement {
  if (stream) {
    return <>{stream.status}</>;
  } else {
    return <></>;
  }
}

export function createDescriptionText(
  stream: StreamInfoFieldsFragment
): string {
  const containerFormat =
    stream.format === Format.Hls
      ? `${stream.format}-${stream.container}`
      : stream.format;
  const topResolution = getTopResolution(stream.videoInfos);
  const resolutionName = getResolutionName(topResolution);
  const hdrString = isHDR(topResolution || { bitdepth: 0 }) ? ', HDR' : '';
  const drmType = stream.drm === Drm.Undefined ? 'No DRM' : stream.drm;
  return `${containerFormat}, ${resolutionName}, ${stream.segmentDuration}s, ${drmType}${hdrString}`;
}

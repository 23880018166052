import { Switch, Route } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import PrivateRoute from './PrivateRoute';

import LoadingDisplay from 'common/components/status-displays/LoadingDisplay';

enum RoutePaths {
  ASSET_LIST = '/',
  LOGIN_PAGE = '/login',
  ASSET_DETAILS = '/details',
  TAG_PAGE = '/tags',
  ADMIN_PAGE = '/admin',
  USER_REGISTRATION_PAGE = '/new-user-registration',
}

enum AdminPaths {
  ADD_USER = '/admin/add-users',
  INVITE_USER = '/admin/invite-user',
  HEALTH_CHECK = '/admin/health',
}

/** NOTE: The code split occurs on the import path.
 * Importing multiple components from the same index.js file will ONLY create 1 bundle.
 * Importing a component from `index.js` results in an promise not a dynamic import, causing an error.
 * Lazy loading will allow Webpack to Code Split, this is easily done on routes.
 */
const AssetListPage = lazy(() => import('../pages/AssetListPage'));
const AssetPage = lazy(() => import('../pages/AssetPage'));
const TagPage = lazy(() => import('../pages/TagPage'));
const AdminPage = lazy(() => import('../pages/AdminPage'));
const LoginPage = lazy(() => import('../pages/LoginPage'));
const UserRegistrationPage = lazy(
  () => import('../pages/UserRegistrationPage')
);

const Routes = (): JSX.Element => {
  /**
   * Switch is used to prevent multiple route hits (when exact isn't used).
   * Route ordering matters, more specific routes should be first in the switch.
   *
   * Admin route is not exact, Admin Page defines components to be rendered when admin sub routes are hit.
   */
  return (
    <Suspense fallback={<LoadingDisplay />}>
      <Switch>
        <Route path={RoutePaths.LOGIN_PAGE}>
          <LoginPage />
        </Route>
        <PrivateRoute exact path={`${RoutePaths.TAG_PAGE}`}>
          <TagPage />
        </PrivateRoute>
        <PrivateRoute exact path={RoutePaths.ASSET_LIST}>
          <AssetListPage />
        </PrivateRoute>
        <PrivateRoute exact path={`${RoutePaths.ASSET_DETAILS}/:id`}>
          <AssetPage />
        </PrivateRoute>
        <PrivateRoute path={RoutePaths.ADMIN_PAGE}>
          <AdminPage />
        </PrivateRoute>
        <Route path={RoutePaths.USER_REGISTRATION_PAGE}>
          <UserRegistrationPage />
        </Route>
      </Switch>
    </Suspense>
  );
};

export { RoutePaths, Routes as default, AdminPaths };

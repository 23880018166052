// THIS FILE IS GENERATED, DO NOT EDIT!
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Upload: { input: any; output: any; }
};

export type AddImageInfosInput = {
  groupId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  imageInfos: Array<ImageInfoInput>;
};

export type AddStreamInfosInput = {
  groupId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  streamInfos: Array<StreamInfoInput>;
};

export type Asset = {
  __typename?: 'Asset';
  description: Scalars['String']['output'];
  duration: Scalars['Int']['output'];
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageInfos: Array<ImageInfo>;
  live?: Maybe<Scalars['Boolean']['output']>;
  progress: Scalars['Int']['output'];
  status: Status;
  streamInfos: Array<StreamInfo>;
  tagInfos: Array<TagInfo>;
  title: Scalars['String']['output'];
};

export type AssetFilter = {
  container?: InputMaybe<Container>;
  drm?: InputMaybe<Drm>;
  format?: InputMaybe<Format>;
  live?: InputMaybe<Scalars['Boolean']['input']>;
  segmentDuration?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<Status>;
  tagIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type AssetInput = {
  groupId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
};

export type AssetsFilterInput = {
  filter: AssetFilter;
  groupId?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetsInput = {
  groupId?: InputMaybe<Scalars['Int']['input']>;
};

export enum AudioCodec {
  AacHe = 'AAC_HE',
  AacLc = 'AAC_LC',
  Ac_3 = 'AC_3',
  EAc_3 = 'E_AC_3'
}

export type AudioConfig = {
  bitrateKbs: Array<Scalars['Float']['input']>;
  channelType: ChannelType;
  codec: AudioCodec;
  label?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  type: AudioType;
};

export type AudioInfo = {
  __typename?: 'AudioInfo';
  bitrate: Scalars['Int']['output'];
  channels: Scalars['Int']['output'];
  codec: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  language: Scalars['String']['output'];
  trackId: Scalars['Int']['output'];
};

export type AudioInfoInput = {
  bitrate: Scalars['Int']['input'];
  channels: Scalars['Int']['input'];
  codec: Scalars['String']['input'];
  language: Scalars['String']['input'];
  trackId: Scalars['Int']['input'];
};

export enum AudioType {
  Advanced = 'ADVANCED',
  DeNone = 'DE_NONE',
  Enhanced = 'ENHANCED',
  Max = 'MAX'
}

export enum BitrateMode {
  Cbr = 'CBR',
  Vbr = 'VBR'
}

export type BitrateRendition = {
  bitrateKb: Scalars['Int']['input'];
  height: Scalars['Int']['input'];
  maxBitrateKb?: InputMaybe<Scalars['Int']['input']>;
  vbvBufferSizeKb?: InputMaybe<Scalars['Int']['input']>;
};

export type CancelUploadRequestInput = {
  id: Scalars['Int']['input'];
};

export enum ChannelType {
  FiveOne = 'FIVE_ONE',
  Mono = 'MONO',
  Stereo = 'STEREO'
}

export enum ChromaFormat {
  Yuv_411 = 'YUV_411',
  Yuv_420 = 'YUV_420',
  Yuv_422 = 'YUV_422',
  Yuv_444 = 'YUV_444'
}

export enum ColorDepth {
  DolbyVision = 'DOLBY_VISION',
  Eight = 'EIGHT',
  Hdr = 'HDR',
  Sdr = 'SDR',
  Ten = 'TEN'
}

export enum ColorSpace {
  Bt_709 = 'BT_709',
  Bt_2020 = 'BT_2020'
}

export enum Container {
  Cmaf = 'CMAF',
  Ts = 'TS',
  Undefined = 'UNDEFINED'
}

export type CreateAssetInput = {
  description: Scalars['String']['input'];
  duration: Scalars['Int']['input'];
  error?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['Int']['input']>;
  imageInfos: Array<ImageInfoInput>;
  live?: InputMaybe<Scalars['Boolean']['input']>;
  progress: Scalars['Int']['input'];
  status: Status;
  streamInfos: Array<StreamInfoInput>;
  tagIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  title: Scalars['String']['input'];
};

export type CreateGroupInput = {
  convivaKey?: InputMaybe<Scalars['String']['input']>;
  convivaUrl?: InputMaybe<Scalars['String']['input']>;
  fairplayUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  playreadyUrl?: InputMaybe<Scalars['String']['input']>;
  widevineUrl?: InputMaybe<Scalars['String']['input']>;
  xCDConfigId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTagInput = {
  groupId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type DeleteAssetInput = {
  groupId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
};

export type DeleteGroupInput = {
  id: Scalars['Int']['input'];
};

export type DeleteStreamInfoInput = {
  groupId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
};

export type DeleteTagInput = {
  groupId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};

export enum Drm {
  Cbcs = 'CBCS',
  Ctr = 'CTR',
  None = 'NONE',
  Undefined = 'UNDEFINED'
}

export type EncodeConfig = {
  audio?: InputMaybe<Array<InputMaybe<AudioConfig>>>;
  video?: InputMaybe<Array<InputMaybe<VideoConfig>>>;
};

/** The `File` type, represents the response of uploading a file. */
export type File = {
  __typename?: 'File';
  content: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export enum FileType {
  Audio = 'AUDIO',
  Meta = 'META',
  Video = 'VIDEO'
}

export enum Format {
  Dash = 'DASH',
  Hls = 'HLS',
  Undefined = 'UNDEFINED'
}

export type GroupInfo = {
  __typename?: 'GroupInfo';
  convivaKey?: Maybe<Scalars['String']['output']>;
  convivaUrl?: Maybe<Scalars['String']['output']>;
  fairplayUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  playreadyUrl?: Maybe<Scalars['String']['output']>;
  widevineUrl?: Maybe<Scalars['String']['output']>;
  xCDConfigId?: Maybe<Scalars['String']['output']>;
};

export type GroupInput = {
  id: Scalars['Int']['input'];
};

export type GroupsInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type ImageInfo = {
  __typename?: 'ImageInfo';
  height: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  imageType: ImageType;
  url: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

export type ImageInfoInput = {
  height: Scalars['Int']['input'];
  imageType: ImageType;
  url: Scalars['String']['input'];
  width: Scalars['Int']['input'];
};

export enum ImageType {
  Other = 'OTHER',
  Poster_720 = 'POSTER_720',
  Poster_1080 = 'POSTER_1080',
  Thumbnail_144 = 'THUMBNAIL_144',
  Thumbnail_540 = 'THUMBNAIL_540'
}

export type MutationRoot = {
  __typename?: 'MutationRoot';
  addImageInfos: Scalars['Boolean']['output'];
  addStreamInfos: Scalars['Boolean']['output'];
  cancelUploadRequest: Scalars['Boolean']['output'];
  createAsset: Scalars['Int']['output'];
  createGroup: Scalars['Int']['output'];
  createTag: TagInfo;
  deleteAsset: Scalars['Boolean']['output'];
  deleteGroup: Scalars['Boolean']['output'];
  deleteStreamInfo: Scalars['Boolean']['output'];
  deleteTag: Scalars['Boolean']['output'];
  processAssetWithUrl: Scalars['Boolean']['output'];
  updateAssetInfo: Scalars['Boolean']['output'];
  updateGroup: Scalars['Boolean']['output'];
  updateTag: Scalars['Boolean']['output'];
};


export type MutationRootAddImageInfosArgs = {
  addImageInfosInput: AddImageInfosInput;
};


export type MutationRootAddStreamInfosArgs = {
  addStreamInfosInput: AddStreamInfosInput;
};


export type MutationRootCancelUploadRequestArgs = {
  cancelUploadRequestInput: CancelUploadRequestInput;
};


export type MutationRootCreateAssetArgs = {
  createAssetInput: CreateAssetInput;
};


export type MutationRootCreateGroupArgs = {
  createGroupInput: CreateGroupInput;
};


export type MutationRootCreateTagArgs = {
  createTagInput: CreateTagInput;
};


export type MutationRootDeleteAssetArgs = {
  deleteAssetInput: DeleteAssetInput;
};


export type MutationRootDeleteGroupArgs = {
  deleteGroupInput: DeleteGroupInput;
};


export type MutationRootDeleteStreamInfoArgs = {
  deleteStreamInfoInput: DeleteStreamInfoInput;
};


export type MutationRootDeleteTagArgs = {
  deleteTagInput: DeleteTagInput;
};


export type MutationRootProcessAssetWithUrlArgs = {
  payload: UploadPayloadV2;
};


export type MutationRootUpdateAssetInfoArgs = {
  updateAssetInput: UpdateAssetInput;
};


export type MutationRootUpdateGroupArgs = {
  updateGroupInput: UpdateGroupInput;
};


export type MutationRootUpdateTagArgs = {
  updateTagInput: UpdateTagInput;
};

export type PackageConfig = {
  container?: InputMaybe<Container>;
  drm?: InputMaybe<Drm>;
  format?: InputMaybe<Format>;
  fragmentDuration?: InputMaybe<Scalars['Int']['input']>;
  segmentDuration?: InputMaybe<Scalars['Int']['input']>;
};

export type PackagePayload = {
  packageConfigs?: InputMaybe<Array<InputMaybe<PackageConfig>>>;
  packagerType: PackagerType;
};

export enum PackagerType {
  Hybrik = 'HYBRIK',
  Mss = 'MSS'
}

export enum Preset {
  Fast = 'FAST',
  Medium = 'MEDIUM',
  Slow = 'SLOW'
}

export type QueryRoot = {
  __typename?: 'QueryRoot';
  asset: Asset;
  assets: Array<Asset>;
  filterAssets: Array<Asset>;
  getUploadUrls: Array<UploadUrl>;
  group: GroupInfo;
  groups: Array<GroupInfo>;
  tag: TagInfo;
  tags: Array<TagInfo>;
};


export type QueryRootAssetArgs = {
  assetInput: AssetInput;
};


export type QueryRootAssetsArgs = {
  assetsInput: AssetsInput;
};


export type QueryRootFilterAssetsArgs = {
  assetsFilterInput: AssetsFilterInput;
};


export type QueryRootGetUploadUrlsArgs = {
  payload: UploadPayload;
};


export type QueryRootGroupArgs = {
  groupInput: GroupInput;
};


export type QueryRootGroupsArgs = {
  groupsInput: GroupsInput;
};


export type QueryRootTagArgs = {
  tagInput: TagInput;
};


export type QueryRootTagsArgs = {
  tagsInput: TagsInput;
};

export type SourceInfo = {
  __typename?: 'SourceInfo';
  cdn: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type SourceInfoInput = {
  cdn: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export enum Status {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Queued = 'QUEUED',
  Undefined = 'UNDEFINED',
  Uploading = 'UPLOADING'
}

export type StreamInfo = {
  __typename?: 'StreamInfo';
  audioInfos: Array<AudioInfo>;
  container: Container;
  drm: Drm;
  error?: Maybe<Scalars['String']['output']>;
  format: Format;
  id: Scalars['Int']['output'];
  segmentDuration: Scalars['Float']['output'];
  sourceInfos: Array<SourceInfo>;
  status: Status;
  taskId: Scalars['Int']['output'];
  textInfos: Array<TextInfo>;
  videoInfos: Array<VideoInfo>;
};

export type StreamInfoInput = {
  audioInfos: Array<AudioInfoInput>;
  container: Container;
  drm: Drm;
  error?: InputMaybe<Scalars['String']['input']>;
  format: Format;
  segmentDuration: Scalars['Float']['input'];
  sourceInfos: Array<SourceInfoInput>;
  status: Status;
  taskId: Scalars['Int']['input'];
  textInfos: Array<TextInfoInput>;
  videoInfos: Array<VideoInfoInput>;
};

export type TagInfo = {
  __typename?: 'TagInfo';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type TagInfoInput = {
  name: Scalars['String']['input'];
};

export type TagInput = {
  groupId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};

export type TagsInput = {
  groupId: Scalars['Int']['input'];
};

export type TextInfo = {
  __typename?: 'TextInfo';
  id: Scalars['Int']['output'];
  language: Scalars['String']['output'];
  textType: Scalars['String']['output'];
  trackId?: Maybe<Scalars['String']['output']>;
};

export type TextInfoInput = {
  language: Scalars['String']['input'];
  textType: Scalars['String']['input'];
  trackId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAssetInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  groupId?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
  live?: InputMaybe<Scalars['Boolean']['input']>;
  progress?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<Status>;
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGroupInput = {
  convivaKey?: InputMaybe<Scalars['String']['input']>;
  convivaUrl?: InputMaybe<Scalars['String']['input']>;
  fairplayUrl?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  playreadyUrl?: InputMaybe<Scalars['String']['input']>;
  widevineUrl?: InputMaybe<Scalars['String']['input']>;
  xCDConfigId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTagInput = {
  groupId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

/** The `UploadFile` type, represents the request for uploading a file with a certain payload. */
export type UploadFile = {
  contentType: Scalars['String']['input'];
  encodeConfig?: InputMaybe<EncodeConfig>;
  fileName: Scalars['String']['input'];
  fileType: FileType;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  supportMetaFileNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadFileV2 = {
  contentType: Scalars['String']['input'];
  encodeConfig?: InputMaybe<EncodeConfig>;
  fileName: Scalars['String']['input'];
  fileType: FileType;
  fileUrl: Scalars['String']['input'];
  supportMetaFileNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UploadPayload = {
  assetId: Scalars['Int']['input'];
  destinationBucketName?: InputMaybe<Scalars['String']['input']>;
  destinationPath?: InputMaybe<Scalars['String']['input']>;
  packagePayload?: InputMaybe<PackagePayload>;
  sourceBucketName: Scalars['String']['input'];
  sourcePath: Scalars['String']['input'];
  uploadFiles: Array<UploadFile>;
};

export type UploadPayloadV2 = {
  assetId: Scalars['Int']['input'];
  destinationBucketName?: InputMaybe<Scalars['String']['input']>;
  destinationPath?: InputMaybe<Scalars['String']['input']>;
  packagePayload?: InputMaybe<PackagePayload>;
  uploadFiles: Array<UploadFileV2>;
};

export type UploadUrl = {
  __typename?: 'UploadUrl';
  fileName: Scalars['String']['output'];
  uploadUrl: Scalars['String']['output'];
};

export enum VideoCodec {
  H_264 = 'H_264',
  H_265 = 'H_265'
}

export type VideoConfig = {
  bitrateLadder: Array<InputMaybe<BitrateRendition>>;
  bitrateMode?: InputMaybe<BitrateMode>;
  chromaFormat?: InputMaybe<ChromaFormat>;
  codec: VideoCodec;
  colorDepth?: InputMaybe<ColorDepth>;
  colorSpace?: InputMaybe<ColorSpace>;
  iFrameInterval?: InputMaybe<Scalars['Int']['input']>;
  idrInterval?: InputMaybe<Scalars['Int']['input']>;
  preset?: InputMaybe<Preset>;
};

export type VideoInfo = {
  __typename?: 'VideoInfo';
  bitdepth: Scalars['Int']['output'];
  bitrate: Scalars['Int']['output'];
  codec: Scalars['String']['output'];
  fps: Scalars['Float']['output'];
  height: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  trackId: Scalars['Int']['output'];
  width: Scalars['Int']['output'];
};

export type VideoInfoInput = {
  bitdepth: Scalars['Int']['input'];
  bitrate: Scalars['Int']['input'];
  codec: Scalars['String']['input'];
  fps: Scalars['Float']['input'];
  height: Scalars['Int']['input'];
  trackId: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

export type GetAllAssetsQueryVariables = Exact<{
  assetsInput: AssetsInput;
}>;


export type GetAllAssetsQuery = { __typename?: 'QueryRoot', assets: Array<{ __typename?: 'Asset', description: string, duration: number, id: number, title: string, imageInfos: Array<{ __typename?: 'ImageInfo', height: number, imageType: ImageType, url: string, width: number }>, streamInfos: Array<{ __typename?: 'StreamInfo', id: number, container: Container, drm: Drm, format: Format, segmentDuration: number, sourceInfos: Array<{ __typename?: 'SourceInfo', cdn: string, url: string }>, videoInfos: Array<{ __typename?: 'VideoInfo', bitrate: number, bitdepth: number }> }>, tagInfos: Array<{ __typename?: 'TagInfo', name: string }> }> };

export type GetAllAssetFieldsFragment = { __typename?: 'Asset', description: string, duration: number, id: number, title: string, imageInfos: Array<{ __typename?: 'ImageInfo', height: number, imageType: ImageType, url: string, width: number }>, streamInfos: Array<{ __typename?: 'StreamInfo', id: number, container: Container, drm: Drm, format: Format, segmentDuration: number, sourceInfos: Array<{ __typename?: 'SourceInfo', cdn: string, url: string }>, videoInfos: Array<{ __typename?: 'VideoInfo', bitrate: number, bitdepth: number }> }>, tagInfos: Array<{ __typename?: 'TagInfo', name: string }> };

export type GetAssetQueryVariables = Exact<{
  assetInput: AssetInput;
}>;


export type GetAssetQuery = { __typename?: 'QueryRoot', asset: { __typename?: 'Asset', id: number, title: string, description: string, duration: number, imageInfos: Array<{ __typename?: 'ImageInfo', height: number, imageType: ImageType, url: string, width: number }>, tagInfos: Array<{ __typename?: 'TagInfo', id: number, name: string }> } };

export type CreateAssetMutationVariables = Exact<{
  createAssetInput: CreateAssetInput;
}>;


export type CreateAssetMutation = { __typename?: 'MutationRoot', createAsset: number };

export type CreateTagMutationVariables = Exact<{
  createTagInput: CreateTagInput;
}>;


export type CreateTagMutation = { __typename?: 'MutationRoot', createTag: { __typename?: 'TagInfo', id: number, name: string } };

export type UpdateAssetInfoMutationVariables = Exact<{
  updateAssetInput: UpdateAssetInput;
}>;


export type UpdateAssetInfoMutation = { __typename?: 'MutationRoot', updateAssetInfo: boolean };

export type UpdateTagMutationVariables = Exact<{
  updateTagInput: UpdateTagInput;
}>;


export type UpdateTagMutation = { __typename?: 'MutationRoot', updateTag: boolean };

export type DeleteAssetMutationVariables = Exact<{
  deleteAssetInput: DeleteAssetInput;
}>;


export type DeleteAssetMutation = { __typename?: 'MutationRoot', deleteAsset: boolean };

export type DeleteTagMutationVariables = Exact<{
  deleteTagInput: DeleteTagInput;
}>;


export type DeleteTagMutation = { __typename?: 'MutationRoot', deleteTag: boolean };

export type GetAssetStreamsQueryVariables = Exact<{
  assetInput: AssetInput;
}>;


export type GetAssetStreamsQuery = { __typename?: 'QueryRoot', asset: { __typename?: 'Asset', id: number, title: string, description: string, duration: number, streamInfos: Array<{ __typename?: 'StreamInfo', id: number, container: Container, drm: Drm, format: Format, segmentDuration: number, taskId: number, status: Status, error?: string | null, audioInfos: Array<{ __typename?: 'AudioInfo', bitrate: number, channels: number, codec: string, language: string, trackId: number }>, sourceInfos: Array<{ __typename?: 'SourceInfo', cdn: string, url: string }>, textInfos: Array<{ __typename?: 'TextInfo', language: string, textType: string, trackId?: string | null }>, videoInfos: Array<{ __typename?: 'VideoInfo', bitdepth: number, bitrate: number, codec: string, fps: number, height: number, trackId: number, width: number }> }>, imageInfos: Array<{ __typename?: 'ImageInfo', imageType: ImageType, url: string }> } };

export type AssetWithStreamInfoFieldsFragment = { __typename?: 'Asset', id: number, title: string, description: string, duration: number, streamInfos: Array<{ __typename?: 'StreamInfo', id: number, container: Container, drm: Drm, format: Format, segmentDuration: number, taskId: number, status: Status, error?: string | null, audioInfos: Array<{ __typename?: 'AudioInfo', bitrate: number, channels: number, codec: string, language: string, trackId: number }>, sourceInfos: Array<{ __typename?: 'SourceInfo', cdn: string, url: string }>, textInfos: Array<{ __typename?: 'TextInfo', language: string, textType: string, trackId?: string | null }>, videoInfos: Array<{ __typename?: 'VideoInfo', bitdepth: number, bitrate: number, codec: string, fps: number, height: number, trackId: number, width: number }> }>, imageInfos: Array<{ __typename?: 'ImageInfo', imageType: ImageType, url: string }> };

export type AssetDetailsFieldsFragment = { __typename?: 'Asset', id: number, title: string, description: string, duration: number, imageInfos: Array<{ __typename?: 'ImageInfo', imageType: ImageType, url: string }> };

export type StreamInfoFieldsFragment = { __typename?: 'StreamInfo', id: number, container: Container, drm: Drm, format: Format, segmentDuration: number, taskId: number, status: Status, error?: string | null, audioInfos: Array<{ __typename?: 'AudioInfo', bitrate: number, channels: number, codec: string, language: string, trackId: number }>, sourceInfos: Array<{ __typename?: 'SourceInfo', cdn: string, url: string }>, textInfos: Array<{ __typename?: 'TextInfo', language: string, textType: string, trackId?: string | null }>, videoInfos: Array<{ __typename?: 'VideoInfo', bitdepth: number, bitrate: number, codec: string, fps: number, height: number, trackId: number, width: number }> };

export type DeleteStreamInfoMutationVariables = Exact<{
  deleteStreamInfoInput: DeleteStreamInfoInput;
}>;


export type DeleteStreamInfoMutation = { __typename?: 'MutationRoot', deleteStreamInfo: boolean };

export type GetAssetStatusQueryVariables = Exact<{
  assetsInput: AssetsInput;
}>;


export type GetAssetStatusQuery = { __typename?: 'QueryRoot', assets: Array<{ __typename?: 'Asset', id: number, status: Status, error?: string | null, progress: number }> };

export type GetAssetStreamStatusesQueryVariables = Exact<{
  assetInput: AssetInput;
}>;


export type GetAssetStreamStatusesQuery = { __typename?: 'QueryRoot', asset: { __typename?: 'Asset', title: string, description: string, streamInfos: Array<{ __typename?: 'StreamInfo', taskId: number, status: Status, error?: string | null }> } };

export type GetAssetStreamStatusesFieldsFragment = { __typename?: 'Asset', title: string, description: string, streamInfos: Array<{ __typename?: 'StreamInfo', taskId: number, status: Status, error?: string | null }> };

export type CancelUploadRequestMutationVariables = Exact<{
  cancelUploadRequestInput: CancelUploadRequestInput;
}>;


export type CancelUploadRequestMutation = { __typename?: 'MutationRoot', cancelUploadRequest: boolean };

export type GetUploadUrlsQueryVariables = Exact<{
  payload: UploadPayload;
}>;


export type GetUploadUrlsQuery = { __typename?: 'QueryRoot', getUploadUrls: Array<{ __typename?: 'UploadUrl', fileName: string, uploadUrl: string }> };

export type TagsQueryVariables = Exact<{
  tagsInput: TagsInput;
}>;


export type TagsQuery = { __typename?: 'QueryRoot', tags: Array<{ __typename?: 'TagInfo', id: number, name: string }> };

export type TagFieldsFragment = { __typename?: 'TagInfo', id: number, name: string };

export type TagQueryVariables = Exact<{
  tagInput: TagInput;
}>;


export type TagQuery = { __typename?: 'QueryRoot', tag: { __typename?: 'TagInfo', id: number, name: string } };

export type GetAllAssetTagsQueryVariables = Exact<{
  assetsInput: AssetsInput;
}>;


export type GetAllAssetTagsQuery = { __typename?: 'QueryRoot', assets: Array<{ __typename?: 'Asset', tagInfos: Array<{ __typename?: 'TagInfo', id: number, name: string }> }> };

export type AllAssetTagsFragment = { __typename?: 'Asset', tagInfos: Array<{ __typename?: 'TagInfo', id: number, name: string }> };

export type GetGroupsQueryVariables = Exact<{
  groupsInput: GroupsInput;
}>;


export type GetGroupsQuery = { __typename?: 'QueryRoot', groups: Array<{ __typename?: 'GroupInfo', id: number, name: string }> };

export const GetAllAssetFieldsFragmentDoc = gql`
    fragment GetAllAssetFields on Asset {
  description
  duration
  id
  title
  imageInfos {
    height
    imageType
    url
    width
  }
  streamInfos {
    id
    container
    drm
    format
    segmentDuration
    sourceInfos {
      cdn
      url
    }
    videoInfos {
      bitrate
      bitdepth
    }
  }
  tagInfos {
    name
  }
}
    `;
export const AssetDetailsFieldsFragmentDoc = gql`
    fragment AssetDetailsFields on Asset {
  id
  title
  description
  duration
  imageInfos {
    imageType
    url
  }
}
    `;
export const StreamInfoFieldsFragmentDoc = gql`
    fragment StreamInfoFields on StreamInfo {
  id
  container
  drm
  format
  segmentDuration
  taskId
  status
  error
  audioInfos {
    bitrate
    channels
    codec
    language
    trackId
  }
  sourceInfos {
    cdn
    url
  }
  textInfos {
    language
    textType
    trackId
  }
  videoInfos {
    bitdepth
    bitrate
    codec
    fps
    height
    trackId
    width
  }
}
    `;
export const AssetWithStreamInfoFieldsFragmentDoc = gql`
    fragment AssetWithStreamInfoFields on Asset {
  ...AssetDetailsFields
  streamInfos {
    ...StreamInfoFields
  }
}
    ${AssetDetailsFieldsFragmentDoc}
${StreamInfoFieldsFragmentDoc}`;
export const GetAssetStreamStatusesFieldsFragmentDoc = gql`
    fragment GetAssetStreamStatusesFields on Asset {
  title
  description
  streamInfos {
    taskId
    status
    error
  }
}
    `;
export const TagFieldsFragmentDoc = gql`
    fragment TagFields on TagInfo {
  id
  name
}
    `;
export const AllAssetTagsFragmentDoc = gql`
    fragment AllAssetTags on Asset {
  tagInfos {
    id
    name
  }
}
    `;
export const GetAllAssetsDocument = gql`
    query GetAllAssets($assetsInput: AssetsInput!) {
  assets(assetsInput: $assetsInput) {
    ...GetAllAssetFields
  }
}
    ${GetAllAssetFieldsFragmentDoc}`;

/**
 * __useGetAllAssetsQuery__
 *
 * To run a query within a React component, call `useGetAllAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssetsQuery({
 *   variables: {
 *      assetsInput: // value for 'assetsInput'
 *   },
 * });
 */
export function useGetAllAssetsQuery(baseOptions: Apollo.QueryHookOptions<GetAllAssetsQuery, GetAllAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAssetsQuery, GetAllAssetsQueryVariables>(GetAllAssetsDocument, options);
      }
export function useGetAllAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAssetsQuery, GetAllAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAssetsQuery, GetAllAssetsQueryVariables>(GetAllAssetsDocument, options);
        }
export type GetAllAssetsQueryHookResult = ReturnType<typeof useGetAllAssetsQuery>;
export type GetAllAssetsLazyQueryHookResult = ReturnType<typeof useGetAllAssetsLazyQuery>;
export type GetAllAssetsQueryResult = Apollo.QueryResult<GetAllAssetsQuery, GetAllAssetsQueryVariables>;
export const GetAssetDocument = gql`
    query GetAsset($assetInput: AssetInput!) {
  asset(assetInput: $assetInput) {
    id
    title
    description
    duration
    imageInfos {
      height
      imageType
      url
      width
    }
    tagInfos {
      id
      name
    }
  }
}
    `;

/**
 * __useGetAssetQuery__
 *
 * To run a query within a React component, call `useGetAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetQuery({
 *   variables: {
 *      assetInput: // value for 'assetInput'
 *   },
 * });
 */
export function useGetAssetQuery(baseOptions: Apollo.QueryHookOptions<GetAssetQuery, GetAssetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetQuery, GetAssetQueryVariables>(GetAssetDocument, options);
      }
export function useGetAssetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetQuery, GetAssetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetQuery, GetAssetQueryVariables>(GetAssetDocument, options);
        }
export type GetAssetQueryHookResult = ReturnType<typeof useGetAssetQuery>;
export type GetAssetLazyQueryHookResult = ReturnType<typeof useGetAssetLazyQuery>;
export type GetAssetQueryResult = Apollo.QueryResult<GetAssetQuery, GetAssetQueryVariables>;
export const CreateAssetDocument = gql`
    mutation CreateAsset($createAssetInput: CreateAssetInput!) {
  createAsset(createAssetInput: $createAssetInput)
}
    `;
export type CreateAssetMutationFn = Apollo.MutationFunction<CreateAssetMutation, CreateAssetMutationVariables>;

/**
 * __useCreateAssetMutation__
 *
 * To run a mutation, you first call `useCreateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetMutation, { data, loading, error }] = useCreateAssetMutation({
 *   variables: {
 *      createAssetInput: // value for 'createAssetInput'
 *   },
 * });
 */
export function useCreateAssetMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssetMutation, CreateAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssetMutation, CreateAssetMutationVariables>(CreateAssetDocument, options);
      }
export type CreateAssetMutationHookResult = ReturnType<typeof useCreateAssetMutation>;
export type CreateAssetMutationResult = Apollo.MutationResult<CreateAssetMutation>;
export type CreateAssetMutationOptions = Apollo.BaseMutationOptions<CreateAssetMutation, CreateAssetMutationVariables>;
export const CreateTagDocument = gql`
    mutation CreateTag($createTagInput: CreateTagInput!) {
  createTag(createTagInput: $createTagInput) {
    id
    name
  }
}
    `;
export type CreateTagMutationFn = Apollo.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      createTagInput: // value for 'createTagInput'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, options);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const UpdateAssetInfoDocument = gql`
    mutation UpdateAssetInfo($updateAssetInput: UpdateAssetInput!) {
  updateAssetInfo(updateAssetInput: $updateAssetInput)
}
    `;
export type UpdateAssetInfoMutationFn = Apollo.MutationFunction<UpdateAssetInfoMutation, UpdateAssetInfoMutationVariables>;

/**
 * __useUpdateAssetInfoMutation__
 *
 * To run a mutation, you first call `useUpdateAssetInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetInfoMutation, { data, loading, error }] = useUpdateAssetInfoMutation({
 *   variables: {
 *      updateAssetInput: // value for 'updateAssetInput'
 *   },
 * });
 */
export function useUpdateAssetInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssetInfoMutation, UpdateAssetInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssetInfoMutation, UpdateAssetInfoMutationVariables>(UpdateAssetInfoDocument, options);
      }
export type UpdateAssetInfoMutationHookResult = ReturnType<typeof useUpdateAssetInfoMutation>;
export type UpdateAssetInfoMutationResult = Apollo.MutationResult<UpdateAssetInfoMutation>;
export type UpdateAssetInfoMutationOptions = Apollo.BaseMutationOptions<UpdateAssetInfoMutation, UpdateAssetInfoMutationVariables>;
export const UpdateTagDocument = gql`
    mutation UpdateTag($updateTagInput: UpdateTagInput!) {
  updateTag(updateTagInput: $updateTagInput)
}
    `;
export type UpdateTagMutationFn = Apollo.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      updateTagInput: // value for 'updateTagInput'
 *   },
 * });
 */
export function useUpdateTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, options);
      }
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>;
export const DeleteAssetDocument = gql`
    mutation DeleteAsset($deleteAssetInput: DeleteAssetInput!) {
  deleteAsset(deleteAssetInput: $deleteAssetInput)
}
    `;
export type DeleteAssetMutationFn = Apollo.MutationFunction<DeleteAssetMutation, DeleteAssetMutationVariables>;

/**
 * __useDeleteAssetMutation__
 *
 * To run a mutation, you first call `useDeleteAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetMutation, { data, loading, error }] = useDeleteAssetMutation({
 *   variables: {
 *      deleteAssetInput: // value for 'deleteAssetInput'
 *   },
 * });
 */
export function useDeleteAssetMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAssetMutation, DeleteAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAssetMutation, DeleteAssetMutationVariables>(DeleteAssetDocument, options);
      }
export type DeleteAssetMutationHookResult = ReturnType<typeof useDeleteAssetMutation>;
export type DeleteAssetMutationResult = Apollo.MutationResult<DeleteAssetMutation>;
export type DeleteAssetMutationOptions = Apollo.BaseMutationOptions<DeleteAssetMutation, DeleteAssetMutationVariables>;
export const DeleteTagDocument = gql`
    mutation DeleteTag($deleteTagInput: DeleteTagInput!) {
  deleteTag(deleteTagInput: $deleteTagInput)
}
    `;
export type DeleteTagMutationFn = Apollo.MutationFunction<DeleteTagMutation, DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      deleteTagInput: // value for 'deleteTagInput'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DeleteTagDocument, options);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<DeleteTagMutation, DeleteTagMutationVariables>;
export const GetAssetStreamsDocument = gql`
    query GetAssetStreams($assetInput: AssetInput!) {
  asset(assetInput: $assetInput) {
    ...AssetWithStreamInfoFields
  }
}
    ${AssetWithStreamInfoFieldsFragmentDoc}`;

/**
 * __useGetAssetStreamsQuery__
 *
 * To run a query within a React component, call `useGetAssetStreamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetStreamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetStreamsQuery({
 *   variables: {
 *      assetInput: // value for 'assetInput'
 *   },
 * });
 */
export function useGetAssetStreamsQuery(baseOptions: Apollo.QueryHookOptions<GetAssetStreamsQuery, GetAssetStreamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetStreamsQuery, GetAssetStreamsQueryVariables>(GetAssetStreamsDocument, options);
      }
export function useGetAssetStreamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetStreamsQuery, GetAssetStreamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetStreamsQuery, GetAssetStreamsQueryVariables>(GetAssetStreamsDocument, options);
        }
export type GetAssetStreamsQueryHookResult = ReturnType<typeof useGetAssetStreamsQuery>;
export type GetAssetStreamsLazyQueryHookResult = ReturnType<typeof useGetAssetStreamsLazyQuery>;
export type GetAssetStreamsQueryResult = Apollo.QueryResult<GetAssetStreamsQuery, GetAssetStreamsQueryVariables>;
export const DeleteStreamInfoDocument = gql`
    mutation DeleteStreamInfo($deleteStreamInfoInput: DeleteStreamInfoInput!) {
  deleteStreamInfo(deleteStreamInfoInput: $deleteStreamInfoInput)
}
    `;
export type DeleteStreamInfoMutationFn = Apollo.MutationFunction<DeleteStreamInfoMutation, DeleteStreamInfoMutationVariables>;

/**
 * __useDeleteStreamInfoMutation__
 *
 * To run a mutation, you first call `useDeleteStreamInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStreamInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStreamInfoMutation, { data, loading, error }] = useDeleteStreamInfoMutation({
 *   variables: {
 *      deleteStreamInfoInput: // value for 'deleteStreamInfoInput'
 *   },
 * });
 */
export function useDeleteStreamInfoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStreamInfoMutation, DeleteStreamInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStreamInfoMutation, DeleteStreamInfoMutationVariables>(DeleteStreamInfoDocument, options);
      }
export type DeleteStreamInfoMutationHookResult = ReturnType<typeof useDeleteStreamInfoMutation>;
export type DeleteStreamInfoMutationResult = Apollo.MutationResult<DeleteStreamInfoMutation>;
export type DeleteStreamInfoMutationOptions = Apollo.BaseMutationOptions<DeleteStreamInfoMutation, DeleteStreamInfoMutationVariables>;
export const GetAssetStatusDocument = gql`
    query GetAssetStatus($assetsInput: AssetsInput!) {
  assets(assetsInput: $assetsInput) {
    id
    status
    error
    progress
  }
}
    `;

/**
 * __useGetAssetStatusQuery__
 *
 * To run a query within a React component, call `useGetAssetStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetStatusQuery({
 *   variables: {
 *      assetsInput: // value for 'assetsInput'
 *   },
 * });
 */
export function useGetAssetStatusQuery(baseOptions: Apollo.QueryHookOptions<GetAssetStatusQuery, GetAssetStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetStatusQuery, GetAssetStatusQueryVariables>(GetAssetStatusDocument, options);
      }
export function useGetAssetStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetStatusQuery, GetAssetStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetStatusQuery, GetAssetStatusQueryVariables>(GetAssetStatusDocument, options);
        }
export type GetAssetStatusQueryHookResult = ReturnType<typeof useGetAssetStatusQuery>;
export type GetAssetStatusLazyQueryHookResult = ReturnType<typeof useGetAssetStatusLazyQuery>;
export type GetAssetStatusQueryResult = Apollo.QueryResult<GetAssetStatusQuery, GetAssetStatusQueryVariables>;
export const GetAssetStreamStatusesDocument = gql`
    query GetAssetStreamStatuses($assetInput: AssetInput!) {
  asset(assetInput: $assetInput) {
    ...GetAssetStreamStatusesFields
  }
}
    ${GetAssetStreamStatusesFieldsFragmentDoc}`;

/**
 * __useGetAssetStreamStatusesQuery__
 *
 * To run a query within a React component, call `useGetAssetStreamStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetStreamStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetStreamStatusesQuery({
 *   variables: {
 *      assetInput: // value for 'assetInput'
 *   },
 * });
 */
export function useGetAssetStreamStatusesQuery(baseOptions: Apollo.QueryHookOptions<GetAssetStreamStatusesQuery, GetAssetStreamStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssetStreamStatusesQuery, GetAssetStreamStatusesQueryVariables>(GetAssetStreamStatusesDocument, options);
      }
export function useGetAssetStreamStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssetStreamStatusesQuery, GetAssetStreamStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssetStreamStatusesQuery, GetAssetStreamStatusesQueryVariables>(GetAssetStreamStatusesDocument, options);
        }
export type GetAssetStreamStatusesQueryHookResult = ReturnType<typeof useGetAssetStreamStatusesQuery>;
export type GetAssetStreamStatusesLazyQueryHookResult = ReturnType<typeof useGetAssetStreamStatusesLazyQuery>;
export type GetAssetStreamStatusesQueryResult = Apollo.QueryResult<GetAssetStreamStatusesQuery, GetAssetStreamStatusesQueryVariables>;
export const CancelUploadRequestDocument = gql`
    mutation cancelUploadRequest($cancelUploadRequestInput: CancelUploadRequestInput!) {
  cancelUploadRequest(cancelUploadRequestInput: $cancelUploadRequestInput)
}
    `;
export type CancelUploadRequestMutationFn = Apollo.MutationFunction<CancelUploadRequestMutation, CancelUploadRequestMutationVariables>;

/**
 * __useCancelUploadRequestMutation__
 *
 * To run a mutation, you first call `useCancelUploadRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelUploadRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelUploadRequestMutation, { data, loading, error }] = useCancelUploadRequestMutation({
 *   variables: {
 *      cancelUploadRequestInput: // value for 'cancelUploadRequestInput'
 *   },
 * });
 */
export function useCancelUploadRequestMutation(baseOptions?: Apollo.MutationHookOptions<CancelUploadRequestMutation, CancelUploadRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelUploadRequestMutation, CancelUploadRequestMutationVariables>(CancelUploadRequestDocument, options);
      }
export type CancelUploadRequestMutationHookResult = ReturnType<typeof useCancelUploadRequestMutation>;
export type CancelUploadRequestMutationResult = Apollo.MutationResult<CancelUploadRequestMutation>;
export type CancelUploadRequestMutationOptions = Apollo.BaseMutationOptions<CancelUploadRequestMutation, CancelUploadRequestMutationVariables>;
export const GetUploadUrlsDocument = gql`
    query getUploadUrls($payload: UploadPayload!) {
  getUploadUrls(payload: $payload) {
    fileName
    uploadUrl
  }
}
    `;

/**
 * __useGetUploadUrlsQuery__
 *
 * To run a query within a React component, call `useGetUploadUrlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadUrlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadUrlsQuery({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useGetUploadUrlsQuery(baseOptions: Apollo.QueryHookOptions<GetUploadUrlsQuery, GetUploadUrlsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUploadUrlsQuery, GetUploadUrlsQueryVariables>(GetUploadUrlsDocument, options);
      }
export function useGetUploadUrlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUploadUrlsQuery, GetUploadUrlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUploadUrlsQuery, GetUploadUrlsQueryVariables>(GetUploadUrlsDocument, options);
        }
export type GetUploadUrlsQueryHookResult = ReturnType<typeof useGetUploadUrlsQuery>;
export type GetUploadUrlsLazyQueryHookResult = ReturnType<typeof useGetUploadUrlsLazyQuery>;
export type GetUploadUrlsQueryResult = Apollo.QueryResult<GetUploadUrlsQuery, GetUploadUrlsQueryVariables>;
export const TagsDocument = gql`
    query tags($tagsInput: TagsInput!) {
  tags(tagsInput: $tagsInput) {
    ...TagFields
  }
}
    ${TagFieldsFragmentDoc}`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      tagsInput: // value for 'tagsInput'
 *   },
 * });
 */
export function useTagsQuery(baseOptions: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const TagDocument = gql`
    query tag($tagInput: TagInput!) {
  tag(tagInput: $tagInput) {
    ...TagFields
  }
}
    ${TagFieldsFragmentDoc}`;

/**
 * __useTagQuery__
 *
 * To run a query within a React component, call `useTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagQuery({
 *   variables: {
 *      tagInput: // value for 'tagInput'
 *   },
 * });
 */
export function useTagQuery(baseOptions: Apollo.QueryHookOptions<TagQuery, TagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagQuery, TagQueryVariables>(TagDocument, options);
      }
export function useTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagQuery, TagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagQuery, TagQueryVariables>(TagDocument, options);
        }
export type TagQueryHookResult = ReturnType<typeof useTagQuery>;
export type TagLazyQueryHookResult = ReturnType<typeof useTagLazyQuery>;
export type TagQueryResult = Apollo.QueryResult<TagQuery, TagQueryVariables>;
export const GetAllAssetTagsDocument = gql`
    query GetAllAssetTags($assetsInput: AssetsInput!) {
  assets(assetsInput: $assetsInput) {
    ...AllAssetTags
  }
}
    ${AllAssetTagsFragmentDoc}`;

/**
 * __useGetAllAssetTagsQuery__
 *
 * To run a query within a React component, call `useGetAllAssetTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssetTagsQuery({
 *   variables: {
 *      assetsInput: // value for 'assetsInput'
 *   },
 * });
 */
export function useGetAllAssetTagsQuery(baseOptions: Apollo.QueryHookOptions<GetAllAssetTagsQuery, GetAllAssetTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAssetTagsQuery, GetAllAssetTagsQueryVariables>(GetAllAssetTagsDocument, options);
      }
export function useGetAllAssetTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAssetTagsQuery, GetAllAssetTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAssetTagsQuery, GetAllAssetTagsQueryVariables>(GetAllAssetTagsDocument, options);
        }
export type GetAllAssetTagsQueryHookResult = ReturnType<typeof useGetAllAssetTagsQuery>;
export type GetAllAssetTagsLazyQueryHookResult = ReturnType<typeof useGetAllAssetTagsLazyQuery>;
export type GetAllAssetTagsQueryResult = Apollo.QueryResult<GetAllAssetTagsQuery, GetAllAssetTagsQueryVariables>;
export const GetGroupsDocument = gql`
    query GetGroups($groupsInput: GroupsInput!) {
  groups(groupsInput: $groupsInput) {
    id
    name
  }
}
    `;

/**
 * __useGetGroupsQuery__
 *
 * To run a query within a React component, call `useGetGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsQuery({
 *   variables: {
 *      groupsInput: // value for 'groupsInput'
 *   },
 * });
 */
export function useGetGroupsQuery(baseOptions: Apollo.QueryHookOptions<GetGroupsQuery, GetGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupsQuery, GetGroupsQueryVariables>(GetGroupsDocument, options);
      }
export function useGetGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupsQuery, GetGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupsQuery, GetGroupsQueryVariables>(GetGroupsDocument, options);
        }
export type GetGroupsQueryHookResult = ReturnType<typeof useGetGroupsQuery>;
export type GetGroupsLazyQueryHookResult = ReturnType<typeof useGetGroupsLazyQuery>;
export type GetGroupsQueryResult = Apollo.QueryResult<GetGroupsQuery, GetGroupsQueryVariables>;
import {
  AspectRatios,
  ResolutionHeights,
  ResolutionNames,
} from 'common/models/Resolutions';

export interface BasicVideoInfos {
  width: number;
  height: number;
  bitrate: number;
  bitdepth: number;
}

export function sortByBitrate<T extends { bitrate: number }>(
  items: Array<T>
): Array<T> {
  return [...items].sort((a, b) => b.bitrate - a.bitrate);
}

export function getTopResolution<T extends BasicVideoInfos>(
  videoInfos: Array<T>
): T | undefined {
  return sortByBitrate([...videoInfos])[0];
}

export function getResolutionName(
  vid: BasicVideoInfos | undefined
): ResolutionNames {
  let res = ResolutionNames.UNKNOWN;
  if (vid) {
    if (vid.height >= ResolutionHeights.UHD_8K) {
      res = ResolutionNames.UHD_8K;
    } else if (vid.height >= ResolutionHeights.UHD_4K) {
      res = ResolutionNames.UHD_4K;
    } else if (vid.height >= ResolutionHeights.QHD) {
      res = ResolutionNames.QHD;
    } else if (vid.height >= ResolutionHeights.HD_1080P) {
      res =
        vid.height / vid.width === AspectRatios.RATIO_1_1_9
          ? ResolutionNames.HD_2K
          : ResolutionNames.HD_1080P;
    } else if (vid.height >= ResolutionHeights.HD_720P) {
      res = ResolutionNames.HD_720P;
    } else {
      res = ResolutionNames.SD;
    }
  }
  return res;
}

export function isHDR(vid: { bitdepth: number }): boolean {
  return vid.bitdepth >= 10;
}

export function formatBitrate(bitrate: number): string {
  return formatNumberWithCommas(Math.floor(bitrate / 1000)) + ' Kbps';
}

export function formatNumberWithCommas(x: number): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * Takes a number of seconds and returns it in the format of HH:MM:SS
 **/
export function formatSecondsToHMSString(time: number): string {
  let hr: number | string;
  let min: number | string;
  let sec: number | string;

  if (time && !isNaN(time) && time >= 0 && isFinite(time)) {
    hr = Math.floor(time / 3600);
    min = Math.floor((time % 3600) / 60);
    sec = Math.floor(time % 60);
  } else {
    hr = 0;
    min = 0;
    sec = 0;
  }

  hr = hr >= 10 ? hr.toFixed(0) : `0${hr.toFixed(0)}`;
  min = min >= 10 ? min.toFixed(0) : `0${min.toFixed(0)}`;
  sec = sec >= 10 ? sec.toFixed(0) : `0${sec.toFixed(0)}`;

  return `${hr}:${min}:${sec}`;
}

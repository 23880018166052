import { IconButton, Typography, DialogTitle } from '@mui/material';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/system';

interface ModalTitleProps {
  title: string;
  onClose?: () => void;
  className?: string;
}

const ModalTitle = ({ title, onClose, className }: ModalTitleProps) => {
  return (
    <div className={className}>
      <DialogTitle className="dialogTitle">
        <div>
          <Typography variant="h6">{title}</Typography>
        </div>
        {onClose ? (
          <IconButton
            aria-label="close"
            className="closeButton"
            onClick={onClose}
            size="large"
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
    </div>
  );
};

const styledTagPage = styled(ModalTitle)({
  margin: '0',
  padding: '5px 10px',
  '.dialogTitle': {
    margin: '0',
    padding: '0',
  },
  '.closeButton': {
    position: 'absolute',
    right: '0px',
    top: '-2px',
  },
});

export default styledTagPage;

import { CreateAssetInput } from 'api/graphql/generated';
import {
  ComponentType,
  Dispatch,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import {
  ActionType,
  UpdateAssetIFormAction,
  AddStreamIFormAction,
  UpdateStreamIFormAction,
  AddVideoIFormAction,
  UpdateVideoIFormAction,
  AddAudioIFormAction,
  UpdateAudioIFormAction,
  AddTextIFormAction,
  UpdateTextIFormAction,
  AddSourceIFormAction,
  UpdateSourceIFormAction,
  AddImageIFormAction,
  UpdateImageIFormAction,
  AddDeleteTagIFormAction,
  AssetImportAction,
  DeleteStreamIFromAction,
  DeleteImageIFromAction,
  DeleteVideoIFromAction,
  DeleteAudioIFromAction,
  DeleteTextIFromAction,
  DeleteSourceIFromAction,
} from '../types';
import { DEFAULT_IMPORT_ASSET_FORM } from '../constants/defaults';
import { FeatureLogger } from 'common/logging';

export const ImportFormContext = createContext<CreateAssetInput | null>(null);
export const ImportFormDispatchContext =
  createContext<Dispatch<AssetImportAction> | null>(null);

const useImportFormContext = (): CreateAssetInput => {
  const context = useContext(ImportFormContext);
  if (context === null) {
    throw new Error(
      'useImportFormContext must be used with ImportFormProviders'
    );
  }
  return context;
};
const useImportFormDispatchContext = (): Dispatch<AssetImportAction> => {
  const context = useContext(ImportFormDispatchContext);
  if (context === null) {
    throw new Error(
      'useImportFormDispatchContext must be used with ImportFormProviders'
    );
  }
  return context;
};

const ImportFormProviders = ({ children }: { children: JSX.Element }) => {
  const [formState, dispatch] = useReducer(
    tasksReducer,
    DEFAULT_IMPORT_ASSET_FORM
  );

  useEffect(() => {
    FeatureLogger.AssetImport('Form updated: ', formState);
  }, [formState]);

  return (
    <ImportFormContext.Provider value={formState}>
      <ImportFormDispatchContext.Provider value={dispatch}>
        {children}
      </ImportFormDispatchContext.Provider>
    </ImportFormContext.Provider>
  );
};

const withImportFormContext =
  <P extends object>(Component: ComponentType<P>) =>
  (props: P) =>
    (
      <ImportFormProviders>
        <Component {...props} />
      </ImportFormProviders>
    );

const newUpdatedObjectByField = <T extends Record<string, unknown>>(
  obj: T,
  field: keyof T,
  newValue: unknown
): T => {
  return Object.entries(obj).reduce((accum, [key, value]) => {
    if (key !== field) {
      return { ...accum, [key]: value };
    }
    if (typeof value !== typeof newValue) {
      throw new Error(
        'dispatched invalid type. Expected: ' +
          typeof value +
          ' Received: ' +
          typeof newValue
      );
    }
    return { ...accum, [key]: newValue };
  }, {}) as T;
};

const tasksReducer = (
  formState: CreateAssetInput,
  action: AssetImportAction
) => {
  switch (action.type) {
    case ActionType.UpdateAsset: {
      const knownAction = action as UpdateAssetIFormAction;
      const { field, value: newValue } = knownAction.payload;

      const nextState = JSON.parse(
        JSON.stringify(formState)
      ) as CreateAssetInput;

      return newUpdatedObjectByField(nextState, field, newValue);
    }
    case ActionType.UpdateImage: {
      const knownAction = action as UpdateImageIFormAction;
      const { field, value: newValue, imageInfoIndex } = knownAction.payload;

      const nextState = JSON.parse(
        JSON.stringify(formState)
      ) as CreateAssetInput;

      const imageToUpdate = nextState.imageInfos[imageInfoIndex];
      nextState.imageInfos[imageInfoIndex] = newUpdatedObjectByField(
        imageToUpdate,
        field,
        newValue
      );
      return nextState;
    }
    case ActionType.UpdateStream: {
      const knownAction = action as UpdateStreamIFormAction;
      const { field, value: newValue, streamInfoIndex } = knownAction.payload;

      const nextState = JSON.parse(
        JSON.stringify(formState)
      ) as CreateAssetInput;

      const streamToUpdate = nextState.streamInfos[streamInfoIndex];
      nextState.streamInfos[streamInfoIndex] = newUpdatedObjectByField(
        streamToUpdate,
        field,
        newValue
      );
      return nextState;
    }
    case ActionType.AddStream: {
      const knownAction = action as AddStreamIFormAction;
      const { value: newStreamInfo } = knownAction.payload;
      const nextState = JSON.parse(
        JSON.stringify(formState)
      ) as CreateAssetInput;
      nextState.streamInfos.push(newStreamInfo);
      return nextState;
    }
    case ActionType.AddImage: {
      const knownAction = action as AddImageIFormAction;
      const { value: newImageInfo } = knownAction.payload;
      const nextState = JSON.parse(
        JSON.stringify(formState)
      ) as CreateAssetInput;
      nextState.imageInfos.push(newImageInfo);
      return nextState;
    }
    case ActionType.AddVideo: {
      const knownAction = action as AddVideoIFormAction;
      const { value: newVideoInfo, streamInfoIndex } = knownAction.payload;
      const nextState = JSON.parse(
        JSON.stringify(formState)
      ) as CreateAssetInput;
      nextState.streamInfos[streamInfoIndex].videoInfos.push(newVideoInfo);
      return nextState;
    }
    case ActionType.AddAudio: {
      const knownAction = action as AddAudioIFormAction;
      const { value: newAudioInfo, streamInfoIndex } = knownAction.payload;
      const nextState = JSON.parse(
        JSON.stringify(formState)
      ) as CreateAssetInput;
      nextState.streamInfos[streamInfoIndex].audioInfos.push(newAudioInfo);
      return nextState;
    }
    case ActionType.AddText: {
      const knownAction = action as AddTextIFormAction;
      const { value: newTextInfo, streamInfoIndex } = knownAction.payload;
      const nextState = JSON.parse(
        JSON.stringify(formState)
      ) as CreateAssetInput;
      nextState.streamInfos[streamInfoIndex].textInfos.push(newTextInfo);
      return nextState;
    }
    case ActionType.AddSource: {
      const knownAction = action as AddSourceIFormAction;
      const { value: newSourceInfo, streamInfoIndex } = knownAction.payload;
      const nextState = JSON.parse(
        JSON.stringify(formState)
      ) as CreateAssetInput;
      nextState.streamInfos[streamInfoIndex].sourceInfos.push(newSourceInfo);
      return nextState;
    }
    case ActionType.UpdateVideo: {
      const knownAction = action as UpdateVideoIFormAction;
      const {
        field,
        value: newValue,
        streamInfoIndex,
        videoInfoIndex,
      } = knownAction.payload;

      const nextState = JSON.parse(
        JSON.stringify(formState)
      ) as CreateAssetInput;

      const videoToUpdate =
        nextState.streamInfos[streamInfoIndex].videoInfos[videoInfoIndex];
      nextState.streamInfos[streamInfoIndex].videoInfos[videoInfoIndex] =
        newUpdatedObjectByField(videoToUpdate, field, newValue);
      return nextState;
    }
    case ActionType.UpdateAudio: {
      const knownAction = action as UpdateAudioIFormAction;
      const {
        field,
        value: newValue,
        streamInfoIndex,
        audioInfoIndex,
      } = knownAction.payload;

      const nextState = JSON.parse(
        JSON.stringify(formState)
      ) as CreateAssetInput;

      const audioToUpdate =
        nextState.streamInfos[streamInfoIndex].audioInfos[audioInfoIndex];
      nextState.streamInfos[streamInfoIndex].audioInfos[audioInfoIndex] =
        newUpdatedObjectByField(audioToUpdate, field, newValue);
      return nextState;
    }
    case ActionType.UpdateText: {
      const knownAction = action as UpdateTextIFormAction;
      const {
        field,
        value: newValue,
        streamInfoIndex,
        textInfoIndex,
      } = knownAction.payload;

      const nextState = JSON.parse(
        JSON.stringify(formState)
      ) as CreateAssetInput;

      const textToUpdate =
        nextState.streamInfos[streamInfoIndex].textInfos[textInfoIndex];
      nextState.streamInfos[streamInfoIndex].textInfos[textInfoIndex] =
        newUpdatedObjectByField(textToUpdate, field, newValue);
      return nextState;
    }
    case ActionType.UpdateSource: {
      const knownAction = action as UpdateSourceIFormAction;
      const {
        field,
        value: newValue,
        streamInfoIndex,
        sourceInfoIndex,
      } = knownAction.payload;

      const nextState = JSON.parse(
        JSON.stringify(formState)
      ) as CreateAssetInput;

      const sourceToUpdate =
        nextState.streamInfos[streamInfoIndex].sourceInfos[sourceInfoIndex];
      nextState.streamInfos[streamInfoIndex].sourceInfos[sourceInfoIndex] =
        newUpdatedObjectByField(sourceToUpdate, field, newValue);
      return nextState;
    }
    case ActionType.DeleteStream: {
      const knownAction = action as DeleteStreamIFromAction;
      const { streamInfoIndex } = knownAction.payload;

      const nextState = JSON.parse(
        JSON.stringify(formState)
      ) as CreateAssetInput;

      nextState.streamInfos.splice(streamInfoIndex, 1);
      return nextState;
    }
    case ActionType.DeleteImage: {
      const knownAction = action as DeleteImageIFromAction;
      const { imageInfoIndex } = knownAction.payload;

      const nextState = JSON.parse(
        JSON.stringify(formState)
      ) as CreateAssetInput;

      nextState.imageInfos.splice(imageInfoIndex, 1);
      return nextState;
    }
    case ActionType.DeleteVideo: {
      const knownAction = action as DeleteVideoIFromAction;
      const { streamInfoIndex, videoInfoIndex } = knownAction.payload;

      const nextState = JSON.parse(
        JSON.stringify(formState)
      ) as CreateAssetInput;

      nextState.streamInfos[streamInfoIndex].videoInfos.splice(
        videoInfoIndex,
        1
      );
      return nextState;
    }
    case ActionType.DeleteAudio: {
      const knownAction = action as DeleteAudioIFromAction;
      const { streamInfoIndex, audioInfoIndex } = knownAction.payload;

      const nextState = JSON.parse(
        JSON.stringify(formState)
      ) as CreateAssetInput;

      nextState.streamInfos[streamInfoIndex].audioInfos.splice(
        audioInfoIndex,
        1
      );
      return nextState;
    }
    case ActionType.DeleteText: {
      const knownAction = action as DeleteTextIFromAction;
      const { streamInfoIndex, textInfoIndex } = knownAction.payload;

      const nextState = JSON.parse(
        JSON.stringify(formState)
      ) as CreateAssetInput;

      nextState.streamInfos[streamInfoIndex].textInfos.splice(textInfoIndex, 1);
      return nextState;
    }
    case ActionType.DeleteSource: {
      const knownAction = action as DeleteSourceIFromAction;
      const { streamInfoIndex, sourceInfoIndex } = knownAction.payload;

      const nextState = JSON.parse(
        JSON.stringify(formState)
      ) as CreateAssetInput;

      nextState.streamInfos[streamInfoIndex].sourceInfos.splice(
        sourceInfoIndex,
        1
      );
      return nextState;
    }
    case ActionType.AddDeleteTag: {
      const knownAction = action as AddDeleteTagIFormAction;
      const { tagId: newTagId } = knownAction.payload;

      const nextState = JSON.parse(
        JSON.stringify(formState)
      ) as CreateAssetInput;

      const oldTagIds = nextState.tagIds || [];
      // attempt to remove tag if it exists
      const newTagIds = oldTagIds.filter((tagId) => tagId !== newTagId);
      if (newTagIds.length === oldTagIds.length) {
        // no tag was removed so add it instead
        newTagIds.push(newTagId);
      }
      nextState.tagIds = newTagIds;
      return nextState;
    }
  }
};

export {
  withImportFormContext,
  useImportFormContext,
  useImportFormDispatchContext,
};

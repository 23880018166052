interface UploadRequestInit extends RequestInit {
  useUpload: boolean;
  onProgress: (progressEvent: ProgressEvent) => void;
  body: File;
}

const parseHeaders = (rawHeaders: string): Headers => {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
  preProcessedHeaders.split(/\r?\n/).forEach((line: string) => {
    const parts = line.split(':');
    const key = parts.shift()?.trim();
    if (key) {
      const value = parts.join(':').trim();
      headers.append(key, value);
    }
  });
  return headers;
};

const uploadFetch = (
  url: string,
  options: UploadRequestInit
): Promise<Response> =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      const opts: ResponseInit = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders()),
      };
      resolve(new Response(xhr.response, opts));
    };

    xhr.onerror = () => {
      reject(new TypeError('Network request failed'));
    };

    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'));
    };

    xhr.open(options.method || 'POST', url, true);

    const headers = new Headers(options.headers);
    Array.from(headers.keys()).forEach((key) => {
      xhr.setRequestHeader(key, headers.get(key) || '');
    });

    if (xhr.upload) {
      xhr.upload.onprogress = options.onProgress;
    }

    xhr.send(options.body);
  });

export const customFetch = (
  uri: string,
  options: UploadRequestInit
): Promise<Response> => {
  if (options?.useUpload) {
    return uploadFetch(uri, options);
  }
  return fetch(uri, options);
};

// All valid video container formats also support audio streams.
export const VALID_VIDEO_EXTENSIONS = [
  '.mpegts',
  '.mpeg2ts',
  '.ts',
  '.mov',
  '.mp4',
  '.avi',
  '.fmp4',
  '.webm',
  '.mkv',
  '.3gp',
  '.mxf',
];
export const VALID_AUDIO_EXTENSIONS = [
  '.mp3',
  '.wav',
  '.aac',
  '.ogg',
  '.aiff',
  '.flac',
  '.alaw',
  '.audio', // allows .atmos.audio -> browsers only check value after last '.'
];
export const VALID_META_EXTENSIONS = ['.atmos', '.metadata'];

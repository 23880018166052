import { ErrorModalModel } from 'common/components/modals/error-modal/ErrorModal';
import { ErrorModalController } from 'common/components/modals/error-modal/ErrorModalController';
import { ModalDialog, ModalSizes } from 'common/components/modals/ModalDialog';
import { StreamInfoModalModel } from 'common/components/modals/stream-info-modal/StreamInfoModal';
import { StreamInfoModalController } from 'common/components/modals/stream-info-modal/StreamInfoModalController';
import UploadModal from 'common/components/modals/upload-modal/UploadModal';

export enum ModalTypes {
  NONE = 'NONE',
  EMPTY = 'EMPTY',
  ERROR = 'ERROR',
  STREAM_DETAILS = 'STREAM_DETAILS',
  CREATE_ASSET = 'CREATE_ASSET',
}

export type ModalPayload = { id: number; data: unknown };

const getModal = (
  type: ModalTypes,
  onClose: () => void,
  data?: ModalPayload,
  title?: string,
  size?: ModalSizes
): React.ReactNode => {
  let modal = (
    <ModalDialog
      title={'Unset Modal'}
      isOpen={true}
      onClose={onClose}
      size={size || ModalSizes.MD}
    />
  );

  switch (type) {
    case ModalTypes.STREAM_DETAILS: {
      const model: StreamInfoModalModel = data?.data as StreamInfoModalModel;
      modal = (
        <ModalDialog
          title={title || 'Stream Info'}
          isOpen={true}
          onClose={onClose}
          size={size || ModalSizes.MD}
        >
          <>
            <StreamInfoModalController data={model} onClose={onClose} />
          </>
        </ModalDialog>
      );
      break;
    }
    case ModalTypes.ERROR: {
      const model: ErrorModalModel = data?.data as ErrorModalModel;
      modal = (
        <ModalDialog
          title={title || 'Error'}
          isOpen={true}
          onClose={onClose}
          size={size || ModalSizes.MD}
        >
          <>
            <ErrorModalController data={model} onClose={onClose} />
          </>
        </ModalDialog>
      );
      break;
    }
    case ModalTypes.CREATE_ASSET: {
      modal = (
        <ModalDialog
          title={title || 'Create Media'}
          isOpen={true}
          onClose={onClose}
          size={size || ModalSizes.MD}
        >
          <UploadModal onClose={onClose} />
        </ModalDialog>
      );
      break;
    }
    case ModalTypes.EMPTY: {
      modal = (
        <ModalDialog
          title={title || 'Unimplemented'}
          isOpen={true}
          onClose={onClose}
          size={size || ModalSizes.SM}
        >
          <>
            <p className="wide">This has not been implemented yet.</p>
          </>
        </ModalDialog>
      );
      break;
    }
  }
  return modal;
};

export default getModal;

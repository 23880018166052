/* eslint-disable @typescript-eslint/no-empty-function */

// UPDATE Feature Log enablement HERE
// Please commit 'false' / OFF values unless testing in DEV/STAGE.
// FUTURE TODO:
//  1) Update CI/CD to inject Feature Logging values (ENV file perhaps?)
//  2) Make values runtime dynamic (logging can change without app restart)

const AuthLogging = true;
const UploadLogging = true;
const SelfService = true;
const AssetImport = true;

export class FeatureLogger {
  static Auth = (() => {
    if (!AuthLogging) {
      return () => {};
    }
    return console.debug.bind(
      console,
      `%cAuth |`,
      `background: #0000; color: #A955DA`
    );
  })();

  static Upload = (() => {
    if (!UploadLogging) {
      return () => {};
    }
    return console.debug.bind(
      console,
      `%cUpload |`,
      `background: #0000; color: #FCC603`
    );
  })();

  static SelfService = (() => {
    if (!SelfService) {
      return () => {};
    }
    return console.debug.bind(
      console,
      `%cSelfService |`,
      `background: #0000; color: #3452EB`
    );
  })();

  static AssetImport = (() => {
    if (!AssetImport) {
      return () => {};
    }
    return console.debug.bind(
      console,
      `%cAssetImport |`,
      `background: #0000; color: #2941BC`
    );
  })();
}

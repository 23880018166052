import React, {
  createContext,
  ReactElement,
  useContext,
  useState,
} from 'react';
import { getNotification, NotificationTypes } from './NotificationManager';

export interface NotificationContextValue {
  state: NotificationState;
  showNotification: (
    type: NotificationTypes,
    description: string,
    duration?: number
  ) => void;
  hideNotification: () => void;
}

export const NotificationContext = createContext<
  NotificationContextValue | undefined
>(undefined);

export interface NotificationState {
  currentNotification: React.ReactNode | null;
  type: NotificationTypes;
  description: string;
  duration?: number;
}

export interface NotificationStateProviderProps {
  children?: React.ReactNode;
}

export const NotificationProvider: React.FC<NotificationStateProviderProps> = ({
  children,
}: NotificationStateProviderProps) => {
  const defaultState: NotificationState = {
    currentNotification: null,
    type: NotificationTypes.NONE,
    description: '',
    duration: -1,
  };

  const [state, setState] = useState(defaultState);
  const hideNotification = (): void => setState(defaultState);

  const showNotification = (
    type: NotificationTypes,
    description: string,
    duration?: number
  ): void => {
    const currentNotification = getNotification(
      type,
      hideNotification,
      description,
      duration
    );
    setState({ type, description, duration, currentNotification });
  };

  const value: NotificationContextValue = {
    state: state,
    showNotification: showNotification,
    hideNotification: hideNotification,
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

export function useNotificationContext(): NotificationContextValue {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      'useNotificationContext must be used with a NotificationProvider'
    );
  }
  return context;
}

export const NotificationRoot = (): ReactElement => {
  const value = useNotificationContext();
  return (
    <>
      {value.state.currentNotification ? value.state.currentNotification : null}
    </>
  );
};

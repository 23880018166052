import React, {
  createContext,
  FunctionComponent,
  ReactElement,
  useContext,
  useState,
} from 'react';
import getModal, { ModalPayload, ModalTypes } from './ModalManager';

export interface ModalContextValue {
  state: ModalState;
  showModal: (type: ModalTypes, data?: ModalPayload, title?: string) => void;
  hideModal: () => void;
}

export const ModalContext = createContext<ModalContextValue | undefined>(
  undefined
);

export interface ModalState {
  currentModal: React.ReactNode | null;
  data: ModalPayload | undefined;
  type: ModalTypes;
}

export interface ModalStateProviderProps {
  children?: React.ReactNode;
}

export const ModalProvider: FunctionComponent<ModalStateProviderProps> = ({
  children,
}: ModalStateProviderProps) => {
  const defaultState: ModalState = {
    currentModal: null,
    type: ModalTypes.NONE,
    data: undefined,
  };

  const [state, setState] = useState(defaultState);

  function hideModal(): void {
    return setState(defaultState);
  }

  function showModal(
    type: ModalTypes,
    data?: ModalPayload,
    title?: string
  ): void {
    const currentModal = getModal(type, hideModal, data, title);
    setState({ type, data, currentModal });
  }

  const value: ModalContextValue = {
    state: state,
    showModal: showModal,
    hideModal: hideModal,
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

export function useModalContext(): ModalContextValue {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModalContext must be used with a ModalProvider');
  }
  return context;
}

export const ModalRoot = (): ReactElement => {
  const value = useModalContext();
  return <>{value.state.currentModal ? value.state.currentModal : null}</>;
};

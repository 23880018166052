import { ValidationResponse } from '../types';

const AtmosAudioFileValidator = (fileName: string): ValidationResponse => {
  const atmosExtensionRegExp = new RegExp(/\.atmos\.audio$/);

  if (!atmosExtensionRegExp.test(fileName)) {
    return {
      valid: false,
      error: new Error('filename does not have an atmos audio file extension'),
    };
  }
  return {
    valid: true,
    error: null,
  };
};

const AtmosSourceFileValidator = (fileName: string): ValidationResponse => {
  const atmosExtensionRegExp = new RegExp(/\.atmos$/);

  if (!atmosExtensionRegExp.test(fileName)) {
    return {
      valid: false,
      error: new Error('filename does not have an atmos audio file extension'),
    };
  }
  return {
    valid: true,
    error: null,
  };
};

const AtmosMetadataFileValidator = (fileName: string): ValidationResponse => {
  const atmosExtensionRegExp = new RegExp(/\.atmos\.metadata$/);

  if (!atmosExtensionRegExp.test(fileName)) {
    return {
      valid: false,
      error: new Error('filename does not have an atmos audio file extension'),
    };
  }
  return {
    valid: true,
    error: null,
  };
};

export {
  AtmosAudioFileValidator,
  AtmosSourceFileValidator,
  AtmosMetadataFileValidator,
};

import { Button, IconButton, Typography } from '@mui/material';
import { FileCopyOutlined, Visibility } from '@mui/icons-material';
import { createDescriptionText } from 'common/utils/asset.util';
import {
  formatBitrate,
  getResolutionName,
  sortByBitrate,
} from 'common/utils/resolution.util';
import { formatSecondsToHMSString } from 'common/utils/time.util';
import LabeledValue from '../../text/LabeledValue';
import { StreamInfoFieldsFragment } from 'api/graphql/generated';
import { styled } from '@mui/system';

export interface StreamInfoModalModel {
  assetId: number;
  assetTitle: string;
  assetImage: string;
  assetDuration: number;
  streamInfo?: StreamInfoFieldsFragment;
}

interface StreamInfoModalProps {
  data: StreamInfoModalModel;
  onDeleteClick: () => void;
  onCloseClick: () => void;
  className?: string;
}

const StreamInfoModal = ({
  data,
  onDeleteClick,
  onCloseClick,
  className,
}: StreamInfoModalProps) => {
  if (!data.streamInfo) {
    return <p>Couldn't find that stream info.</p>;
  } else {
    const description = createDescriptionText(data.streamInfo);
    const info = data.streamInfo;
    const sortedVideo = sortByBitrate([...data.streamInfo.videoInfos]);
    const topVideo = sortedVideo[0];
    const sortedAudio = sortByBitrate([...data.streamInfo.audioInfos]);
    const topAudio = sortedAudio[0];

    const onViewClick = (link: string): void => {
      const mvUrl =
        'https://mv.realeyes.cloud/?showVideo=1&muted=1&url=' +
        encodeURIComponent(link);
      window.open(mvUrl, '_blank');
    };

    const onCopyClick = (link: string): void => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(link);
      } else {
        // fallback for pre-2019 browser versions
        const listener = (e: ClipboardEvent) => {
          if (e && e.clipboardData) {
            e.clipboardData.setData('text/plain', link);
            e.preventDefault();
          }
          document.removeEventListener('copy', listener);
        };
        document.addEventListener('copy', listener);
        document.execCommand('copy');
      }
    };

    return (
      <div data-testid="stream-info-modal" className={className}>
        <div className="row">
          <Typography
            component="h6"
            variant="h6"
          >{`${data.assetTitle} - ${description}`}</Typography>
        </div>
        <div className="row">
          <div>
            <img src={data.assetImage} alt={data.assetTitle} />
          </div>
          <div className="majorityColumn">
            <LabeledValue label="Format:" value={info.format} />
            <LabeledValue label="Container:" value={info.container} />
            <LabeledValue
              label="Resolution:"
              value={getResolutionName(topVideo)}
            />
            <LabeledValue label="Protection:" value={info.drm} />
            <LabeledValue
              label="Duration:"
              value={`${data.assetDuration}s (${formatSecondsToHMSString(
                data.assetDuration
              )})`}
            />
            <LabeledValue
              label="Segment Length:"
              value={`${info.segmentDuration}s`}
            />
          </div>
          <div className="majorityColumn">
            <LabeledValue label="FPS:" value={topVideo?.fps} />
            <LabeledValue label="Video Codec:" value={topVideo?.codec} />
            <LabeledValue label="Audio Codec:" value={topAudio?.codec} />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <p>Sources:</p>
            <ul data-testid="sources-list">
              {info.sourceInfos.length < 1 ? (
                <span>No CDN sources</span>
              ) : (
                info.sourceInfos.map((source) => (
                  <li key={source.cdn}>
                    <a href={source.url} target="_blank" rel="noreferrer">
                      {source.cdn}
                    </a>
                    <IconButton
                      size="small"
                      onClick={() => onViewClick(source.url)}
                    >
                      <Visibility />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => onCopyClick(source.url)}
                    >
                      <FileCopyOutlined />
                    </IconButton>
                  </li>
                ))
              )}
            </ul>
          </div>
          <div className="column">
            <p>Renditions:</p>
            <ul data-testid="renditions-list">
              {sortedVideo.length < 1 ? (
                <span>No renditions</span>
              ) : (
                sortedVideo.map((video) => (
                  <li key={video.bitrate}>
                    {formatBitrate(video.bitrate)} - {video.width} x{' '}
                    {video.height}
                  </li>
                ))
              )}
            </ul>
          </div>
          <div className="column">
            <p>Audio:</p>
            <ul data-testid="audio-list">
              {sortedAudio.length < 1 ? (
                <span>No audio tracks</span>
              ) : (
                sortedAudio.map((audio) => (
                  <li key={audio.trackId}>
                    {audio.language} - {audio.codec},
                    {` ${audio.bitrate}kb/s` || 'No bitrate'}
                    {', '}
                    {audio.channels || 0} channels
                  </li>
                ))
              )}
            </ul>
          </div>
          <div className="column">
            <p>Text:</p>
            <ul data-testid="text-list">
              {info.textInfos.length < 1 ? (
                <span>No text tracks</span>
              ) : (
                info.textInfos.map((text) => (
                  <li key={text.trackId}>
                    {text.trackId || 'No ID'}: {text.textType || 'Unknown'} -{' '}
                    {text.language}
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>

        <div className="row">
          <Button title="Delete" variant="contained" onClick={onDeleteClick}>
            Delete
          </Button>
          <Button
            title="Close"
            variant="contained"
            className="closeButton"
            onClick={onCloseClick}
          >
            Close
          </Button>
        </div>
      </div>
    );
  }
};

const styledStreamInfoModal = styled(StreamInfoModal)({
  display: 'flex',
  flexDirection: 'column',
  img: {
    width: '300px',
    height: 'auto',
  },
  '.row': {
    display: 'flex',
    flexDirection: 'row',
  },
  '.column': {
    flexDirection: 'column',
    flexGrow: 1,
  },
  '.majorityColumn': {
    flex: 3,
    paddingLeft: '10px',
  },
  p: {
    display: 'flex',
    margin: 0,
    fontWeight: 'bold',
  },
  '.closeButton': {
    marginLeft: 'auto',
  },

  'li a': {
    marginRight: '3px',
  },

  'li button': {
    visibility: 'hidden',
    '.MuiSvgIcon-root': {
      width: '15px',
      height: '15px',
    },
  },
  'li:hover': {
    button: {
      visibility: 'visible',
      '.MuiSvgIcon-root': {
        visibility: 'visible',
      },
      '.MuiSvgIcon-root:hover': {
        color: '#3f51b5',
      },
    },
  },
});

export default styledStreamInfoModal;

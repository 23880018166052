import { useCallback } from 'react';
import { GradientCloudUpload } from '../assets/GradientCloudUploadIcon';
import { styled } from '@mui/system';
import { FileType } from 'api/graphql/generated';
import {
  useNotificationContext,
  NotificationTypes,
} from 'common/utils/managers/notifications';
import { FileRejection, useDropzone } from 'react-dropzone';
import { TitleCase } from '../helpers';
import { useFormContext } from '../context/FormContext';
import {
  VALID_AUDIO_EXTENSIONS,
  VALID_VIDEO_EXTENSIONS,
  VALID_META_EXTENSIONS,
} from '../validators/FileExtensionValid';
import { colors } from 'design';

type UploadFileBoxProps = {
  className?: string;
  fileType: FileType;
  linkToFileName?: string;
};

interface AcceptFiles {
  [key: string]: string[];
}

/**
 * VCMS Server File Requirements:
 * 1. Only one video can be processed at a time. This is designated via the `FileType` field, not the file extension.
 * 2. If there is a boosted config, the audio file needs to be a `wav`.
 * 3. File extensions must be a valid Hybrik container target.
 *    https://docs.hybrik.com/api/v1/HybrikAPI.html#targets
 * 4. Audio FileType objects must have audio stream compatible container format, same for video.
 *  -- REQUIREMENTS Drafted: 9/28/2022 --
 */

const UploadFileBox = ({
  className,
  fileType,
  linkToFileName,
}: UploadFileBoxProps) => {
  const notifications = useNotificationContext();
  const { addFile, audioFiles, videoFiles } = useFormContext();

  const acceptFiles = (): AcceptFiles => {
    if (fileType === FileType.Video) {
      return {
        'video/*': VALID_VIDEO_EXTENSIONS,
      };
    }
    if (fileType === FileType.Audio) {
      return {
        'audio/*': VALID_VIDEO_EXTENSIONS.concat(VALID_AUDIO_EXTENSIONS),
      };
    }
    // application/octet-stream is the MIME Type for unknown file types
    return {
      'application/octet-stream': VALID_META_EXTENSIONS,
    };
  };

  const onDrop = useCallback(
    (files: File[], fileRejections: FileRejection[]) => {
      if (fileRejections?.length > 0) {
        fileRejections.forEach((fileRejection) => {
          notifications.showNotification(
            NotificationTypes.ERROR,
            `Error adding file: '${fileRejection.file.name}' has an invalid extension for fileType: '${fileType}'`
          );
        });
        return;
      }
      if (
        (fileType === FileType.Video && files.length > 1) ||
        (fileType === FileType.Video && videoFiles.size > 0)
      ) {
        notifications.showNotification(
          NotificationTypes.ERROR,
          `Error adding file: Only 1 video file may be used per upload`
        );
        return;
      }

      if (
        (fileType === FileType.Audio && files.length > 1) ||
        (fileType === FileType.Audio && audioFiles.size > 0)
      ) {
        notifications.showNotification(
          NotificationTypes.ERROR,
          `Error adding file: Only 1 audio file may be used per upload`
        );
        return;
      }

      files.forEach((file) => {
        addFile(file, fileType, linkToFileName);
      });
    },
    [
      fileType,
      videoFiles.size,
      addFile,
      notifications,
      linkToFileName,
      audioFiles.size,
    ]
  );

  let disabled = false;
  if (fileType === FileType.Audio && audioFiles.size >= 1) {
    disabled = true;
  }
  if (fileType === FileType.Video && videoFiles.size >= 1) {
    disabled = true;
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptFiles(),
    disabled,
  });

  return (
    <div
      className={`${className} ${fileType} ${disabled ? 'disabled' : ''} ${
        isDragActive ? 'drop' : ''
      }`}
      {...getRootProps()}
    >
      <p>{TitleCase(fileType)} File</p>

      <GradientCloudUpload className="uploadIcon" />
      <input
        {...getInputProps()}
        data-testid={`icon-${fileType}-file`}
        type="file"
      />
    </div>
  );
};

const styledUploadFileBox = styled(UploadFileBox)({
  display: 'flex',
  flexDirection: 'column',
  border: '3px solid black',
  borderRadius: '5px',
  minWidth: '150px',
  maxWidth: '200px',
  aspectRatio: '1.5',
  cursor: 'pointer',
  textAlign: 'center',
  backgroundColor: 'white',

  '&.disabled': {
    opacity: '50%',
    background: 'black',
    color: 'white',
  },

  '&:not(.disabled).VIDEO': {
    '&:hover, &.drop': {
      boxShadow: `0px 0px 10px ${colors.primaryBlue}`,
      borderColor: `${colors.primaryBlue}`,
    },
  },

  '&:not(.disabled).AUDIO': {
    '&:hover, &.drop': {
      boxShadow: '0px 0px 10px #DB00FF',
      borderColor: '#DB00FF',
    },
  },

  '&:not(.disabled).META': {
    '&:hover, &.drop': {
      boxShadow: `0px 0px 10px ${colors.tertiary}`,
      borderColor: `${colors.tertiary}`,
    },
  },

  p: {
    fontSize: '150%',
    fontWeight: 'bold',
    margin: '5px 0',
    color: 'black',
  },

  '.uploadIcon': {
    flexGrow: 1,
    width: '100%',
  },
});

export default styledUploadFileBox;

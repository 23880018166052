import { Button } from '@mui/material';
import { styled } from '@mui/system';

export interface ErrorModalModel {
  assetId: number;
  streamId: string | undefined;
}

export interface ErrorModalStreamDataModel {
  description: string;
  taskId: number;
  error: string | null;
}

export interface ErrorModalDataModel {
  assetTitle: string;
  assetDescription: string;
  streamData: ErrorModalStreamDataModel[];
}

export interface ErrorModalProps {
  data: ErrorModalDataModel;
  onRetryClick: () => void;
  onCancelClick: (item: ErrorModalStreamDataModel) => void;
  onCloseClick: () => void;
  className?: string;
}

const ErrorModal = ({
  data,
  onRetryClick,
  onCancelClick,
  onCloseClick,
  className,
}: ErrorModalProps) => {
  if (!data) {
    return <p>Couldn't find that error.</p>;
  } else {
    return (
      <div data-testid="error-modal" className={className}>
        <div className="assetInfo">
          <b>{data.assetTitle}</b> - {data.assetDescription}
        </div>
        {data?.streamData.map((item) => {
          return (
            <div key={`${item.taskId}`}>
              <div className="row">
                <div data-testid="error-text" className="errorText">
                  <b>{`${item.taskId}`}</b> - {`${item.description}`} <br />
                  {`${item.error}`}
                </div>
              </div>

              <div className="buttonRow">
                <Button
                  title="Retry"
                  variant="contained"
                  onClick={onRetryClick}
                >
                  Retry
                </Button>
                <span className="spacer" />
                <Button
                  title="Cancel"
                  variant="contained"
                  onClick={() => onCancelClick(item)}
                >
                  Cancel Stream
                </Button>
                <Button
                  title="Close"
                  variant="contained"
                  className="closeButton"
                  onClick={onCloseClick}
                >
                  Close
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
};

const styledErrorModal = styled(ErrorModal)({
  display: 'flex',
  flexDirection: 'column',
  '.row, .buttonRow': {
    borderTop: '1px solid lightslategrey',
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0',
    margin: '4px 0',
    border: 'none',
  },
  p: {
    display: 'flex',
    margin: '0',
    fontWeight: 'bold',
  },
  '.closeButton': {
    marginLeft: 'auto',
  },
  '.errorText': {
    fontSize: '1em',
    width: '100%',
  },
  '.spacer': {
    width: '15px',
  },
  '.assetInfo': {
    fontSize: '1.3em',
  },
});

export default styledErrorModal;

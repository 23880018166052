import { useState, Dispatch, SetStateAction, useCallback } from 'react';

// Function Overloading Signatures
function useSessionStorage<S>(
  key: string,
  defaultValue: S | (() => S)
): [S, Dispatch<SetStateAction<S>>];

function useSessionStorage<S = undefined>(
  key: string
): [S | undefined, Dispatch<SetStateAction<S | undefined>>];

// Function Implementation Signature
function useSessionStorage(key: string, defaultValue?: unknown): unknown {
  const sessionValue = useCallback((): unknown => {
    if (typeof window === 'undefined') return defaultValue;

    try {
      const item = window.sessionStorage.getItem(key);
      const res: unknown = item ? JSON.parse(item) : defaultValue;
      return res;
    } catch (e) {
      console.warn(`Parsing key: ${key} from sessionStorage`);
      return defaultValue;
    }
  }, [defaultValue, key]);

  const [stateValue, setStateValue] = useState(sessionValue);

  const setSessionValue = useCallback(
    (newValue: unknown) => {
      if (typeof window === 'undefined') {
        return;
      }
      try {
        const clearSessionStorage =
          typeof newValue === 'undefined' || newValue === '';
        if (clearSessionStorage) {
          window.sessionStorage.removeItem(key);
        } else {
          window.sessionStorage.setItem(key, JSON.stringify(newValue));
        }
      } catch {
        console.warn(`Adding key: ${key} to sessionStorage`);
      }
      setStateValue(newValue);
    },
    [key]
  );

  return [stateValue, setSessionValue];
}

export { useSessionStorage };

import { CloudUpload } from '@mui/icons-material';

type props = {
  className?: string;
};

const GradientCloudUpload = ({ className }: props) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={0} x2={1} y2={1}>
        <stop offset={0} stopColor="#DB00FF" />
        <stop offset={1} stopColor="#0005FE" />
      </linearGradient>
    </svg>
    <CloudUpload sx={{ fill: 'url(#linearColors)' }} className={className} />
  </>
);

export { GradientCloudUpload };

import React, { useMemo } from 'react';
import { styled } from '@mui/system';
import { useFileContext } from '../context/FileContext';
import OptionItem from './OptionItem';
import { TextField } from '@mui/material';
import { AtmosAudioFileValidator } from '../validators/IsAtmosFile';
import UploadFileBox from './UploadFileBox';
import { FileType } from 'api/graphql/generated';

export type BaseFileTabProps = {
  className?: string;
};

type FileTabProps = {
  className?: string;
};

const FileTab = ({ className }: FileTabProps) => {
  const { fileType, fileName, preuploaded, fileUrl, setFileUrl } =
    useFileContext();

  const atmosFile = useMemo(() => {
    if (!AtmosAudioFileValidator(fileName).valid) {
      return false;
    }
    return true;
  }, [fileName]);

  return (
    <div className={className}>
      <p>FileType: {fileType}</p>
      <p>FileName: {fileName}</p>
      {atmosFile && (
        <>
          <p>Atmos File has been detected. Please upload both:</p>
          <p>
            <b>Atmos Source file </b> (.atmos)
            <br />
            <b>Atmos Metadata file </b> (.atmos.metadata)
          </p>
          <UploadFileBox fileType={FileType.Meta} linkToFileName={fileName} />
        </>
      )}
      {preuploaded && (
        <OptionItem>
          <TextField
            variant="outlined"
            style={{ width: '100%' }}
            multiline
            defaultValue={
              fileUrl
                ? fileUrl
                : 'https://<s3_bucket>.s3.<aws_region>.amazonaws.com/<path_key>/<file_name>'
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setFileUrl(event.target.value)
            }
          />
        </OptionItem>
      )}
    </div>
  );
};

const styledFileTab = styled(FileTab)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  columnGap: '10px',
});

export default styledFileTab;

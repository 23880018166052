import UploadingDisplay from 'common/components/status-displays/UploadingDisplay';
import {
  useNotificationContext,
  NotificationTypes,
} from 'common/utils/managers/notifications';
import { useState, useRef } from 'react';
import { ApolloError } from '@apollo/client';
import { customFetch } from 'api/utils/customFetchUtil';
import {
  useGetUploadUrlsLazyQuery,
  GetUploadUrlsQuery,
  UploadPayload,
  FileType,
  useTagsQuery,
  TagsQueryVariables,
  CreateAssetMutationVariables,
  CreateAssetInput,
  useCreateAssetMutation,
  Status,
  CreateAssetMutation,
} from 'api/graphql/generated';
import { getUploadConfig, getVcmsHostname } from 'common/models/environment';
import { useAuthContext } from 'common/contexts/AuthContext';
import { vcmsAuthFetch } from 'api/utils/vcmsAuthFetch';
import { FeatureLogger } from 'common/logging';
import {
  Button,
  Chip,
  FormControl,
  Input,
  InputLabel,
  Typography,
  styled,
} from '@mui/material';
import UploadFileBox from './components/UploadFileBox';
import { AwsCreds, Progress, UploadModalState } from './types';
import {
  PutObjectCommand,
  PutObjectCommandInput,
  S3Client,
  S3ClientConfig,
} from '@aws-sdk/client-s3';
import { Options, Upload } from '@aws-sdk/lib-storage';
import OptionsMenu from './components/OptionsMenu';
import { useFormContext, withFormContext } from './context/FormContext';
import { Delete, LibraryAdd } from '@mui/icons-material';
import ImportAssetForm from './components/ImportAssetForm';
import ErroredDisplay from 'common/components/status-displays/ErroredDisplay';
import LoadingDisplay from 'common/components/status-displays/LoadingDisplay';
import { colors } from 'design';

type UploadModalProps = {
  className?: string;
  onClose: () => void;
};

const KB_IN_BYTES = 1024;
const MB_IN_BYTES = 1024 * KB_IN_BYTES;
const GB_IN_BYTES = 1024 * MB_IN_BYTES;
const TB_IN_BYTES = 1024 * GB_IN_BYTES;

const UploadModal = ({ className, onClose }: UploadModalProps) => {
  const notifications = useNotificationContext();
  const { vcmsAccessToken, groupId } = useAuthContext();
  const {
    videoFiles,
    audioFiles,
    metaFiles,
    removeFile,
    setSelectedFileId,
    uploadFiles,
    packagePayload,
    validSelections,
  } = useFormContext();

  const [assetId, setAssetId] = useState<number | null>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedTags, setSelectedTags] = useState<number[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [percentUploaded, setPercentUploaded] = useState(0);
  const processingFilesRef = useRef(new Map<string, File>());
  const [uploadModalState, setUploadModalState] = useState<UploadModalState>(
    UploadModalState.DetectFlow
  );

  const tagsQueryVariables: TagsQueryVariables = {
    tagsInput: {
      groupId,
    },
  };

  const queryError = (error: ApolloError) => {
    notifications.showNotification(
      NotificationTypes.ERROR,
      `Failed to load tags: ${error}`,
      3000
    );
  };

  const createAssetError = (error: ApolloError) => {
    setSubmitting(false);
    notifications.showNotification(
      NotificationTypes.ERROR,
      `Create Asset Mutation failed: ${error}`,
      3000
    );
  };

  const createAssetComplete = (data: CreateAssetMutation) => {
    setSubmitting(false);
    setAssetId(data.createAsset);
    setUploadModalState(UploadModalState.AttachingFiles);
    notifications.showNotification(
      NotificationTypes.SUCCESS,
      'Asset successfully imported.',
      3000
    );
  };

  const [createAsset] = useCreateAssetMutation({
    onError: createAssetError,
    onCompleted: createAssetComplete,
  });

  const {
    loading: tagsQueryLoading,
    error: tagsQueryError,
    data: tagsData,
  } = useTagsQuery({
    variables: tagsQueryVariables,
    onError: queryError,
  });

  const calculateTotalProgress = (
    { loaded, total }: Progress,
    index: number,
    allProgress: { [key: number]: number }
  ) => {
    allProgress[index] = loaded / total;
    const values = Object.values(allProgress);
    const totalRequests = values.length;
    const newProgress =
      (values.reduce((sum, val) => val + sum, 0) / totalRequests) * 100;
    if (percentUploaded >= newProgress) {
      // sometimes callbacks will be received out of order
      return;
    }
    setPercentUploaded(Math.floor(newProgress));
  };

  const presignedUrlUpload = async ({
    getUploadUrls,
  }: GetUploadUrlsQuery): Promise<void> => {
    FeatureLogger.Upload(
      'presigned url upload: received urls -- uploading to s3'
    );

    const allProgress = Array.from(getUploadUrls.keys()).reduce(
      (accum, _, idx) => ({ ...accum, [idx]: 0 }),
      {}
    );
    try {
      const { current: processingFiles } = processingFilesRef;
      await Promise.all(
        getUploadUrls.map(({ uploadUrl, fileName }, index) => {
          const fileToUpload = processingFiles.get(fileName);
          if (fileToUpload === undefined) {
            return Promise.resolve();
          }
          FeatureLogger.Upload(`presigned url upload: '${fileName}' ...`);
          return customFetch(uploadUrl, {
            method: 'PUT',
            body: fileToUpload,
            headers: {
              'Content-Type': fileToUpload.type,
            },
            useUpload: true,
            onProgress: (ev: ProgressEvent) =>
              calculateTotalProgress(ev, index, allProgress),
          });
        })
      );
      FeatureLogger.Upload(`presigned url upload: finished`);

      notifications.showNotification(
        NotificationTypes.SUCCESS,
        'Asset successfully uploaded.',
        3000
      );
    } catch (error) {
      FeatureLogger.Upload(
        'presigned url upload failure: aborting with message: ',
        error
      );
      notifications.showNotification(
        NotificationTypes.ERROR,
        'Asset failed to upload.',
        3000
      );
    } finally {
      onClose();
    }
  };

  const stsUpload = async (
    uploadPayload: UploadPayload,
    awsCreds: AwsCreds
  ): Promise<void> => {
    const { sourceBucketName } = uploadPayload;
    FeatureLogger.Upload('sts upload: uploading files to s3 ...');
    try {
      const config: S3ClientConfig = {
        credentials: {
          accessKeyId: awsCreds.accessKeyId,
          secretAccessKey: awsCreds.secretAccessKey,
          sessionToken: awsCreds.sessionToken,
        },
        region: 'us-west-2',
      };
      const s3Client = new S3Client(config);

      const { current: processingFiles } = processingFilesRef;
      const allProgress = Array.from(processingFiles.keys()).reduce(
        (accum, _, idx) => ({ ...accum, [idx]: 0 }),
        {}
      );
      /**
       * S3 multipart upload limits
       * Maximum object size 5 TB
       * Maximum number of parts per upload	10,000
       * Part numbers	1 to 10,000 (inclusive)
       * Part size	5 MB to 5 GB. There is no minimum size limit on the last part of your multipart upload.
       */
      await Promise.all(
        Array.from(processingFiles.entries()).map(
          async ([fileName, file], idx) => {
            let upload = undefined;
            try {
              FeatureLogger.Upload(`sts upload: uploading: '${fileName}' ...`);
              if (file.size <= MB_IN_BYTES * 20) {
                FeatureLogger.Upload(
                  'sts upload: sub 20MB file detected - uploading direct without multipart'
                );
                const input: PutObjectCommandInput = {
                  Body: file,
                  Bucket: sourceBucketName as string,
                  Key: fileName,
                };
                const command = new PutObjectCommand(input);
                const uploadResult = await s3Client.send(command);
                calculateTotalProgress(
                  { loaded: 1, total: 1 },
                  idx,
                  allProgress
                );
                FeatureLogger.Upload(
                  `sts upload: '${fileName}' finished `,
                  uploadResult
                );
                return;
              }

              // Target is 80MB of browser memory, 4 queueSize * 20MB
              const targetBytePartSize = MB_IN_BYTES * 20;
              const targetQueueSize = 4;
              let partSize = targetBytePartSize;
              let queueSize = targetQueueSize;

              if (file.size >= GB_IN_BYTES * 200) {
                FeatureLogger.Upload(
                  'sts upload: File over 200GB - adjusting partSize to 100MB to prevent going over max part total limit'
                );
                queueSize = 2; // 200MB max browser memory - large file uploads will need some hardware memory requirements
                partSize = MB_IN_BYTES * 100;
              } else if (file.size <= MB_IN_BYTES * 100) {
                FeatureLogger.Upload(
                  'sts upload: File under 100MB - adjusting partSize to 10MB to prevent parts from being under size limit'
                );
                queueSize = 6; // 60MB max browser memory, if file size is less than 60MB then queue size will decrease automatically
                partSize = MB_IN_BYTES * 10;
              }

              const uploadOptions: Options = {
                client: s3Client,
                params: {
                  Bucket: sourceBucketName as string,
                  Key: fileName,
                  Body: file,
                  ContentType: file.type,
                },
                queueSize,
                partSize,
              };
              FeatureLogger.Upload('sts upload: options ', {
                uploadOptions,
                client: undefined,
              });
              upload = new Upload(uploadOptions);

              upload.on('httpUploadProgress', (progress) => {
                FeatureLogger.Upload(
                  `sts upload: ${fileName}, progress: `,
                  progress
                );
                const { loaded, total } = progress;
                if (loaded && total) {
                  calculateTotalProgress({ loaded, total }, idx, allProgress);
                }
              });

              const uploadResult = await upload.done();
              FeatureLogger.Upload(
                `sts upload: '${fileName}' finished `,
                uploadResult
              );
            } catch (error) {
              FeatureLogger.Upload(
                'sts upload failure: aborting with message: ',
                error
              );
              await upload?.abort();
              return Promise.reject(error);
            }
          }
        )
      );
      FeatureLogger.Upload('sts upload finished');
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    if (!assetId) {
      notifications.showNotification(
        NotificationTypes.ERROR,
        'Asset must be created prior to submitting',
        3000
      );
      return;
    }
    const filesToUpload = Array.from([
      ...videoFiles.values(),
      ...audioFiles.values(),
      ...metaFiles.values(),
    ]);
    const fileTooLarge = filesToUpload.some((file) => file.size >= TB_IN_BYTES);
    if (fileTooLarge) {
      notifications.showNotification(
        NotificationTypes.ERROR,
        'Uploaded Files can not exceed 1 TB (AWS multipart restricts 5TB, MSS restricts 1TB)',
        3000
      );
      return;
    }
    // using a combination of asset id and a timestamp to guarantee uniqueness in the asset path
    // naming convention
    const assetPath = `/${assetId}_${Date.now()}`;

    const {
      destinationBucketName,
      destinationPathPrefix,
      sourceBucketName,
      sourcePathPrefix,
    } = getUploadConfig();

    // map presigned url response with requested file to be uploaded
    filesToUpload.forEach((file) => {
      const getUrlResponseKey =
        destinationPathPrefix + assetPath + '/' + file.name;
      processingFilesRef.current.set(getUrlResponseKey, file);
    });

    if (uploadFiles.size === 0) {
      FeatureLogger.Upload('no files uploaded -- skipping');
      return;
    }

    setPercentUploaded(0);
    setUploadModalState(UploadModalState.UploadingFiles);

    const uploadPayload: UploadPayload = {
      uploadFiles: [...uploadFiles.values()],
      destinationBucketName,
      destinationPath: destinationPathPrefix + assetPath,
      sourceBucketName,
      sourcePath: sourcePathPrefix + assetPath,
      assetId,
      packagePayload,
    };

    const useStsUpload = filesToUpload.some(
      (file) => file.size >= MB_IN_BYTES * 500
    );
    if (useStsUpload) {
      FeatureLogger.Upload('large file(s) detected -- use STS upload');
      const url = `${getVcmsHostname()}/sts-creds`;
      const fetchOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify(uploadPayload),
      };

      try {
        const awsCreds = await vcmsAuthFetch<AwsCreds>(
          url,
          vcmsAccessToken,
          fetchOptions
        );
        await stsUpload(uploadPayload, awsCreds);
        notifications.showNotification(
          NotificationTypes.SUCCESS,
          'Assets successfully uploaded.',
          3000
        );
      } catch (error) {
        notifications.showNotification(
          NotificationTypes.ERROR,
          'Asset(s) failed to upload.',
          3000
        );
      } finally {
        setUploadModalState(UploadModalState.AttachingFiles);
        onClose();
        return;
      }
    }

    FeatureLogger.Upload('presigned url upload: asking for urls');
    getUploadUrls({
      variables: {
        payload: uploadPayload,
      },
    });
  };

  const getUploadUrlsError = (e: ApolloError): void => {
    notifications.showNotification(
      NotificationTypes.ERROR,
      'Error fetching upload url(s) for asset(s)',
      3000
    );
    setUploadModalState(UploadModalState.AttachingFiles);
    onClose();
  };

  const [getUploadUrls] = useGetUploadUrlsLazyQuery({
    onError: getUploadUrlsError,
    onCompleted: presignedUrlUpload,
  });

  if (tagsQueryError !== undefined)
    return <ErroredDisplay error={tagsQueryError} />;
  if (tagsQueryLoading || tagsData === undefined) return <LoadingDisplay />;
  const { tags } = tagsData;

  return (
    <>
      {uploadModalState === UploadModalState.DetectFlow && (
        <div className={`${className} ${UploadModalState.DetectFlow}`}>
          <div className="header">
            <span>Select A New Asset Create Flow</span>
            <LibraryAdd />
          </div>
          <div className="flowOptions">
            <Button
              className={className}
              variant="outlined"
              onClick={() => {
                setUploadModalState(UploadModalState.CreateAsset);
              }}
              data-testid={`flow_${UploadModalState.CreateAsset}`}
            >
              I need to Upload Source File(s)
            </Button>
            <Button disabled className={className} variant="outlined">
              My source File(s) have already been uploaded
            </Button>
            <Button
              className={className}
              variant="outlined"
              onClick={() => {
                setUploadModalState(UploadModalState.ImportingAsset);
              }}
              data-testid={`flow_${UploadModalState.ImportingAsset}`}
            >
              Let me import an asset.
              <Typography style={{ fontSize: '8px' }} component="span">
                (no transcoding or packaging required)
              </Typography>
            </Button>
          </div>
        </div>
      )}
      {uploadModalState === UploadModalState.CreateAsset && (
        <div className={`${className} ${UploadModalState.CreateAsset}`}>
          <Typography component="span">New Asset Details:</Typography>
          <FormControl variant="standard">
            <InputLabel htmlFor="asset-title">Title</InputLabel>
            <Input
              id="asset-title"
              value={title}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setTitle(event.target.value)
              }
            />
          </FormControl>
          <FormControl variant="standard">
            <InputLabel htmlFor="asset-description">Description</InputLabel>
            <Input
              id="asset-description"
              value={description}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setDescription(event.target.value)
              }
            />
          </FormControl>
          <div className="tag-container">
            <Typography style={{ alignSelf: 'center' }} component="span">
              Tags:
            </Typography>
            {tags.map(({ name, id }) => (
              <Chip
                key={id}
                label={name}
                color="secondary"
                variant={selectedTags.includes(id) ? 'filled' : 'outlined'}
                onClick={() => {
                  selectedTags.includes(id)
                    ? setSelectedTags(
                        selectedTags.filter((tagId) => tagId !== id)
                      )
                    : setSelectedTags([...selectedTags, id]);
                }}
              />
            ))}
          </div>
          <Button
            variant="contained"
            disabled={submitting}
            data-testid={`flow_${UploadModalState.AttachingFiles}`}
            onClick={() => {
              setSubmitting(true);
              const createAssetVariables: CreateAssetMutationVariables = {
                createAssetInput: {
                  title,
                  description,
                  tagIds: selectedTags,
                  duration: 0,
                  groupId,
                  status: Status.Undefined,
                  progress: 0,
                  streamInfos: [],
                  imageInfos: [],
                } as CreateAssetInput,
              };
              createAsset({
                variables: createAssetVariables,
              });
            }}
          >
            Next Step: File Upload
          </Button>
        </div>
      )}
      {uploadModalState === UploadModalState.AttachingFiles && (
        <div className={`${className} ${UploadModalState.AttachingFiles}`}>
          <div className="files">
            {[FileType.Video, FileType.Audio].map((fileType) => (
              <UploadFileBox fileType={fileType} key={fileType} />
            ))}
            {Array.from(videoFiles, ([videoFileId, videoFile]) => (
              <Button
                className="attachedVideo"
                key={videoFileId}
                variant="contained"
                endIcon={
                  <Delete
                    onClick={(e) => {
                      removeFile(videoFileId, FileType.Video);
                      e.stopPropagation();
                    }}
                  />
                }
                onClick={() => {
                  setSelectedFileId(videoFileId);
                }}
              >
                {videoFile.name}
              </Button>
            ))}
            {Array.from(audioFiles, ([audioFileId, audioFile]) => (
              <Button
                variant="contained"
                className="attachedAudio"
                key={audioFileId}
                endIcon={
                  <Delete
                    onClick={(e) => {
                      removeFile(audioFileId, FileType.Audio);
                      e.stopPropagation();
                    }}
                  />
                }
                onClick={() => {
                  setSelectedFileId(audioFileId);
                }}
              >
                {audioFile.name}
              </Button>
            ))}
            {Array.from(metaFiles, ([metaFileId, metaFile]) => (
              <Button
                variant="contained"
                className="attachedMeta"
                key={metaFileId}
                endIcon={
                  <Delete
                    onClick={(e) => {
                      removeFile(metaFileId, FileType.Meta);
                      e.stopPropagation();
                    }}
                  />
                }
                onClick={() => {
                  setSelectedFileId(metaFileId);
                }}
              >
                {metaFile.name}
              </Button>
            ))}
          </div>
          <OptionsMenu className="body" />
          {validSelections !== undefined && (
            <Typography component="span" color="error">
              {validSelections.message}
            </Typography>
          )}
          <Button
            variant="contained"
            disabled={validSelections !== undefined}
            className="submit"
            onClick={handleSubmit}
          >
            Submit Job
          </Button>
        </div>
      )}
      {uploadModalState === UploadModalState.ImportingAsset && (
        <ImportAssetForm onClose={onClose} />
      )}
      {uploadModalState === UploadModalState.UploadingFiles && (
        <UploadingDisplay value={percentUploaded} />
      )}
    </>
  );
};

const styledUploadModal = styled(UploadModal)(({ theme }) => ({
  backgroundColor: 'white',

  '&.DETECT_FLOW': {
    display: 'flex',
    width: 'fit-content',
    flexDirection: 'column',
    gap: '10px',

    '.header': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    '.flowOptions': {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',

      '& > *': {
        display: 'flex',
        flexDirection: 'column',
        width: '150px',
        height: '150px',
      },
    },
  },

  '&.CREATE_ASSET': {
    display: 'flex',
    flexDirection: 'column',

    '.tag-container': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: '5px',
      margin: '10px 0',
    },
  },

  '&.ATTACHING_FILES': {
    display: 'grid',
    gridTemplateAreas: `"files body"
    "files error"
    "files submit"`,
    gridTemplateColumns: '1fr 3fr',
    gridTemplateRows: '1fr auto',
    columnGap: '10%',
    textOverflow: 'ellipsis',

    '.files': {
      gridArea: 'files',
      display: 'flex',
      flexDirection: 'column',
      height: '500px',
      gap: '10px',
      marginTop: '30px',

      '.attachedAudio, .attachedVideo, .attachedMeta': {
        backgroundColor: '#0005FE',
        padding: '5px 10px',
        textOverflow: 'ellipsis',
        borderRadius: '5px',
        color: 'white',
        overflowWrap: 'anywhere',

        '&.attachedAudio': {
          backgroundColor: '#DB00FF',
        },

        '&.attachedMeta': {
          backgroundColor: `${colors.tertiary}`,
        },

        '&.MuiButton-root': {
          justifyContent: 'space-between',

          '.MuiButton-endIcon': {
            height: '100%',
            alignItems: 'center',
            borderRadius: '3px',

            svg: {
              height: '100%',
            },

            ':hover': {
              backgroundColor: '#13141C',
            },
          },
        },
      },
    },

    '.body': {
      gridArea: 'body',
      justifyContent: 'space-between',
    },
  },
}));

export default withFormContext(styledUploadModal);

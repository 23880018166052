import {
  AudioInfoInput,
  CreateAssetInput,
  ImageInfoInput,
  SourceInfoInput,
  StreamInfoInput,
  TextInfoInput,
  AudioCodec,
  AudioType,
  ChannelType,
  VideoInfoInput,
} from 'api/graphql/generated';

export enum UploadModalState {
  DetectFlow = 'DETECT_FLOW',
  CreateAsset = 'CREATE_ASSET',
  AttachingFiles = 'ATTACHING_FILES',
  ImportingAsset = 'IMPORTING_ASSET',
  UploadingFiles = 'UPLOADING_FILES',
}

export enum Tabs {
  File = 'FILE',
  Video = 'VIDEO',
  Audio = 'Audio',
  Package = 'Package',
}

export type Progress = {
  loaded: number;
  total: number;
};

export type AudioConfigForm = {
  channelType: ChannelType | null;
  audioType: AudioType | null;
  language: string;
  label: string;
  audioCodec: AudioCodec | null;
  bitratesKb: number[];
};

export type AwsCreds = {
  sessionToken: string;
  accessKeyId: string;
  secretAccessKey: string;
};

export enum ActionType {
  AddStream,
  AddImage,
  AddVideo,
  AddAudio,
  AddText,
  AddSource,

  UpdateAsset,
  UpdateImage,
  UpdateStream,
  UpdateVideo,
  UpdateAudio,
  UpdateText,
  UpdateSource,

  DeleteStream,
  DeleteImage,
  DeleteVideo,
  DeleteAudio,
  DeleteText,
  DeleteSource,

  AddDeleteTag,
}
export type AssetImportAction =
  | UpdateAssetIFormAction
  | UpdateStreamIFormAction
  | UpdateImageIFormAction
  | UpdateVideoIFormAction
  | UpdateAudioIFormAction
  | UpdateTextIFormAction
  | UpdateSourceIFormAction
  | AddStreamIFormAction
  | AddImageIFormAction
  | AddVideoIFormAction
  | AddAudioIFormAction
  | AddTextIFormAction
  | AddSourceIFormAction
  | DeleteStreamIFromAction
  | DeleteImageIFromAction
  | DeleteVideoIFromAction
  | DeleteAudioIFromAction
  | DeleteTextIFromAction
  | DeleteSourceIFromAction
  | AddDeleteTagIFormAction;

export type AddStreamIFormAction = {
  type: ActionType.AddStream;
  payload: {
    value: StreamInfoInput;
  };
};
export type AddImageIFormAction = {
  type: ActionType.AddImage;
  payload: {
    value: ImageInfoInput;
  };
};
export type AddVideoIFormAction = {
  type: ActionType.AddVideo;
  payload: {
    value: VideoInfoInput;
    streamInfoIndex: number;
  };
};
export type AddAudioIFormAction = {
  type: ActionType.AddAudio;
  payload: {
    value: AudioInfoInput;
    streamInfoIndex: number;
  };
};
export type AddTextIFormAction = {
  type: ActionType.AddText;
  payload: {
    value: TextInfoInput;
    streamInfoIndex: number;
  };
};
export type AddSourceIFormAction = {
  type: ActionType.AddSource;
  payload: {
    value: SourceInfoInput;
    streamInfoIndex: number;
  };
};

export type UpdateAssetIFormAction = {
  type: ActionType.UpdateAsset;
  payload: {
    field: keyof CreateAssetInput;
    value: unknown;
  };
};
export type UpdateStreamIFormAction = {
  type: ActionType.UpdateStream;
  payload: {
    field: keyof StreamInfoInput;
    value: unknown;
    streamInfoIndex: number;
  };
};
export type UpdateVideoIFormAction = {
  type: ActionType.UpdateVideo;
  payload: {
    field: keyof VideoInfoInput;
    value: unknown;
    streamInfoIndex: number;
    videoInfoIndex: number;
  };
};
export type UpdateAudioIFormAction = {
  type: ActionType.UpdateAudio;
  payload: {
    field: keyof AudioInfoInput;
    value: unknown;
    streamInfoIndex: number;
    audioInfoIndex: number;
  };
};
export type UpdateTextIFormAction = {
  type: ActionType.UpdateText;
  payload: {
    field: keyof TextInfoInput;
    value: unknown;
    streamInfoIndex: number;
    textInfoIndex: number;
  };
};
export type UpdateSourceIFormAction = {
  type: ActionType.UpdateSource;
  payload: {
    field: keyof SourceInfoInput;
    value: unknown;
    streamInfoIndex: number;
    sourceInfoIndex: number;
  };
};
export type UpdateImageIFormAction = {
  type: ActionType.UpdateImage;
  payload: {
    field: keyof ImageInfoInput;
    value: unknown;
    imageInfoIndex: number;
  };
};

export type DeleteStreamIFromAction = {
  type: ActionType.DeleteStream;
  payload: {
    streamInfoIndex: number;
  };
};
export type DeleteImageIFromAction = {
  type: ActionType.DeleteImage;
  payload: {
    imageInfoIndex: number;
  };
};
export type DeleteVideoIFromAction = {
  type: ActionType.DeleteVideo;
  payload: {
    streamInfoIndex: number;
    videoInfoIndex: number;
  };
};
export type DeleteAudioIFromAction = {
  type: ActionType.DeleteAudio;
  payload: {
    streamInfoIndex: number;
    audioInfoIndex: number;
  };
};
export type DeleteTextIFromAction = {
  type: ActionType.DeleteText;
  payload: {
    streamInfoIndex: number;
    textInfoIndex: number;
  };
};
export type DeleteSourceIFromAction = {
  type: ActionType.DeleteSource;
  payload: {
    streamInfoIndex: number;
    sourceInfoIndex: number;
  };
};
export type AddDeleteTagIFormAction = {
  type: ActionType.AddDeleteTag;
  payload: {
    tagId: number;
  };
};

export type ValidationResponse = {
  valid: boolean;
  error: Error | null;
};

import React from 'react';
import { styled } from '@mui/system';

interface LabeledValueProps {
  label: string;
  // Going with undefined here so I don't have to do a hundred `|| null` entries
  value: string | number | undefined;
  children?: React.ReactNode;
  className?: string;
}

const LabeledValue = ({
  label,
  value,
  children,
  className,
}: LabeledValueProps) => {
  return (
    <>
      <label className={className}>
        {label} <span>{!!children ? children : value || ''}</span>
      </label>
    </>
  );
};

const styledLabeledValue = styled(LabeledValue)({
  display: 'block',
  fontWeight: 'bold',
  margin: '0 15px 5px 0',
  span: {
    fontWeight: 'normal',
  },
});

export default styledLabeledValue;

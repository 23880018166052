export enum ResolutionNames {
  SD = 'SD',
  HD = 'HD',
  HD_720P = '720p',
  HD_1080P = '1080p',
  HD_2K = '2K',
  QHD = 'Quad HD',
  UHD = 'UHD',
  UHD_4K = '4K',
  UHD_8K = '8K',
  UNKNOWN = 'Unknown',
}

export enum ResolutionHeights {
  SD = 480,
  HD_720P = 720,
  HD_1080P = 1080,
  QHD = 1440,
  UHD_4K = 2160,
  UHD_8K = 4320,
}

export enum AspectRatios {
  RATIO_4_3 = 3 / 4,
  RATIO_16_9 = 9 / 16,
  RATIO_K_1_1_77 = 1.77,
  RATIO_1_1_9 = 1.9,
}
